export const eventConstants = {
    REGISTER_REQUEST: 'EVENT_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'EVENT_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'EVENT_REGISTER_FAILURE',

    GETALL_REQUEST: 'EVENT_GETALL_REQUEST',
    GETALL_SUCCESS: 'EVENT_GETALL_SUCCESS',
    GETALL_FAILURE: 'EVENT_GETALL_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GET_DIVISION_REQUEST: 'GET_DIVISION_REQUEST',
    GET_DIVISION_SUCCESS: 'GET_DIVISION_SUCCESS',
    GET_DIVISION_FAILURE: 'GET_DIVISION_FAILURE',

    
    GET_SOLDTO_DIVISION_REQUEST: 'GET_SOLDTO_DIVISION_REQUEST',
    GET_SOLDTO_DIVISION_SUCCESS: 'GET_SOLDTO_DIVISION_SUCCESS',
    GET_SOLDTO_DIVISION_FAILURE: 'GET_SOLDTO_DIVISION_FAILURE',

    DELETE_REQUEST: 'EVENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'EVENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'EVENT_DELETE_FAILURE' ,
    
    SHIPMENT_MANAGEMENT_REQUEST: 'GET_SHIPMENT_MANAGEMENT_REQUEST',
    SHIPMENT_MANAGEMENT_SUCCESS: 'GET_SHIPMENT_MANAGEMENT_SUCCESS',
    SHIPMENT_MANAGEMENT_FAILURE: 'GET_SHIPMENT_MANAGEMENT_FAILURE',
    CLEAR_STATE_SHIPMENT_MANAGEMENT:'CLEAR_STATE_SHIPMENT_MANAGEMENT',

    CREATESHIPMENT_FILTER_REQUEST: 'GET_CREATESHIPMENT_FILTER_REQUEST',
    CREATESHIPMENT_FILTER_SUCCESS: 'GET_CREATESHIPMENT_FILTER_SUCCESS',
    CREATESHIPMENT_FILTER_FAILURE: 'GET_CREATESHIPMENT_FILTER_FAILURE',

    CANCELSHIPMENT_REQUEST: 'GET_CANCELSHIPMENT_REQUEST',
    CANCELSHIPMENT_SUCCESS: 'GET_CANCELSHIPMENT_SUCCESS',
    CANCELSHIPMENT_FAILURE: 'GET_CANCELSHIPMENT_FAILURE',

    GET_SHIPMENTSTATUS_REQUEST: 'GET_SHIPMENTSTATUS_REQUEST',
    GET_SHIPMENTSTATUS_SUCCESS: 'GET_SHIPMENTSTATUS_SUCCESS',
    GET_SHIPMENTSTATUS_FAILURE: 'GET_SHIPMENTSTATUS_FAILURE',

    SHIPMENT_FILTER_REQUEST: 'GET_SHIPMENT_FILTER_REQUEST',
    SHIPMENT_FILTER_SUCCESS: 'GET_SHIPMENT_FILTER_SUCCESS',
    SHIPMENT_FILTER_FAILURE: 'GET_SHIPMENT_FILTER_FAILURE',

    SHIPMENTDETAILS_REQUEST: 'GET_SHIPMENTDETAILS_REQUEST',
    SHIPMENTDETAILS_SUCCESS: 'GET_SHIPMENTDETAILS_SUCCESS',
    SHIPMENTDETAILS_FAILURE: 'GET_SHIPMENTDETAILS_FAILURE',

    CREATE_PICKUP_REQUEST: 'POST_CREATE_PICKUP_REQUEST',
    CREATE_PICKUP_SUCCESS: 'POST_CREATE_PICKUP_SUCCESS',
    CREATE_PICKUP_FAILURE: 'POST_CREATE_PICKUP_FAILURE',

    SELECTEDSHIP_PRODUCTS_REQUEST: 'SELECTEDSHIP_PRODUCTS_REQUEST',
    SELECTEDSHIP_PRODUCTS_SUCCESS: 'SELECTEDSHIP_PRODUCTS_SUCCESS',
    SELECTEDSHIP_PRODUCTS_FAILURE: 'SELECTEDSHIP_PRODUCTS_FAILURE',

    CREATE_DELIVERY_REQUEST: 'POST_CREATE_DELIVERY_REQUEST',
    CREATE_DELIVERY_SUCCESS: 'POST_CREATE_DELIVERY_SUCCESS',
    CREATE_DELIVERY_FAILURE: 'POST_CREATE_DELIVERY_FAILURE',

    DELIVERYUPDATE_REQUEST: 'POST_DELIVERYUPDATE_REQUEST',
    DELIVERYUPDATE_SUCCESS: 'POST_DELIVERYUPDATE_SUCCESS',
    DELIVERYUPDATE_FAILURE: 'POST_DELIVERYUPDATE_FAILURE',

    GET_PLANT_ID_SHIPMENT_REQUEST: 'GET_PLANT_ID_SHIPMENT_REQUEST',
    GET_PLANT_ID_SHIPMENT_SUCCESS: 'GET_PLANT_ID_SHIPMENT_SUCCESS',
    GET_PLANT_ID_SHIPMENT_FAILURE: 'GET_PLANT_ID_SHIPMENT_FAILURE',

    GET_PRODUCT_ID_SHIPMENT_REQUEST: 'GET_PRODUCT_ID_SHIPMENT_REQUEST',
    GET_PRODUCT_ID_SHIPMENT_SUCCESS: 'GET_PRODUCT_ID_SHIPMENT_SUCCESS',
    GET_PRODUCT_ID_SHIPMENT_FAILURE: 'GET_PRODUCT_ID_SHIPMENT_FAILURE',

    GET_SHIP_TO_SHIPMENT_REQUEST: 'GET_SHIP_TO_SHIPMENT_REQUEST',
    GET_SHIP_TO_SHIPMENT_SUCCESS: 'GET_SHIP_TO_SHIPMENT_SUCCESS',
    GET_SHIP_TO_SHIPMENT_FAILURE: 'GET_SHIP_TO_SHIPMENT_FAILURE',
    
    FILTER_SHIPMENT_REQUEST: 'GET_FILTER_SHIPMENT_REQUEST',
    FILTER_SHIPMENT_SUCCESS: 'GET_FILTER_SHIPMENT_SUCCESS',
    FILTER_SHIPMENT_FAILURE: 'GET_FILTER_SHIPMENT_FAILURE',

    FONT_SIZE_CHANGER_REQUEST: 'FONT_SIZE_CHANGER_REQUEST',
    FONT_SIZE_CHANGER_SUCCESS: 'FONT_SIZE_CHANGER_SUCCESS',
    FONT_SIZE_CHANGER_FAILURE: 'FONT_SIZE_CHANGER_FAILURE',

    HEADER_FONT_CHANGER_REQUEST: 'HEADER_FONT_CHANGER_REQUEST',
    HEADER_FONT_CHANGER_SUCCESS: 'HEADER_FONT_CHANGER_SUCCESS',
    HEADER_FONT_CHANGER_FAILURE: 'HEADER_FONT_CHANGER_FAILURE',

    SMALL_FONT_CHANGER_REQUEST: 'SMALL_FONT_CHANGER_REQUEST',
    SMALL_FONT_CHANGER_SUCCESS: 'SMALL_FONT_CHANGER_SUCCESS',
    SMALL_FONT_CHANGER_FAILURE: 'SMALL_FONT_CHANGER_FAILURE',

    ADD_CLASS_WITH_STYLE_REQUEST: 'ADD_CLASS_WITH_STYLE_REQUEST',
    ADD_CLASS_WITH_STYLE_SUCCESS: 'ADD_CLASS_WITH_STYLE_SUCCESS',
    ADD_CLASS_WITH_STYLE_FAILURE: 'ADD_CLASS_WITH_STYLE_FAILURE',
    
};
