import React from "react";
import Header from "../Header/Header";
import "react-tabs/style/react-tabs.css";
// import "./Payment.scss";
import { withTranslation, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import greensucess from "../../assets/img/greensucess.png";

const Success = ({ bgRefNo }) => {
  const { t } = useTranslation();
  const lancode = localStorage.getItem("lancode");

  return (
    <div className="row ipad_css">
      <div className="mainScroll">
        <div className="col-12">
          <div className="payment-history card1">
            <div
              className="boxSuccess"
              style={{
                flexDirection: "row",
                alignItems: "center",
                // marginLeft: "1rem",
              }}
            >
              <div className="imgSuccess">
                <img
                  src={greensucess}
                  alt="success"
                  style={{ width: "80px" }}
                />
              </div>

              {lancode === "en" ? (
                <div className="boxText" style={{ marginTop: "20px" }}>
                  <h2>{`Your payment status No. ${bgRefNo} is COMPLETED`}</h2>
                </div>
              ) : (
                <div className="boxText" style={{ marginTop: "20px" }}>
                  <h2>{`รายการชำระเงินตามเลขที่เอกสารอ้างอิง ${bgRefNo} เสร็จสมบูรณ์`}</h2>
                </div>
              )}
            </div>
            <div className="btnPayment">
              <Link className="create_btn mb-3" to="/PaymentHistory">
                {t("payment.pay_history_btn")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Success);
