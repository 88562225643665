import React from "react";
// import TextField from "@mui/material/TextField";
import TextField from '@material-ui/core/TextField';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import {enUS, th} from 'date-fns/locale';
import { grey } from "@material-ui/core/colors";
import { withStyles, } from '@material-ui/core/styles';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
      borderBottom:'1px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

const DatePickerMyOrder = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    error = null,
    disable,
    format,
    views,
    style,
    ...other
  } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });



  const langCode = localStorage.getItem("lancode")
  // const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={langCode === "th" ? th : enUS}>
      <MuiDatePicker
        inputFormat={format || "dd-MM-yyyy"}
        label={label}
        // {...(views && { views: ["year", "month", "date"] })}
        // disabled={disable || false}
        value={value}
        // {...other}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        renderInput={(params) => (
          <CssTextField
          className="WhiteBorderTextField"
            {...params}
            variant="standard" 
            style={style}
            size="small"
            InputLabelProps={{
              style: {fontSize:"14px",fontWeight: "normal", color:"black",}}}
            // {...(error && { error: false, helperText: error })}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerMyOrder;
