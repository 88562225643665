import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { customStableSort } from '../../../../_helpers';
import { commaFormatter } from "../../../../_constant";

const ContractDetails = ({ row, densityDetail }) => {
    const { t } = useTranslation();
    const [sortModel, setSortModel] = React.useState([]);
    const [sortedRow, setSortedRow] = useState([])
    const lancode = localStorage.getItem('lancode');
    console.log("rowrowrowrowrow : ", row)

    const columns = [
        { field: "id", hide: true },
        {
            field: "productNumber",
            headerName: t("report.productNo"),
            headerAlign: 'center',
            width: 150, align: "center",
            renderCell: ({ row }) => {
                return <div>{Number(row.productNumber)}</div>;
            },
            sortable: true,
        },
        {
            field: lancode === "th" ? "productName" : "productNameEN",
            headerName: t("report.productName"),
            headerAlign: 'center',
            width: 350,
            align: "left",
            // renderCell: ({ row }) => {
            //     return <div>{lancode === "en" ? row.productNameInEN : row.productNameInTH}</div>;
            // },
            sortable: true,
        },
        {
            field: "contractVolume",
            headerName: t("contractVolume"),
            headerAlign: 'center', width: 170, align: "right",
            sortable: true,
            renderCell: ({ row }) => {
                return <div>{commaFormatter(row.contractVolume, 3)}</div>;
            }
        },
        {
            field: "orderVolume",
            headerName: t("orderVolume"),
            headerAlign: 'center', width: 140, align: "right",
            sortable: true,
            renderCell: ({ row }) => {
                return <div>{commaFormatter(row.orderVolume, 3)}</div>;
            }
        },
        {
            field: "remainingVolume",
            headerName: t("remainingVolume"),
            headerAlign: 'center', width: 180, align: "right",
            sortable: true,
            renderCell: ({ row }) => {
                return <div>{commaFormatter(row.remainingVolume, 3)}</div>;
            }
        }
    ];

    const newRow =
        row.productList &&
        row.productList.map((item, index) => {
            return {
                ...item,
                id: index,
                productNumber: Number(item.productNumber),
                contractVolume: Number(item.contractVolume),
                orderVolume: Number(item.orderVolume),
                remainingVolume: Number(item.remainingVolume),
            };
        });

    function customSortModel(model) {
        console.log(model)
        setSortedRow(customStableSort(newRow, model))
        setSortModel(model)
    }

    useEffect(() => {
        setSortedRow(newRow)
    }, [row.productList])

    return (
        <DataGrid
            // style={{ fontSize: "12px" }}
            rows={sortedRow}
            columns={columns}
            hideFooter={true}
            autoHeight={true}
            density={densityDetail ? densityDetail : "compact"}
            disableColumnMenu={true}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => customSortModel(newSortModel)}
            sortingMode="server"
        />
    )
}

export default ContractDetails