import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./MakePaymentBox.scss";

function MakePaymentBox(props) {
  const selectedLangCode = localStorage.getItem("lancode");
  const { t } = useTranslation();
  let userName = localStorage.getItem("userData");
  userName = JSON.parse(userName);
  const countryCode = userName.countryCode;
  const SmallFontChanger = useSelector(
    (state) => state.smallfontchanger.smallfontchanger
  );
  
  return (
    <>
      <div className="row makepayment_box_container">
        <div className="col-12 col-sm-6 pb-2 pt-2" style={{ backgroundColor: "red" }}>
          {props.tab === "AGING" ? (
            ""
          ) : (
            <>
              <p
                className="bigText mb-1"
                style={{ fontSize: `${SmallFontChanger}px` }}
              >
                {selectedLangCode === "en" || selectedLangCode === null
                  ? `(${props.docCurrencyData})`
                  : props.docCurrencyData === "THB"
                  ? "(บาท)"
                  : props.docCurrencyData === "USD"
                  ? "(ดอลล่าร์)"
                  : `(${props.docCurrencyData})`}{" "}
                {Number(
                  parseFloat(props.totalPayment).toFixed(2)
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}
              </p>

              <p
                className="smallText mb-0"
                style={{ fontSize: `${SmallFontChanger}px` }}
              >
                {" "}
                {props.totalChecked} {t("Items")}
              </p>
            </>
          )}
        </div>

        <div
          className="col-12 col-sm-6 text-sm-right pb-4 pt-4 pl-0"
          style={{ backgroundColor: "red" }}
        >
          {(countryCode && countryCode === "VN") ||
          (countryCode && countryCode === "LK") ? (
            ""
          ) : props.label === "Confirm Payment" ||
            props.label === "ගෙවීම තහවුරු කරන්න" ||
            props.label === "ยืนยันการชำระเงิน" ||
            props.label === "Xác nhận thanh toán" ? (
            <button className="cancel_btn" onClick={props.onClick} disabled={props.isDisabled ? true : false}>
              {props.label}
            </button>
          ) : props.navigate ? (
            <button
              className="cancel_btn makePaymentBtn"
              style={{ fontSize: `${SmallFontChanger}px` }}
            >
              <Link
                className="text-white"
                onClick={props.onClick}
                to={props.navigateurl}
              >
                {" "}
                {props.label}
              </Link>
            </button>
          ) : (
            <button
              className="cancel_btn makePaymentBtn text-white"
              style={{ fontSize: `${SmallFontChanger}px` }}
              onClick={props.onClick}
              disabled={props.isDisabled ? true : false}
            >
              {props.label}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default withTranslation()(MakePaymentBox);
