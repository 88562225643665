import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import moment from "moment";
import Axios from "axios";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import ExportPopup from "../../../../components/exportPopup/ExportPopup";
import { customStableSort } from "../../../../_helpers";
import ContractDetails from "./ContractDetails";


const ContactReportDataGrid = ({
  rows,
  filterState,
  clearDensity,
  setClearDensity,
  loading
}) => {
  const { t } = useTranslation();
  const customerId = localStorage.getItem("CustomerNumber");
  const [rowData, setRowData] = useState([]);
  const [dwnReportName, setDWNReportName] = useState("");
  const [sortModel, setSortModel] = React.useState([]);

  const [openExportPopup, setOpenExportPopup] = useState(null);
  // Export Excel
  const [exportState, setExportState] = useState({
    btnName: "EXPORT",
    linkToDownload: null,
  });

  const columns = [
    { field: "id", hide: true },
    {
      field: "contractNumber",
      headerName: t("report.contractNo"),
      headerAlign: "center",
      width: 150,
      align: "center",
      sortable: true,
    },
    {
      field: "contractName",
      headerName: t("report.contractName"),
      headerAlign: "center",
      width: 400,
      align: "left",
      sortable: true,
    },
    {
      field: "validFromDate",
      headerName: t("validDateFrom"),
      headerAlign: "center",
      width: 180,
      align: "center",
        renderCell: ({ row }) => {
          return <div>{moment(row.validFromDate).format("DD-MM-YYYY")}</div>;
      },
      sortable: true,
      type:"date"
    },
    {
      field: "validToDate",
      headerName: t("validDateTo"),
      headerAlign: "center",
      width: 180,
      align: "center",
        renderCell: ({ row }) => {
          return <div>{moment(row.validToDate).format("DD-MM-YYYY")}</div>;
      },
      sortable: true,
      type:"date"
    }
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const getDetailPanelHeight = React.useCallback(() => "auto", []);
  const [pageSize, setPageSize] = React.useState(20);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState(
    [],
  );

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds);
  }, []);

  useEffect(() => {
    setRowData(rows);
    setClearDensity("");
    setDetailPanelExpandedRowIds([])
  }, [rows]);

  function customSortModel(model) {
    setRowData(customStableSort(rows, model))
    setSortModel(model)
  }


  const [densityDetail, setDensityDetail] = useState("compact")
  const getDetailPanelContent = React.useCallback(
    ({ row }) => row.productList ? <ContractDetails row={row} densityDetail={densityDetail} /> : null,
    [densityDetail],
  );

  return (
    <>
      <ExportPopup
        title={t("ContractReport")}
        fileName={dwnReportName}
        openPopup={openExportPopup}
        setOpenExportPopup={setOpenExportPopup}
        linkToDownload={exportState.linkToDownload}
      />
      <DataGridPro
        style={{ fontSize: "14px" }}
        rows={rowData}
        // rows={dummyData}
        // rows={rows}
        disableColumnMenu={true}
        disableColumnReorder={true}
        disableColumnSelector={true}
        // getRowId={(row) => row.DocumentNo}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
        getDetailPanelContent={getDetailPanelContent}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pageSize={pageSize}
        rowsPerPageOptions={[20, 50, 75, 100]}
        getDetailPanelHeight={getDetailPanelHeight}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}

        pagination={true}
        autoHeight={true}
        componentsProps={{
          pagination: {
            labelRowsPerPage: t("report.perPage"),
          },
        }}
        localeText={{
          noRowsLabel: t("report.noData"),
        }}
        density={clearDensity}
        disableSelectionOnClick={true}

        loading={loading}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => customSortModel(newSortModel)}
        onStateChange={(v) => {
          setDensityDetail(v.density.value)
        }}
      />
    </>
  )
}

export default ContactReportDataGrid