export const caseConstants = {


  GET_CASE_REQUEST: "GET_CASE_REQUEST",
  GET_CASE_SUCCESS: "GET_CASE_SUCCESS",
  GET_CASE_FAILURE: "GET_CASE_FAILURE",

  GETALL_CASE_REQUEST: "GETALLCASE_REQUEST",
  GETALL_CASE_SUCCESS: "GETALLCASE_SUCCESS",
  GETALL_CASE_FAILURE: "GETALLCASE_FAILURE",

  CREATE_CASE_REQUEST: 'CREATECASE_REQUEST',
  CREATE_CASE_SUCCESS: 'CREATECASE_SUCCESS',
  CREATE_CASE_FAILURE: 'CREATECASE_FAILURE',
  CREATE_CASE_RESET: 'CREATECASE_RESET',


   ALLOCATED_VOLUME_REQUEST: ' ALLOCATED_VOLUME_REQUEST',
   ALLOCATED_VOLUME_SUCCESS: ' ALLOCATED_VOLUME_SUCCESS',
   ALLOCATED_VOLUME_FAILURE: ' ALLOCATED_VOLUME_FAILURE',
   ALLOCATED_VOLUME_RESET: ' ALLOCATED_VOLUME_RESET',

 





};
