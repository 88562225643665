import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { masterActions } from "../../../_actions";
const MIN_TEXTAREA_HEIGHT = 0;
const EditVehicle = ({
    getVehiclesDetailsById,
    handleClose,
    getOwnership,
    capitalize,
    getVehicletypes,
    isSaveDisabled,
    DialogActions,
    vehicleId,
    setIsSaveDisabled,
    FontChange
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const textareaRef = React.useRef(null);
    const [editData, setEditData] = useState({
        licenseNumber: "",
        capacity: "",
        documentDate: "",
        expireDate: "",
        retailerName: "",
        vehicleType: "",
        ownership: "",
        notes: "",
        soldToNumber: localStorage.getItem("CustomerNumber"),
        expiryBlockDate: "",
        startBlockDate: ""
    })
    const {
        licenseNumber,
        capacity,
        documentDate,
        expireDate,
        retailerName,
        vehicleType,
        ownership,
        notes } = editData;

    const handleChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value })
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        setIsSaveDisabled(true)
        dispatch(masterActions.updateVehicle(editData, vehicleId));
    };

    useEffect(() => {
        setEditData({
            licenseNumber: getVehiclesDetailsById.editVehiclesById.licenseNumber,
            capacity: getVehiclesDetailsById.editVehiclesById.capacity,
            documentDate: getVehiclesDetailsById.editVehiclesById.documentDate,
            expireDate: getVehiclesDetailsById.editVehiclesById.expireDate,
            retailerName: getVehiclesDetailsById.editVehiclesById.retailerName,
            vehicleType: getVehiclesDetailsById.editVehiclesById.vehicleType,
            ownership: getVehiclesDetailsById.editVehiclesById.ownership,
            notes: getVehiclesDetailsById.editVehiclesById.notes,
            soldToNumber: localStorage.getItem("CustomerNumber"),
            expiryBlockDate: getVehiclesDetailsById.editVehiclesById.expiryBlockDate,
            startBlockDate: getVehiclesDetailsById.editVehiclesById.startBlockDate
        })
    }, [getVehiclesDetailsById])


    // Note Filelds auto inctrease height
    React.useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        textareaRef.current.style.height = MIN_TEXTAREA_HEIGHT;
        // textareaRef.current.style.height = "inherit";
        // Set height
        textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight,
            MIN_TEXTAREA_HEIGHT
        )}px`;
    }, [notes]);


    return (
        <form style={{ width: "100%" }} onSubmit={handleUpdate}>
            <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                        <div className="inputBox ">
                            <label>{t("Registered License No.")}</label>
                            <input
                                disabled="disabled"
                                required
                                type="text"
                                name="licenseNumber"
                                placeholder={t("Registered License No.")}
                                // onChange={handleChange}
                                className="input"
                                value={licenseNumber}
                            />
                            <span
                                id="lincenceErr"
                                style={{ color: "red" }}
                            ></span>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                        <div className="inputBox ">
                            <label>{t("Vehicle Capacity")}</label>
                            <input
                                required
                                type="text"
                                name="capacity"
                                placeholder={t("Vehicle Capacity")}
                                onChange={handleChange}
                                className="input"
                                value={capacity}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                        <div className="inputBox ">
                            <label>{t("Start Date")}</label>
                            <input
                                required
                                type="date"
                                name="documentDate"
                                placeholder={t("Document Date to")}
                                onChange={handleChange}
                                className="input"
                                value={documentDate}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                        <div className="inputBox ">
                            <label>{t("Expire Date")}</label>
                            <input
                                required
                                type="date"
                                name="expireDate"
                                placeholder={t("Document Date to")}
                                onChange={handleChange}
                                className="input"
                                value={expireDate}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                        <div className="inputBox ">
                            <label>{t("Retailer Name")}</label>
                            <input
                                type="text"
                                name="retailerName"
                                placeholder={t("Retailer Name")}
                                onChange={handleChange}
                                className="input"
                                value={retailerName}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                        <div className="inputBox ">
                            <label>{t("Vehicle Type")}</label>
                            <select
                                required
                                className="input"
                                name="vehicleType"
                                onChange={handleChange}
                            >
                                <option value="">Vehicle Type</option>
                                {getVehicletypes &&
                                    getVehicletypes.getVehicletypes !== undefined
                                    ? getVehicletypes.getVehicletypes.map((data) => {
                                        if (vehicleType == data.key) {
                                            return (
                                                <option selected value={data.key}>
                                                    {" "}
                                                    {capitalize(data.value)}
                                                </option>
                                            );
                                        } else {
                                            return (
                                                <option value={data.key}>
                                                    {" "}
                                                    {capitalize(data.value)}
                                                </option>
                                            );
                                        }
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                        <div className="inputBox ">
                            <label>{t("Ownership")}</label>
                            <select
                                className="input"
                                name="ownership"
                                onChange={handleChange}
                            >
                                <option value="">Vehicle Type</option>
                                {getOwnership &&
                                    getOwnership.getOwnership !== undefined
                                    ? getOwnership.getOwnership.map((data) => {
                                        if (ownership == data.key) {
                                            return (
                                                <option selected value={data.key}>
                                                    {" "}
                                                    {capitalize(data.value)}
                                                </option>
                                            );
                                        } else {
                                            return (
                                                <option value={data.key}>
                                                    {" "}
                                                    {capitalize(data.value)}
                                                </option>
                                            );
                                        }
                                    })
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                        <spam style={{ color: "red", float: "right", fontSize: `${FontChange}px` }}>{t("100 Words")}</spam>
                        <div className="inputBox ">
                            <label>{t("Notes")}</label>
                            <textarea
                                // style={{ overflow: "hidden", resize: "none" }}
                                ref={textareaRef}
                                style={{
                                    minHeight: MIN_TEXTAREA_HEIGHT,
                                    resize: "none",
                                    overflow: "hidden"
                                }}
                                className="input"
                                name="notes"
                                onChange={handleChange}
                                value={notes}
                                onKeyPress={e => {
                                    if (e.key === 'Enter')
                                        e.preventDefault()
                                }}
                                maxLength="100"
                            />
                            {/* </textarea> */}
                            {notes && notes.length > 0 && <span style={{ color: "red" }}>{`${100 - parseInt(notes.length)} ${t("CharacterRemaining")}`}</span>}
                        </div>
                    </div>
                </div>
            </div>
            <DialogActions>
                <div className="create_link d-flex">
                    <button
                        type="button"
                        className="create pl-5 pr-5"
                        onClick={handleClose}
                    >
                        {t("cancel.button")}
                    </button>
                    <button
                        type="submit"
                        className="create pl-5 pr-5"
                        disabled={isSaveDisabled}
                    >
                        {t("submit.button")}
                    </button>
                </div>
            </DialogActions>
        </form>
    )
}

export default EditVehicle