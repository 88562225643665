import React, { useEffect } from "react";
// import { useIdleTimer } from "react-idle-timer";
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js'
import { useHistory } from "react-router";
import { jwtDecode } from "jwt-decode";
import Axios from "axios";

const TIME_IN_MINITUS = 60 * 5; // Set time in minutus by right params
const ACTIVE_USER_TIMEOUT = 1000 * 60 * 30; // IN MINUTES
export default function LogoutTimer() {
  const history = useHistory();
  const onIdle = () => {
    console.log("onIdle : ", "onIdle")
  }

  const onActive = () => {
    console.log("onActive : ")
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    // onAction,
    timeout: ACTIVE_USER_TIMEOUT,
    // crossTab: true,
    // throttle: 1000,
    // syncTimers: 200
    leaderElection: true,
    crossTab: true,
    syncTimers: 1000,
    emitOnAllTabs: true
  })

  async function getRefreshToken() {
    console.log("CheckAuth : ", "Call Refresh Token", new Date())
    const requestOptions = {
      headers: {
        "Content-Type": "application/text",
      },
    };
    try {
      const response = await Axios.post(
        process.env.REACT_APP_API_URL_LOGIN + "token/refresh",
        localStorage.getItem("refreshToken"),
        requestOptions
      );
      if (response.data.status === 200) {
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
        localStorage.setItem("x-auth-token", response.data.data.token[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    const interval = setInterval(() => {
      // code For Refresh Token
      localStorage.setItem("autoLogoutTimer", Math.ceil(getRemainingTime() / 1000))
      const autoLogoutTimer = localStorage.getItem("autoLogoutTimer")
      function sessionLogout() {
        try {  // Call Logout API if autologout complete
          fetch(process.env.REACT_APP_API_URL_LOGIN + `customer/logout`, {
            method: "POST",
            body: localStorage.getItem("x-auth-token"),
          }).then((response) => {
            if (response.ok) {
              localStorage.clear();
              history.push("/");
            }
          });
        } catch (error) {
          console.log(error);
        }
      }

      const isTokenAboutToExpire = () => {// 30 minutes from now - 60s seconds > Now time
        const token = localStorage.getItem("x-auth-token")
        if (token) {
          const decodedJWT = jwtDecode(token);
          return (decodedJWT.exp - TIME_IN_MINITUS) > (Date.now() / 1000);
        } else {
          sessionLogout()
          return false
        }
      };

      if (!isTokenAboutToExpire()) getRefreshToken();

      if (autoLogoutTimer === "0") { // Check Logout time is 0
        console.log("CheckAuth : ", "User Activity", new Date())
        sessionLogout()
      }
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  })

  return <></>;
}
