import React from 'react'
import Header from "../../components/Header/Header";
import { withTranslation, useTranslation } from "react-i18next";

const Notifications = () => {
    const { t } = useTranslation();

    const options = [
        'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.',
        'Atria',
        'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual.',
        'Dione',
        'Ganymede',
        'Hangouts Call',
        'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.',
        'Oberon',
        'Phobos',
        'Pyxis',
        'Sedna',
        'Titania',
        'Triton',
        'Umbriel',
        'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.',
        'Atria',
        'Callisto',
        'Dione',
        'Ganymede',
        'Hangouts Call',
        'Luna',
        'Oberon',
        'Phobos',
        'Pyxis',
        'Sedna',
        'Titania',
        'Triton',
        'Umbriel',
    ];

    return (
        <>
            <div className="content-wrapper">


                <Header title={t("Notifications")} />

                <div className={"row ipad_css "}>
                    <div className="mainScroll dashBordMain mr-3">
                        <div className="col-12">
                            {/* <div className="dashboard-container mt-3">
                                <div className="row"> */}

                            {options.map((option, index) => (
                                <div key={index} className='card py-4'>
                                    <div
                                        className={`row`}>
                                        <div className='col-12 col-md-9 col-lg-9'>{option}</div>
                                        <div className='col-auto col-md-auto col-lg-auto customtextSize py-2'><b className='customBadge'>Order</b></div>
                                        <div className='col-auto col-md-auto col-lg-auto customtextSize py-2'>05-10-2023 16:41</div>
                                        <div className='col-auto col-md-auto col-lg-auto customtextSize py-2'>Unread</div>
                                        {/* <span> {option}</span> <b className='readNotification'>Unread</b> */}
                                    </div>
                                </div>
                            ))}

                            {/* </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Notifications