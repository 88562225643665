import xlsx from "json-as-xlsx";

// Export Excel from frontend

export function onExportExcel(columnName, tableData, fileName, sheetName){
  console.log("export ", columnName, tableData)
    let data = [
      {
        sheet: sheetName ?? 'sheet1',
        columns: columnName,
        content: tableData,
        // columns: [
        //   { label: "Payment Status", value: "paymentstatus" }, // Top level data
        //   { label: "Sold To Number", value: "soldToNumber" }, // Custom format
        //   { label: "Payment Amount", value: "paymentamount" }, // Run functions
        //   { label: "Invoice", value: "more.phone" },
        // ],
    
      },
    ];
    
    let settings = {
      fileName: fileName, // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      RTL: false, // Display the columns from right-to-left (the default value is false)
    };
    xlsx(data, settings)
    
    }
    