import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from "../../../components/Header/Header";
import "./Payment.scss"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import paymentHistroryTable from "../../../components/Table/paymentHistroryTable";
import PaymentRefTable from "../../../components/Table/PaymentRefTable";
import { paymentofflineActions } from "../../../_actions/paymentoffline.action";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { useHistory } from "react-router-dom";
import ExportJsonDataToExcel from "../../../components/exportPopup/ExportJsonDataToExcel";
import Axios from "axios";
import ExportExcelPopup from "../../../components/exportPopup/ExportExcelPopup";

function PaymentApprovalReference(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory()

    const productId = props.location && props.location.state ? props.location.state.id : null
    const paymentHistoryData = useSelector((state) => state.paymentHistoryDetail.paymentHistoryDetail);
    const MyNewClass = useSelector((state) => state.addclasswithstyle.addclasswithstyle);

    // console.log(paymentHistoryData, 'hello')
    const formatDataExecl = paymentHistoryData?.inoicesData.map(item => {
        return {
            ...item,
            amountDocCurrency: parseFloat(item.amountDocCurrency)
        }
    })

    const column = [
        {label: t("Document No."), value: "billingDoc"},
        {label: t("Document Type"), value: "DocumentType"},
        {label: t("PO Number/Cheque Number"), value: "customerPONumber"},
        {label: t("Document Date"), value: "documentDate"},
        {label: t("Due Date"), value: "netDueDate"},
        {label: t("Numbers of Due Days"), value: "duedays"},
        {label: t("AmountForRefrence"), value: "amountDocCurrency"},
    ]
    
    useEffect(() => {
        dispatch(paymentofflineActions.paymentHistoryDetail(productId));
    }, [0])

    
  const [fileUrl, setFileUrl] = useState("");
  const [exportFiles, setExportFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const lancode = localStorage.getItem('lancode')

  function exportExcelFile(file_url) {
    setExportFiles([]);
    Axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_URL_CONFIRMPAYMENT +
        `/onlinePayment/bucket?folderName=${file_url}`,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        const newRes = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        setExportFiles(newRes);
      })
      .catch((err) => {
        // setExportState({ btnName: "EXPORT" });
      });
  }

  function exportApiCAll() {
    const postObj = {
      id: productId,
      countryCode: lancode,
      excel: "true",
    };

    const url = `/onlinePayment/export/historyDetails`;

    Axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL_CONFIRMPAYMENT + url,
      data: postObj,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        setOpen(true);
        setFileUrl(response.data.data);
        exportExcelFile(response.data.data);
      })
      .catch((err) => {});
  }

    // console.log('hello', props);
    // console.log('hello', paymentHistoryData);

    return (
        <>
            <div className="content-wrapper">
                <Header title={`${t("label.payment_ref_no")} ${paymentHistoryData && paymentHistoryData.paymentRefNo ? paymentHistoryData.paymentRefNo : ''}`} />

                <div className={"row ipad_css " + MyNewClass}>
                    <div className="mainScroll">

                        <div className="col-12">
                            <div>
                                <ExportExcelPopup
                                    title={"Customer"}
                                    fileName={"customer"}
                                    setOpen={setOpen}
                                    open={open}
                                    exportExcelFile={exportExcelFile}
                                    fileUrl={fileUrl}
                                    exportFiles={exportFiles}
                                />
                                {/* <ExportJsonDataToExcel 
                                    fileName="Payment-Ref"
                                    columnName={column}
                                    // tableData={paymentHistoryData?.inoicesData}
                                    tableData={formatDataExecl}
                                    classes="text-primary float-right mr-2 mt-3"
                                    textSize="12px"
                                    iconSize="14px"
                                /> */}
                                <Button
                                    className="text-primary float-right mr-2 mt-3"
                                    style={{ fontSize: "12px" }}   
                                    startIcon={<DownloadIcon style={{ fontSize: "14px" }} />}
                                    onClick={exportApiCAll}
                                >
                                    {t("payment_export")}
                                </Button>
                            </div>
                            <div className="payment-history card">
                                <PaymentRefTable paymentHistoryData={paymentHistoryData} />
                                <div className="mt-4 text-center">
                                    <Button variant="outlined" style={{ color: "#007bff" }} size="small" onClick={history.goBack}>{t("Back")}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withTranslation()(PaymentApprovalReference);
