import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { withTranslation, useTranslation } from "react-i18next";
import { RenderCellExpand } from "../../../../../components/DataGridTooltip/DataGridToolTip";
import ExportExcelPopup from "../../../../../components/exportPopup/ExportExcelPopup";
import "./ActivityPoints.scss";
// re commit
import SearchForm from "./SearchForm";
import Axios from "axios";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import DataGridProMUI from "../../../../../components/DataGrid/DataGridProMUI";
import {
  convertToCurrencyFormat,
  convertToCurrencyFormatQuantaty,
  millisecondsToStringDate,
  DATE_FORMAT,
  DataFormat,
} from "../../../../../_helpers";
import { onExportExcel } from "../../../../../_helpers/exportExcel";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <div className="ExportAndSearchContainer">
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={onExportClick}
          style={{ border: "0px" }}
        >
          Export
        </Button>
        <GridToolbarQuickFilter />
      </div>
    </GridToolbarContainer>
  );
};

const onExportClick = () => {
  console.log("Inside Export Click");
};

const SubDealerActivityPoint = () => {
  const { t } = useTranslation();
  const lancode = localStorage.getItem("lancode");
  const [row, setRow] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [filteredRows, setFilteredRows] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [searchedRows, setSearchedRows] = useState(null)
  const [filteredValues, setFilteredValues] = useState({
    startDate: moment().subtract(1, "months"),
    endDate: new Date(),
    activityType: null,
  });
  const [fileUrl, setFileUrl] = useState("");
  const [exportFiles, setExportFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [exportState, setExportState] = useState("Export");
  const [newMinDate, setNewMinDate] = useState(null);
  const [state, setState] = React.useState({
    message: "Deleted Successfully!!!!",
    severity: "success",
    open: false,
  });
  const excelFormating = [{ expireDate: "date" }, { createDate: "date" }];

  const onExportClick = () => {
    const url = `export/activity-point`;
    setExportState("Exporting...");
    let filters = {
      startDate: moment(filteredValues.startDate).format("DD-MM-YYYY"),
      endDate: moment(filteredValues.endDate).format("DD-MM-YYYY"),
      activityType: filteredValues.activityType,
      search: null,
      accountNumber: localStorage.getItem("CustomerNumber"),
      isDealer: false,
      languageType: lancode.toUpperCase(),
    };

    Axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL_LMS + url,
      data: filters,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        setExportState("Export");
        setOpen(true);
        setFileUrl(response?.data?.data);
        exportExcelFile(response?.data.data);
      })
      .catch((err) => {
        setExportState("Export");
      });
  };

  function exportExcelFile(file_url) {
    setExportFiles([]);
    Axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_URL_LMS +
        `export/bucket?folderName=${file_url}`,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        const newRes = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        setExportFiles(newRes);
      })
      .catch((err) => {
        // setExportState({ btnName: "EXPORT" });
      });
  }

  const columns = [
    {
      field: `${lancode === "en" ? 'activityType' : 'activityTypeTh'}`,
      headerName: t("label.activity_type"),
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "accountNumber",
      headerName: t("dealernumbr_activitypoits"),
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "accountNameTranslate",
      headerName: t("dealername.label"),
      headerAlign: "center",
      align: "left",
      width: 200,
      renderCell: RenderCellExpand,
    },
    {
      field: "billingNumber",
      headerName: t("label.billing_no"),
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "billingDate",
      headerName: t("label.billing_date"),
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "date",
      valueFormatter: (row) => {
        return row.value && row.value !== null
          ? moment(new Date(row.value)).format("DD-MM-yyyy")
          : "";
      },
    },
    {
      field: "createDate",
      headerName: t("label.create_date"),
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "date",
      valueFormatter: (row) => {
        return row.value && row.value !== null
          ? moment(new Date(row.value)).format("DD-MM-yyyy")
          : "";
      },
    },
    {
      field: "productCode",
      headerName: t("label.product_number"),
      align: "center",
      headerAlign: "center",
      width: 150,
      renderCell: ({ row }) => {
        return <div>{DataFormat(row.productCode)}</div>;
      },
    },
    {
      field: "productCodeTranslate",
      headerName: t("label.product_name"),
      headerAlign: "center",
      align: "left",
      width: 150,
      renderCell: RenderCellExpand,
    },
    {
      field: "quantity",
      headerName: t("label.quantity_units"),
      headerAlign: "center",
      align: "right",
      width: 150,
      format: '#,##0.000',
      renderCell: ({ row }) => {
        return (
          <div>
            {row.quantity
              ? convertToCurrencyFormatQuantaty(row.quantity)
              : null}
          </div>
        );
      },
    },
    {
      field: "totalPoints",
      headerName: t("label.point_recieved"),
      headerAlign: "center",
      align: "right",
      width: 150,
      format: '#,##0.00',
      renderCell: ({ row }) => {
        return <div>{convertToCurrencyFormat(row.totalPoints)}</div>;
      },
    },
    {
      field: "expireDate",
      headerName: t("expirationDate_activityPoints"),
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "date",
      format: 'DD-MM-yyyy',
      valueFormatter: (row) => {
        return row.value && row.value !== null
          ? moment(new Date(row.value)).format("DD-MM-yyyy")
          : "";
      },
    },
    {
      field: "remarks",
      headerName: t("Remarks"),
      headerAlign: "center",
      align: "left",
      width: 150,
      renderCell: RenderCellExpand,
    },
    { field: 'billDate',headerName: 'billDate', hide:true},
    { field: 'createdDate',headerName: 'createdDate', hide:true},
    { field: 'q',headerName: 'q', hide:true},
    { field: 'pr',headerName: 'pr', hide:true},
    { field: 'expDt',headerName: 'expDt', hide:true},
  ];

  const fetchActivityHistorySubDealer = function () {
    setShowLoading(true);
    let customerId = localStorage.getItem("CustomerNumber");
    Axios.get(
      // `${
      //   process.env.REACT_APP_API_URL_LMS
      // }loyalty/activityHistory?accountNumber=${"110005870"}&isDealer=true`,
      `${
        process.env.REACT_APP_API_URL_LMS
      }loyalty/activityHistory?accountNumber=${customerId}&isDealer=false&startDate=${moment(
        filteredValues.startDate
      ).format("DD-MM-YYYY")}&endDate=${moment(filteredValues.endDate).format(
        "DD-MM-YYYY"
      )}&activityType=${filteredValues.activityType}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
          "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
        },
      }
    )
      .then((response) => {
        setShowLoading(false);
        const responseRow = (response?.data?.data || []).map((item, index) => ({
          ...item,
          id: index + 1,
          billingDate: item.billingDate != null ? new Date(item.billingDate) : "",
          expireDate: item.expireDate != null ? new Date(item.expireDate) : "",
          createDate: item.createDate != null ? new Date(item.createDate) : "",
          quantity: item.quantity != null ? +item.quantity : 0,
          productCodeTranslate:
            lancode === "en" ? item.productNameEN : item.productName,

          accountNameTranslate:
            lancode === "en" ? item.accountName : item.accountNameTh,
          
            billDate: item.billingDate != null ? moment(new Date(item.billingDate)).format("DD-MM-yyyy"): "",
            expDt: item.expireDate != null ? moment(new Date(item.expireDate)).format("DD-MM-yyyy"): "",
            createdDate : item.createDate != null ? moment(new Date(item.createDate)).format("DD-MM-yyyy"): "",
            q: item.quantity != null ? convertToCurrencyFormatQuantaty(item.quantity) : "0.000",
            pr:item.totalPoints != null ? convertToCurrencyFormat(item.totalPoints) : "0.00",
        }));

        setRow(responseRow);
      })
      .catch((error) => {
        setShowLoading(false);
        console.log("Activity History Error : ", error);
      });
  };

  useEffect(() => {
    fetchActivityHistorySubDealer();
  }, [
    filteredValues.startDate,
    filteredValues.endDate,
    filteredValues.activityType,
  ]);

  useEffect(() => {
    setRow(
      (row || []).map((item) => ({
        ...item,
        productCodeTranslate:
          lancode === "en" ? item.productNameEN : item.productName,
        accountNameTranslate:
          lancode === "en" ? item.accountName : item.accountNameTh,
      }))
    );
  }, [lancode]);

  const onFilterChange = (filterObj) => {
    setFilteredValues({ ...filterObj });
  };

  function modifyColumns(columns) {
    columns = columns.filter((item)=>!["billDate", "q", "pr", "createdDate", "expDt"].includes(item.field))
    return columns.map((item) => {
      return ({
        label: item.headerName,
        value: item.field,
        ...item
      })
    })
  }

  function modifyRows() {
    console.log("export ", searchedRows)
    let filteredRow = searchedRows.map((item) => item.model);
    const data = filteredRow.map((item) => {
      return ({
        ...item,
        productCode : DataFormat(item.productCode),
        quantity : item?.quantity ? item.quantity : null
      })
    })
    return data
  }

  function callExcelExport() {
    const fileName = `Activity_Point_Report_${moment(new Date()).format("DDMMyyyy")}`;
    const sheetName = `Activity_Point_Report`;
    onExportExcel(modifyColumns(columns), modifyRows(), fileName, sheetName)
  }

  return (
    <>
      <div>
        <SearchForm
          setOpenPopup={true}
          recordForEdit={null}
          onFilterChange={onFilterChange}
          minDate={newMinDate === 0 ? null : newMinDate}
        />
      </div>
      <div className="DataGridContainer">
        <ExportExcelPopup
          title={"Customer"}
          fileName={"customer"}
          setOpen={setOpen}
          open={open}
          exportExcelFile={exportExcelFile}
          fileUrl={fileUrl}
          exportFiles={exportFiles}
        />
        <DataGridProMUI
          rows={row}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
          pageSize={pageSize}
          loading={showLoading}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 75, 100]}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          onExportClick={onExportClick}
          exportState={exportState}
          setSearchedRows={setSearchedRows}
          callExcelExport={callExcelExport}
        />
      </div>
    </>
  );
};

export default withTranslation()(SubDealerActivityPoint);
