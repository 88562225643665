import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "../../../components/Header/Header";
import "./Payment.scss";
// import InvoiceDebitCreditBoxes from "../../../components/InvoiceDebitCreditBoxes/InvoiceDebitCreditBoxes";
import {  useLocation } from "react-router-dom";
import FormSelectbox from "../../../components/FormSelectbox/FormSelectbox";
import FormInput from "../../../components/FormInput/FormInput";
import MakePaymentBox from "../../../components/MakePaymentBox/MakePaymentBox";
import TotalPaymentBox from "../../../components/TotalPaymentBox/TotalPaymentBox";
import { paymentofflineActions } from "../../../_actions/paymentoffline.action";
import MakeTable from "../../../components/Table/MakeTable";
import PaymentSummaryConfirmPopup from "../../../components/ModalPopup/PaymentSummaryConfirmPopup";
import { toast } from "react-toastify";
import moment from "moment";
import "moment-timezone";
import Loading from "../../../components/Loader/Loading";
import PaymentReferencePopup from "../../../components/ModalPopup/PaymentReferencePopup";
import Axios from "axios";
import { API_URL_CONFIRMPAYMENT } from "../../../constant";
import { getAxiosCall, putAxiosCall } from "../../../_constant";
const THBText = require("thai-baht-text");

function PaymentSummary(props) {
  // const event = useSelector((state) => state);
  const paymentModeDropdown = useSelector((state) => state.getpaymentmode);
  const paymentTypeDropdown = useSelector((state) => state.getpaymenttype);
  const paymentBankDropdown = useSelector((state) => state.getpaymentbank);
  // const makePaymentDataFinal = useSelector(
  //   (state) => state.makePaymentData.makePaymentData
  // );
  const totalMakePayment = useSelector(
    (state) => state.totalMakePaymentData.totalMakePaymentData
  );
  const totalCount = useSelector(
    (state) => state.totalMakePaymentData.totalCount
  );
  const [open, setOpen] = useState(false);
  // let history = useHistory();
  const [openPaymentRefPopup, setOpenPaymentRefPopup] = useState(false);

  // const [paymentid, setPaymentId] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [isCheckedData, setIsCheckedData] = useState([]);

  const [c_payment_mode, setCpaymentMode] = useState("");
  const [c_payment_type, setPaymenttype] = useState("");
  const [c_bank_name, setBankName] = useState("");

  const [c_payment_date, setPaymentDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [errors, setError] = useState({});
  const [bankDisable, setBankDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingOne, setLoadingOne] = useState(false);
  const confirmPaymentNo = useSelector(
    (state) => state.confirmPaymentButton.confirmPaymentButton
  );
  // const confirmPaymentError = useSelector(
  //   (state) => state.confirmPaymentButton
  // );
  const checkedInvoiveData = useSelector(
    (state) => state.totalCheckedPandingData.totalCheckedPandingData
  );
  const paymentrefnoconfirm = confirmPaymentNo && confirmPaymentNo.paymentrefno;
  const getPendingPaymentStatusData = useSelector(
    (state) => state.getPendingPaymentStatus.getPendingPaymentStatus
  );
  const confirmPaymentButtonOnlineDataURL = useSelector(
    (state) => state.confirmPaymentButtonOnline.confirmPaymentButtonOnline
  );

  const [paymentRefNo, setPaymentRefNo] = useState();
  const [isUserOwner, setIsUserOwner] = useState(false);  

  const getselectedPaymentData = useSelector(
    (state) => state.totalCheckedData.totalCheckedData
  );
  // let custmerNo = localStorage.getItem("CustomerNumber");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const location = useLocation();
  const getPaymentCat = useSelector(
    (state) => state.getCatForFilter.getCatForFilter
  );
  const defaultCat =
    getPaymentCat && getPaymentCat ? getPaymentCat && getPaymentCat : "Cement";

  const totalMakePaymentValue = parseFloat(totalMakePayment).toFixed(2);
  const selectedLangCode = localStorage.getItem("lancode");
  // const customerName = localStorage.getItem("CustomerName");
  const customerNameTh = localStorage.getItem("CustomerNameTh");
  let userName = localStorage.getItem("userData");
  const userId = JSON.parse(userName).userId;
  let date = new Date()
  let currentDate = moment(date).format("YYYY-MM-DD");
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    dispatch(paymentofflineActions.getPaymentMode());
  }, []);

  useEffect(() => {
    dispatch(paymentofflineActions.getPaymentType(c_payment_mode));
  }, [c_payment_mode]);

  useEffect(() => {
    dispatch(paymentofflineActions.getPaymentBank());
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isChecked != []) {
      dispatch(paymentofflineActions.totalCheckedData(getselectedPaymentData));
    }
  }, [isCheckedData]);

  useEffect(() => {
    if (
      confirmPaymentButtonOnlineDataURL &&
      confirmPaymentButtonOnlineDataURL != undefined &&
      confirmPaymentButtonOnlineDataURL &&
      confirmPaymentButtonOnlineDataURL != null
    )
      window.location.href =
        confirmPaymentButtonOnlineDataURL &&
        confirmPaymentButtonOnlineDataURL.url;
  }, [confirmPaymentButtonOnlineDataURL]);

  const handleCheckPaymentSummary = (e, row, payment, type) => {
    getselectedPaymentData.indexOf(row) !== -1 &&
      getselectedPaymentData.splice(getselectedPaymentData.indexOf(row), 1);
    let totalAmt = parseFloat(totalMakePayment) - parseFloat(payment);
    let count = parseInt(totalCount) - 1;
    dispatch(paymentofflineActions.totalMakePaymentData(totalAmt, count));
  };

  const paymentModeData = paymentModeDropdown.getpaymentmode
    ? paymentModeDropdown.getpaymentmode.map((element) => {
        return {
          id: element.paymentmode,
          name:
            element.paymentmode === "Online" || element.paymentmode === "online"
              ? "ออนไลน์ (ชำระเงินโดยการหักบัญชีทางอินเทอร์เน็ต) - Online"
              : element.paymentmode === "Offline" ||
                element.paymentmode === "offline"
              ? "ออฟไลน์ (การชำระเงินโดยใบนำฝากพิเศษ) - Offline"
              : element.paymentmode,
        };
      })
    : [
        {
          id: "0",
          name: "Data is not available",
        },
      ];

  const paymentTypeData = paymentTypeDropdown.getpaymenttype
    ? paymentTypeDropdown.getpaymenttype.map((element) => {
        return {
          id: element.paymenttype,
          name: element.paymenttypeInTh,
        };
      })
    : [
        {
          id: "0",
          name: "Data is not available",
        },
      ];

  const paymentBankData = paymentBankDropdown.getpaymentbank
    ? paymentBankDropdown.getpaymentbank.map((element) => {
        return  {
          id: element.bankname,
          name: element.bankNameInTh,
        };
      })
    : [
        {
          id: "0",
          name: "Data is not available",
        },
      ];

  const filteredData = paymentBankData.filter(item => {
    return item.id !== "Siam Commercial Bank (SCB) - Business Net" && {id: item.id, name: item.name}
  })

  // const removeKBank = paymentBankData.filter(item => {	
  //   return item.id !== "Kasikorn Bank - K-Cash Connect" && {id: item.id, name: item.name}	
  // })	
  
  const filteredBankName = c_payment_type === "Customer Financing" ? filteredData : paymentBankData

  function onChangeHandle(event, name) {
    switch (name) {
      case "payment_mode":
        setCpaymentMode(event);
        setPaymenttype("");
        if (event === "Offline" || event === "offline") {
          setBankDisable(true);
        } else {
          setBankDisable(false);
        }
        break;
      case "payment_type":
        setPaymenttype(event);
        break;
      case "bank_name":
        setBankName(event);
        break;
      case "payment_date":
        setPaymentDate(event);
        break;
      default:
        setPaymenttype("");
    }
  }

  const confirmPayment = (e) => {
    e.preventDefault();
    if (totalCount === 0) {
      toast.error(t("No Invoice is Selected"), {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (totalMakePayment <= 0) {
      toast.error(t("Total Amount can not be negative"), {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (c_payment_mode == "") {
      setError({ payment_mode: t("Please Select Payment Mode") });
      ////errors["expected_arrival_date"] = "Cannot be empty";
    } else if (c_payment_type === "") {
      setError({ payment_type: t("Please select Payment type") });
    } else if (c_bank_name == "") {
      if (c_payment_mode === "Offline" || c_payment_mode === "offline") {
        if (c_payment_date === "") {
          setError({ payment_date: t("Please enter payment date") });
        } else {
          var invoiceId = [];
          for (var i = 0; i < getPendingPaymentStatusData.length; i++) {
            var dataId = getPendingPaymentStatusData[i].id;
            invoiceId.push(dataId);
          }
          const data = {
            customerName: customerNameTh,
            division: defaultCat,
            invoiceId: getselectedPaymentData,
            // "docCurrency": getPendingPaymentStatusData && getPendingPaymentStatusData ? getPendingPaymentStatusData && getPendingPaymentStatusData[0].docCurrency : '',
            paymentamount: String(totalMakePaymentValue),
            paymentbank: c_bank_name,
            paymentmode: c_payment_mode,

            paymentstatus: "active",
            paymentamountwords: THBText(String(totalMakePaymentValue)),
            paymenttype: c_payment_type,
            soldtoNumber: localStorage.getItem("CustomerNumber"),
            transationdate: c_payment_date,
          };
          dispatch(paymentofflineActions.confirmPaymentButton(data));
          setOpen(true);
        }
      } else {
        setError({ bank_name: t("Please Select Bank Name") });
      }
    } else if (c_payment_date === "") {
      setError({ payment_date: t("Please enter payment date") });
    } else {
      var invoiceId = [];
      for (var i = 0; i < getPendingPaymentStatusData.length; i++) {
        var dataId = getPendingPaymentStatusData[i].id;
        invoiceId.push(dataId);
      }
      const data = {
        customerName: customerNameTh,
        division: defaultCat,
        invoiceId: getselectedPaymentData,
        // "docCurrency": getPendingPaymentStatusData && getPendingPaymentStatusData ? getPendingPaymentStatusData && getPendingPaymentStatusData[0].docCurrency : '',
        paymentamount: String(totalMakePaymentValue),
        paymentbank: c_bank_name,
        paymentmode: c_payment_mode,
        paymentamountwords: THBText(String(totalMakePaymentValue)),
        paymentstatus: "active",
        paymenttype: c_payment_type,
        soldtoNumber: localStorage.getItem("CustomerNumber"),
        transationdate: c_payment_date,
      };
      dispatch(paymentofflineActions.confirmPaymentButton(data));
      setOpen(true);
    }
  };

  const confirmOnlinePayment = (e) => {
    e.preventDefault();
    if (totalCount === 0) {
      toast.error(t("No Invoice is Selected"), {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (totalMakePayment <= 0) {
      toast.error(t("Total Amount can not be negative"), {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (c_payment_mode == "") {
      setError({ payment_mode: t("Please Select Payment Mode") });
      ////errors["expected_arrival_date"] = "Cannot be empty";
    } else if (c_payment_type === "") {
      setError({ payment_type: t("Please select Payment type") });
    } else if (c_bank_name == "") {
      if (c_payment_mode === "Offline" || c_payment_mode === "offline") {
        if (c_payment_date === "") {
          setError({ payment_date: t("Please enter payment date") });
        } else {
          setLoadingOne(true);
          var invoiceId = [];
          for (var i = 0; i < getPendingPaymentStatusData.length; i++) {
            var dataId = getPendingPaymentStatusData[i].id;
            invoiceId.push(dataId);
          }
          const data = {
            customerName: customerNameTh,
            division: defaultCat,
            invoiceId: getselectedPaymentData,
            // "docCurrency": getPendingPaymentStatusData && getPendingPaymentStatusData ? getPendingPaymentStatusData && getPendingPaymentStatusData[0].docCurrency : '',
            paymentamount: String(totalMakePaymentValue),
            paymentbank: c_bank_name,
            paymentmode: c_payment_mode,

            paymentstatus: "active",
            paymentamountwords: THBText(String(totalMakePaymentValue)),
            paymenttype: c_payment_type,
            soldtoNumber: localStorage.getItem("CustomerNumber"),
            transationdate: c_payment_date,
          };

          dispatch(paymentofflineActions.confirmPaymentButtonOnline(data));
          // setTimeout(() => {
          //     // window.location.href = confirmPaymentButtonOnlineDataURL && confirmPaymentButtonOnlineDataURL.url;
          //     //history.push(confirmPaymentButtonOnlineDataURL && confirmPaymentButtonOnlineDataURL.url)

          //   }, 2000);
        }
      } else {
        setError({ bank_name: t("Please Select Bank Name") });
      }
    } else if (c_payment_date === "") {
      setError({ payment_date: t("Please enter payment date") });
    } else {
      var invoiceId = [];
      for (var i = 0; i < getPendingPaymentStatusData.length; i++) {
        var dataId = getPendingPaymentStatusData[i].id;
        invoiceId.push(dataId);
      }
      const data = {
        customerName: customerNameTh,
        division: defaultCat,
        invoiceId: getselectedPaymentData,
        // "docCurrency": getPendingPaymentStatusData && getPendingPaymentStatusData ? getPendingPaymentStatusData && getPendingPaymentStatusData[0].docCurrency : '',
        paymentamount: String(totalMakePaymentValue),
        paymentbank: c_bank_name,
        paymentmode: c_payment_mode,
        paymentamountwords: THBText(String(totalMakePaymentValue)),
        paymentstatus: "active",
        paymenttype: c_payment_type,
        soldtoNumber: localStorage.getItem("CustomerNumber"),
        transationdate: c_payment_date,
      };
      dispatch(paymentofflineActions.confirmPaymentButtonOnline(data));
    }
    setTimeout(() => {
      setLoadingOne(false);
    }, 2000);
  };

    const formatBankName = (bankName) => {
      if(bankName === "Siam Commercial Bank (SCB) - Business Net"){
        return {paymenttype: "DD", paymentbank: "SCB" }
      } else if(bankName === "Krungsri Bank - Krungsri Online"){
        return c_payment_type === "Customer Financing" ? {paymenttype: "CF", paymentbank: "BAY" } : {paymenttype: "DD", paymentbank: "BAY" }
      } else if(bankName === "Kasikorn Bank - K-Cash Connect"){
        return c_payment_type === "Customer Financing" ? {paymenttype: "CF", paymentbank: "KBANK" } : {paymenttype: "DD", paymentbank: "KBANK" }
      }
    }

    // const changeData4WarningCaseTesting = (data) => {
    //   const items = data.map( item =>{
    //     return item.amountDocCurrency === "879.54" ? {...item, lineItem: "0"} : item
    //   })
    //   console.log("hello", items)
    //   return items;
    // }

    const modifyThaiTextForWarning = (refNo) => {
      if(selectedLangCode === "en"){
        return `There is an error in some invoice under payment reference number ${refNo}, please contact wecare (1732)`;
      }
      return `มีข้อผิดพลาดในใบแจ้งหนี้บางรายการ ภายใต้หมายเลขอ้างอิงการชำระเงิน ${refNo} โปรดติดต่อ wecare (1732)`
    }
  
    const bankErrorMessage = () => {
      if(selectedLangCode === "en"){
        return `Bank Services are not available, please try after some time.`;
      }
      return `ธนาคารปิดให้บริการชั่วคราว โปรดลองอีกครั้งในภายหลัง`;
    }
  
    const toastClasses = {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }

  
  const sendForApprovalRequest = () => {
    const postdata = {
      invoiceId: getselectedPaymentData,
      paymentmode: c_payment_mode,
      transationdate: currentDate,
      paymentamountwords: THBText(String(totalMakePaymentValue)),
      division: defaultCat,
      customerName: customerNameTh,
      paymentamount: String(totalMakePaymentValue),
      createdOn: currentDate,
      soldtoNumber: localStorage.getItem("CustomerNumber"),
      pendingApproval: "true",
      preparedBy: userId,
      approvedBy: "",
      ...formatBankName(c_bank_name),
    }

    Axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL_CONFIRMPAYMENT + `/onlinePayment/sendForApproval`,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
      data: postdata,
    })
      .then(async (response) => {
        if(response.data.status === 200 && response.data.message === "Sucess"){
          setPaymentRefNo(response.data.data.paymentrefno);
          setOpenPaymentRefPopup(true);
          
        } else if(response.data.status === 300 && response.data.message === "Failed"){
          toast.error(modifyThaiTextForWarning(response.data.data), toastClasses);
          setIsLoading(false);
        } else if(response.data.status === 500){
          toast.error(bankErrorMessage(), toastClasses);
          setIsLoading(false);
        } else{
          toast.error(t("Something is Wrong."), toastClasses);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(t("Something is Wrong."), toastClasses);
        setIsLoading(false);
      });
  };

  const confirmOnlineOwnerPayment = async () => {
    if (totalCount === 0) {
      toast.error(t("No Invoice is Selected"), toastClasses);
      return;
    } else if (totalMakePayment <= 0) {
      toast.error(t("Total Amount can not be negative"), toastClasses);
      return;
    }

    if (c_payment_mode === "") {
      setError({ payment_mode: t("Please Select Payment Mode") });
    } else if (c_payment_type === "") {
      setError({ payment_type: t("Please select Payment type") });
    } else if (c_bank_name === "") {
      setError({ bank_name: t("Please Select Bank Name") });
    } else {
      setIsLoading(true);
      const postdata = {
        // invoiceId: changeData(getselectedPaymentData),
        invoiceId: getselectedPaymentData,
        paymentmode: c_payment_mode,
        transationdate: currentDate,
        paymentamountwords: THBText(String(totalMakePaymentValue)),
        division: defaultCat,
        customerName: customerNameTh,
        paymentamount: String(totalMakePaymentValue),
        createdOn: currentDate,
        soldtoNumber: localStorage.getItem("CustomerNumber"),
        pendingApproval: "true",
        preparedBy: userId,
        approvedBy: "",
        ...formatBankName(c_bank_name),
      }
      const endPoint = process.env.REACT_APP_API_URL_CONFIRMPAYMENT +`/onlinePayment/confirmPayment`
      const apiResponse = await putAxiosCall(endPoint, postdata);
      // console.log('hello', apiResponse);
      
      if(apiResponse.status === 200 &&  apiResponse.message === "Sucess" && apiResponse.data !=="WIP" ){
        // console.log('hello', apiResponse);
        if(c_bank_name === "Kasikorn Bank - K-Cash Connect" && c_payment_type === "DirectDebit"){
          const paymentId = apiResponse.data?.split("?")[1].split("=")[1];
          localStorage.setItem("payId", paymentId);

          window.location.replace(apiResponse.data);

        } else if(c_bank_name === "Kasikorn Bank - K-Cash Connect" && c_payment_type === "Customer Financing"){
          const redirect = apiResponse.data;
          window.location.href = redirect;

        } else if(c_bank_name === "Siam Commercial Bank (SCB) - Business Net"){
          const referenceNo = apiResponse.data?.split("&")[1].split("=")[1];
          const url = apiResponse.data?.split("&")[0]
          localStorage.setItem("referenceNo", referenceNo);

          window.location.href = url;
        } else{
          window.location.replace(apiResponse.data);
        }

      } else if(apiResponse.status === 300 && apiResponse.message === "Failed"){
        toast.error(modifyThaiTextForWarning(apiResponse.data), toastClasses);
        setIsLoading(false);
      } else if(apiResponse.status === 500){
        toast.error(bankErrorMessage(), toastClasses);
        setIsLoading(false);
      } else{
        toast.error(t("Something is Wrong."), toastClasses);
        setIsLoading(false);
      }
    }
  };

  const confirmOnlineStaffPayment = () => {
    if (totalCount === 0) {
      toast.error(t("No Invoice is Selected"), toastClasses);
      return;
    } else if (totalMakePayment <= 0) {
      toast.error(t("Total Amount can not be negative"), toastClasses);
      return;
    }
    
    if (c_payment_mode === "") {
      setError({ payment_mode: t("Please Select Payment Mode") });
    } else if (c_payment_type === "") {
      setError({ payment_type: t("Please select Payment type") });
    } else if (c_bank_name === "") {
      setError({ bank_name: t("Please Select Bank Name") });
    } else {
      setIsLoading(true);
      sendForApprovalRequest();
    }
  };

  // const confirmOnlineStaffPayment = (bankName) => {
  //   if(bankName === "Siam Commercial Bank (SCB) - Business Net"){
  //     return {paymenttype: "DD", paymentbank: "SCB" }
  //   } else if(bankName === "Krungsri Bank - Krungsri Online"){
  //     return {paymenttype: "DD", paymentbank: "BayBank" }
  //   } else if(bankName === "Kasikorn Bank - K-Cash Connect"){
  //     return c_payment_type === "Customer Financing" ? {paymenttype: "CF", paymentbank: "KBankCF" } : {paymenttype: "DD", paymentbank: "KBankDD" }
  //   }
  // }


  const decimalwithcoma = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };

  const MyNewClass = useSelector(
    (state) => state.addclasswithstyle.addclasswithstyle
  );

  async function checkUserIsOwnerStaff () {
    const endPoint = process.env.REACT_APP_API_URL_UMS +`/external-user/getCustomerByUserId/${userId}`
    const apiResponse = await getAxiosCall(endPoint);
    const isOwner = apiResponse?.approveAndMakePaymentRole && apiResponse.approveAndMakePaymentRole === "Active" ? true : false
    setIsUserOwner(isOwner)
  }

  useEffect(() => {
    checkUserIsOwnerStaff();
    setIsLoading(false);
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <Header title={t("label.payment_summary")} />

        <div className={"row ipad_css " + MyNewClass}>
          <div className="mainScroll">
            <div className="payment-summary col-12">
              <div className="row">
                <div className="col-12 mb-2 mt-2">
                  <p className="bigHeading">{t("label.payment_summary")}</p>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
                      <MakeTable
                        decimalwithcoma={decimalwithcoma}
                        docCurrencyData={
                          getselectedPaymentData !== undefined &&
                          getselectedPaymentData &&
                          getselectedPaymentData.length > 0
                            ? getselectedPaymentData &&
                              getselectedPaymentData[0].docCurrency
                            : ""
                        }
                        makePaymentDataFinal={
                          getselectedPaymentData && getselectedPaymentData
                        }
                        checkPaymentSummary={handleCheckPaymentSummary}
                        totalPayment={totalMakePayment && totalMakePayment}
                        totalChecked={totalCount && totalCount}
                        checkedValue={checkedInvoiveData}
                        loading={loading}
                      />
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="">
                        <div className="col-12 mb-5">
                          <TotalPaymentBox
                            decimalwithcoma={decimalwithcoma}
                            docCurrencyData={
                              getselectedPaymentData &&
                              getselectedPaymentData.length > 0
                                ? getselectedPaymentData &&
                                  getselectedPaymentData[0].docCurrency
                                : ""
                            }
                            totalMakePayment={totalMakePayment}
                          />
                        </div>
                        <div className="col-12 pb-5">
                          <div className="form_section p-0">
                            <div className="row">
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                <div className="inputBox">
                                  <label>{t("label.paymentmode")}</label>

                                  <FormSelectbox
                                    name={"payment_mode"}
                                    class={"input"}
                                    onSelectChange={onChangeHandle}
                                    label={t("Select")}
                                    data={paymentModeData}
                                  />
                                  {errors && errors["payment_mode"] ? (
                                    <span style={{ color: "red" }}>
                                      {errors["payment_mode"]}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                <div className="inputBox">
                                  <label>{t("label.paymenttype")}</label>
                                  <FormSelectbox
                                    name={"payment_type"}
                                    class={"input"}
                                    onSelectChange={onChangeHandle}
                                    label={t("Select")}
                                    data={paymentTypeData}
                                  />
                                  {errors && errors["payment_type"] ? (
                                    <span style={{ color: "red" }}>
                                      {errors["payment_type"]}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                <div className="inputBox">
                                  <label>{t("label.bankname")}</label>
                                  <FormSelectbox
                                    disabledValue={bankDisable}
                                    name={"bank_name"}
                                    class={"input"}
                                    onSelectChange={onChangeHandle}
                                    label={t("Select")}
                                    // data={paymentBankData}
                                    data={filteredBankName}
                                  />
                                  {errors && errors["bank_name"] ? (
                                    <span style={{ color: "red" }}>
                                      {errors["bank_name"]}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {bankDisable && (
                                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                                  <div className="inputBox">
                                    <label>{t("label.payment_date")}</label>
                                    <FormInput
                                      type={"date"}
                                      required="required"
                                      class={"input"}
                                      value={c_payment_date}
                                      name={"payment_date"}
                                      onChange={onChangeHandle}
                                      label={t("eventname.label")}
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                    />
                                    {errors && errors["payment_date"] ? (
                                      <span style={{ color: "red" }}>
                                        {errors["payment_date"]}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-12 p-0 mt-5"></div>
                                                <div className="col-12 p-0 mt-5"></div> 
                                                <div className="col-12 p-0 mt-5"></div>
                                                <div className="col-12 p-0 mt-5"></div> */}
                        {loadingOne && loadingOne ? (
                          <div
                            style={{
                              textAlign: "center",
                              paddingTop: "0",
                              paddingBottom: "10px",
                            }}
                          >
                            <Loading />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-12 mt-0">
                          {c_payment_mode === "Online" ? (
                            <MakePaymentBox
                              label={
                                // userId.startsWith("O")
                                isUserOwner
                                  ? t("payment.confirm_pay")
                                  : t("payment.send_approval")
                              }
                              navigate={false}
                              onClick={
                                // !isLoading && userId?.startsWith("O") 
                                isUserOwner
                                  ? confirmOnlineOwnerPayment
                                  : confirmOnlineStaffPayment
                                  // )
                              }
                              totalPayment={
                                totalMakePayment && totalMakePayment
                              }
                              totalChecked={totalCount && totalCount}
                              docCurrencyData={
                                getselectedPaymentData &&
                                getselectedPaymentData.length > 0
                                  ? getselectedPaymentData &&
                                    getselectedPaymentData[0].docCurrency
                                  : ""
                              }
                              isDisabled={isLoading}
                            />
                          ) : (
                            <MakePaymentBox
                              label={t("payment.confirm_pay")}
                              navigate={true}
                              navigateurl="/"
                              totalPayment={
                                totalMakePayment && totalMakePayment
                              }
                              totalChecked={totalCount && totalCount}
                              onClick={
                                c_payment_mode === "Online"
                                  ? confirmOnlinePayment
                                  : confirmPayment
                              }
                              docCurrencyData={
                                getselectedPaymentData &&
                                getselectedPaymentData.length > 0
                                  ? getselectedPaymentData &&
                                    getselectedPaymentData[0].docCurrency
                                  : ""
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PaymentSummaryConfirmPopup
          open={open && paymentrefnoconfirm}
          // open={true}
          done={t("pickupform.yes")}
          paymentrefno={paymentrefnoconfirm}
          paymentDate={c_payment_date}
          handleClose={handleClose}
        />

        <PaymentReferencePopup
          open={openPaymentRefPopup}
          paymentrefno={paymentRefNo}
        />
      </div>
    </>
  );
}

export default withTranslation()(PaymentSummary);