export const masterConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  GET_EX_CALC_RULES_REQUEST: " GET_EX_CALC_RULES_REQUEST",
  GET_EX_CALC_RULES_SUCCESS: " GET_EX_CALC_RULES_SUCCESS",
  GET_EX_CALC_RULES_FAILURE: " GET_EX_CALC_RULES_FAILURE",

  FORGOTPASSWORD_REQUEST: "POST_FORGOTPASSWORD_REQUEST",
  FORGOTPASSWORD_SUCCESS: "POST_FORGOTPASSWORD_SUCCESS",
  FORGOTPASSWORD_FAILURE: "POST_FORGOTPASSWORD_FAILURE",

  RESETPASSWORD_REQUEST: "GET_RESETPASSWORD_REQUEST",
  RESETPASSWORD_SUCCESS: "GET_RESETPASSWORD_SUCCESS",
  RESETPASSWORD_FAILURE: "GET_RESETPASSWORD_FAILURE",

  SETPASSWORD_REQUEST: "POST_SETPASSWORD_REQUEST",
  SETPASSWORD_SUCCESS: "POST_SETPASSWORD_SUCCESS",
  SETPASSWORD_FAILURE: "POST_SETPASSWORD_FAILURE",

  CUSTOMERPROFILE_REQUEST: "CUSTOMERPROFILE_REQUEST",
  CUSTOMERPROFILE_SUCCESS: "CUSTOMERPROFILE_SUCCESS",
  CUSTOMERPROFILE_FAILURE: "CUSTOMERPROFILE_FAILURE",

  GET_BANNER_REQUEST: " GET_BANNER_REQUEST",
  GET_BANNER_SUCCESS: " GET_BANNER_SUCCESS",
  GET_BANNER_FAILURE: " GET_BANNER_FAILURE",

  GET_DATE_RANGE_REQUEST: "GET_DATE_RANGE_REQUEST",
  GET_DATE_RANGE_SUCCESS: "GET_DATE_RANGE_SUCCESS",
  GET_DATE_RANGE_FAILURE: "GET_DATE_RANGE_FAILURE",

  CUSTOMERRETAILER_REQUEST: "CUSTOMERRETAILER_REQUEST",
  CUSTOMERRETAILER_SUCCESS: "CUSTOMERRETAILER_SUCCESS",
  CUSTOMERRETAILER_FAILURE: "CUSTOMERRETAILER_FAILURE",

  CUSTOMERSOCIALMEDIA_REQUEST: "GET_CUSTOMERSOCIALMEDIA_REQUEST",
  CUSTOMERSOCIALMEDIA_SUCCESS: "GET_CUSTOMERSOCIALMEDIA_SUCCESS",
  CUSTOMERSOCIALMEDIA_FAILURE: "GET_CUSTOMERSOCIALMEDIA_FAILURE",

  SHIPTOCUSTOMER_REQUEST: "GET_SHIPTOCUSTOMER_REQUEST",
  SHIPTOCUSTOMER_SUCCESS: "GET_SHIPTOCUSTOMER_SUCCESS",
  SHIPTOCUSTOMER_FAILURE: "GET_SHIPTOCUSTOMER_FAILURE",

  DELETEVEHICLESBYID_REQUEST: "DELETE_DELETEVEHICLESBYID_REQUEST",
  DELETEVEHICLESBYID_SUCCESS: "DELETE_DELETEVEHICLESBYID_SUCCESS",
  DELETEVEHICLESBYID_FAILURE: "DELETE_SHIPTOCUSTOMER_FAILURE",

  EDITVEHICLESBYID_REQUEST: "EDIT_EDITVEHICLESBYID_REQUEST",
  EDITVEHICLESBYID_SUCCESS: "EDIT_EDITVEHICLESBYID_SUCCESS",
  EDITVEHICLESBYID_FAILURE: "EDIT_EDITVEHICLESBYID_FAILURE",

  UPDATEVEHICLESBYID_REQUEST: "PUT_UPDATEVEHICLESBYID_REQUEST",
  UPDATEVEHICLESBYID_SUCCESS: "PUT_UPDATEVEHICLESBYID_SUCCESS",
  UPDATEVEHICLESBYID_FAILURE: "PUT_UPDATEVEHICLESBYID_FAILURE",

  DELIVERYORDERSTATUS_REQUEST: "GET_DELIVERYORDERSTATUS_REQUEST",
  DELIVERYORDERSTATUS_SUCCESS: "GET_DELIVERYORDERSTATUS_SUCCESS",
  DELIVERYORDERSTATUS_FAILURE: "GET_DELIVERYORDERSTATUS_FAILURE",

  CUSTOMERSOCIALMEDIA_REQUEST: "POST_CUSTOMERSOCIALMEDIA_REQUEST",
  CUSTOMERSOCIALMEDIA_SUCCESS: "POST_CUSTOMERSOCIALMEDIA_SUCCESS",
  CUSTOMERSOCIALMEDIA_FAILURE: "POST_CUSTOMERSOCIALMEDIA_FAILURE",

  ISLOGGED_IN_REQUEST: "ISLOGGED_IN_REQUEST",
  ISLOGGED_IN_SUCCESS: "ISLOGGED_IN_SUCCESS",
  ISLOGGED_IN_FAILURE: "ISLOGGED_IN_FAILURE",

  GET_SHIPPING_CONDITION_REQUEST: "SHIPPING_CONDITION_REQUEST",
  GET_SHIPPING_CONDITION_SUCCESS: "SHIPPING_CONDITION_SUCCESS",
  GET_SHIPPING_CONDITION_FAILURE: "SHIPPING_CONDITION_FAILURE",

  GET_SUB_DEALER_REQUEST: "SUB_DEALER_REQUEST",
  GET_SUB_DEALER_SUCCESS: "SUB_DEALER_SUCCESS",
  GET_SUB_DEALER_FAILURE: "SUB_DEALER_FAILURE",

  GET_VEHICLETYPES_REQUEST: "VEHICLETYPES_REQUEST",
  GET_VEHICLETYPES_SUCCESS: "VEHICLETYPES_SUCCESS",
  GET_VEHICLETYPES_FAILURE: "VEHICLETYPES_FAILURE",

  GETOWNERSHIP_REQUEST: "GET_GETOWNERSHIP_REQUEST",
  GETOWNERSHIP_SUCCESS: "GET_GETOWNERSHIP_SUCCESS",
  GETOWNERSHIP_FAILURE: "GET_GETOWNERSHIP_FAILURE",

  CREATEVEHICLE_REQUEST: "POST_CREATEVEHICLE_REQUEST",
  CREATEVEHICLE_SUCCESS: "POST_CREATEVEHICLE_SUCCESS",
  CREATEVEHICLE_FAILURE: "POST_CREATEVEHICLE_FAILURE",

  GET_VEHICLES_REQUEST: "VEHICLES_REQUEST",
  GET_VEHICLES_SUCCESS: "VEHICLES_SUCCESS",
  GET_VEHICLES_FAILURE: "VEHICLES_FAILURE",

  GET_CHOOSETRANSPORTER_REQUEST: "CHOOSETRANSPORTER_REQUEST",
  GET_CHOOSETRANSPORTER_SUCCESS: "CHOOSETRANSPORTER_SUCCESS",
  GET_CHOOSETRANSPORTER_FAILURE: "CHOOSETRANSPORTER_FAILURE",

  SHIP_TO_VALUE_REQUEST: "SHIP_TO_VALUE_REQUEST",
  SHIP_TO_VALUE_SUCCESS: "SHIP_TO_VALUE_SUCCESS",
  SHIP_TO_VALUE_FAILURE: "SHIP_TO_VALUE_FAILURE",

  GET_SPCLSHIPPING_CONDITION_REQUEST: "SPCLSHIPPING_CONDITION_REQUEST",
  GET_SPCLSHIPPING_CONDITION_SUCCESS: "SPCLSHIPPING_CONDITION_SUCCESS",
  GET_SPCLSHIPPING_CONDITION_FAILURE: "SPCLSHIPPING_CONDITION_FAILURE",

  GET_SHIPPING_TYPE_REQUEST: "SHIPPING_TYPE_REQUEST",
  GET_SHIPPING_TYPE_SUCCESS: "SHIPPING_TYPE_SUCCESS",
  GET_SHIPPING_TYPE_FAILURE: "SHIPPING_TYPE_FAILURE",

  GET_SHIPPING_TYPE_MYORDER_REQUEST: "SHIPPING_TYPE_MYORDER_REQUEST",
  GET_SHIPPING_TYPE_MYORDER_SUCCESS: "SHIPPING_TYPE_MYORDER_SUCCESS",
  GET_SHIPPING_TYPE_MYORDER_FAILURE: "SHIPPING_TYPE_MYORDER_FAILURE",

  GET_ORDER_TYPE_REQUEST: "ORDER_TYPE_REQUEST",
  GET_ORDER_TYPE_SUCCESS: "ORDER_TYPE_SUCCESS",
  GET_ORDER_TYPE_FAILURE: "ORDER_TYPE_FAILURE",

  GET_CONTRACT_ACCOUNT_REQUEST: "CONTRACT_ACCOUNT_REQUEST",
  GET_CONTRACT_ACCOUNT_SUCCESS: "CONTRACT_ACCOUNT_SUCCESS",
  GET_CONTRACT_ACCOUNT_FAILURE: "CONTRACT_ACCOUNT_FAILURE",

  GET_PREF_TRUCK_TYPE_REQUEST: "PREF_TRUCK_TYPE_REQUEST",
  GET_PREF_TRUCK_TYPE_SUCCESS: "PREF_TRUCK_TYPE_SUCCESS",
  GET_PREF_TRUCK_TYPE_FAILURE: "PREF_TRUCK_TYPE_FAILURE",

  GET_SPECIAL_PROJECT_REQUEST: "SPECIAL_PROJECT_REQUEST",
  GET_SPECIAL_PROJECT_SUCCESS: "SPECIAL_PROJECT_SUCCESS",
  GET_SPECIAL_PROJECT_FAILURE: "SPECIAL_PROJECT_FAILURE",

  GET_ALL_PRODUCT_REQUEST: "ALL_PRODUCT_REQUEST",
  GET_ALL_PRODUCT_SUCCESS: "ALL_PRODUCT_SUCCESS",
  GET_ALL_PRODUCT_FAILURE: "ALL_PRODUCT_FAILURE",

  GET_PLANTBY_COUNTRY_REQUEST: "PLANTBY_COUNTRY_REQUEST",
  GET_PLANTBY_COUNTRY_SUCCESS: "PLANTBY_COUNTRY_SUCCESS",
  GET_PLANTBY_COUNTRY_FAILURE: "PLANTBY_COUNTRY_FAILURE",

  GET_PLANTBY_COUNTRY_VN_REQUEST: "PLANTBY_COUNTRY_VN_REQUEST",
  GET_PLANTBY_COUNTRY_VN_SUCCESS: "PLANTBY_COUNTRY_VN_SUCCESS",
  GET_PLANTBY_COUNTRY_VN_FAILURE: "PLANTBY_COUNTRY_VN_FAILURE",

  GET_SHIPTO_COUNTRY_REQUEST: "SHIPTO_COUNTRY_REQUEST",
  GET_SHIPTO_COUNTRY_SUCCESS: "SHIPTO_COUNTRY_SUCCESS",
  GET_SHIPTO_COUNTRY_FAILURE: "SHIPTO_COUNTRY_FAILURE",

  GET_PROVINCE_REQUEST: "PROVINCE_REQUEST",
  GET_PROVINCE_SUCCESS: "PROVINCE_SUCCESS",
  GET_PROVINCE_FAILURE: "PROVINCE_FAILURE",

  GET_DISTRICT_REQUEST: "DISTRICT_REQUEST",
  GET_DISTRICT_SUCCESS: "DISTRICT_SUCCESS",
  GET_DISTRICT_FAILURE: "DISTRICT_FAILURE",

  GET_SHIPTO_SHIP_VN_REQUEST: "GET_SHIPTO_SHIP_VN_REQUEST",
  GET_SHIPTO_SHIP_VN_SUCCESS: "GET_SHIPTO_SHIP_VN_SUCCESS",
  GET_SHIPTO_SHIP_VN_FAILURE: "GET_SHIPTO_SHIP_VN_FAILURE",

  GET_CASE_TYPE_REQUEST: "CASE_TYPE_REQUEST",
  GET_CASE_TYPE_SUCCESS: "CASE_TYPE_SUCCESS",
  GET_CASE_TYPE_FAILURE: "CASE_TYPE_FAILURE",

  GET_CASE_STATUS_REQUEST: "CASE_STATUS_REQUEST",
  GET_CASE_STATUS_SUCCESS: "CASE_STATUS_SUCCESS",
  GET_CASE_STATUS_FAILURE: "CASE_STATUS_FAILURE",

  GET_BUSINESS_SEGMENT_REQUEST: "BUSINESS_SEGMENT_REQUEST",
  GET_BUSINESS_SEGMENT_SUCCESS: "BUSINESS_SEGMENT_SUCCESS",
  GET_BUSINESS_SEGMENT_FAILURE: "BUSINESS_SEGMENT_FAILURE",

  GET_CASE_CATEGORY_REQUEST: "CASE_CATEGORY_REQUEST",
  GET_CASE_CATEGORY_SUCCESS: "CASE_CATEGORY_SUCCESS",
  GET_CASE_CATEGORY_FAILURE: "CASE_CATEGORY_FAILURE",

  GET_SHIPMENT_STATUS_REQUEST: "SHIPMENT_STATUS_REQUEST",
  GET_SHIPMENT_STATUS_SUCCESS: "SHIPMENT_STATUS_SUCCESS",
  GET_SHIPMENT_STATUS_FAILURE: "SHIPMENT_STATUS_FAILURE",

  GET_SHIPMENT_STATUS_VN_REQUEST: "SHIPMENT_STATUS_VN_REQUEST",
  GET_SHIPMENT_STATUS_VN_SUCCESS: "SHIPMENT_STATUS_VN_SUCCESS",
  GET_SHIPMENT_STATUS_VN_FAILURE: "SHIPMENT_STATUS_VN_FAILURE",

  GET_SHIPMENTFILTERLIST_REQUEST: "GET_SHIPMENTFILTERLIST_REQUEST",
  GET_SHIPMENTFILTERLIST_SUCCESS: "GET_SHIPMENTFILTERLIST_SUCCESS",
  GET_SHIPMENTFILTERLIST_FAILURE: "GET_SHIPMENTFILTERLIST_FAILURE",

  GET_SHIPTO_BYACCNUM_REQUEST: "SHIPTO_BYACCNUM_REQUEST",
  GET_SHIPTO_BYACCNUM_SUCCESS: "SHIPTO_BYACCNUM_SUCCESS",
  GET_SHIPTO_BYACCNUM_FAILURE: "SHIPTO_BYACCNUM_FAILURE",

  GET_SHIPTO_FORSHIPMENT_REQUEST: "SHIPTO_FORSHIPMENT_REQUEST",
  GET_SHIPTO_FORSHIPMENT_SUCCESS: "SHIPTO_FORSHIPMENT_SUCCESS",
  GET_SHIPTO_FORSHIPMENT_FAILURE: "SHIPTO_FORSHIPMENT_FAILURE",

  GET_PRODUCT_FORSHIPMENT_REQUEST: "SHIPTO_PRODUCT_REQUEST",
  GET_PRODUCT_FORSHIPMENT_SUCCESS: "SHIPTO_PRODUCT_SUCCESS",
  GET_PRODUCT_FORSHIPMENT_FAILURE: "SHIPTO_PRODUCT_FAILURE",

  GET_PLANT_FORSHIPMENT_REQUEST: "SHIPTO_PLANT_REQUEST",
  GET_PLANT_FORSHIPMENT_SUCCESS: "SHIPTO_PLANT_SUCCESS",
  GET_PLANT_FORSHIPMENT_FAILURE: "SHIPTO_PLANT_FAILURE",

  GET_DATA_FORSHIPMENT_REQUEST: "DATA_FORSHIPMENT_REQUEST",
  GET_DATA_FORSHIPMENT_SUCCESS: "DATA_FORSHIPMENT_SUCCESS",
  GET_DATA_FORSHIPMENT_FAILURE: "DATA_FORSHIPMENT_FAILURE",

  GET_PRODUCT_CATEGORY_REQUEST: "GET_PRODUCT_CATEGORY_REQUEST",
  GET_PRODUCT_CATEGORY_SUCCESS: "GET_PRODUCT_CATEGORY_SUCCESS",
  GET_PRODUCT_CATEGORY_FAILURE: "GET_PRODUCT_CATEGORY_FAILURE",

  GET_ORDERBY_STATUS_REQUEST: "GET_ORDERBY_STATUS_REQUEST",
  GET_ORDERBY_STATUS_SUCCESS: "GET_ORDERBY_STATUS_SUCCESS",
  GET_ORDERBY_STATUS_FAILURE: "GET_ORDERBY_STATUS_FAILURE",

  GET_SHIP_TO_MY_ORDER_REQUEST: "GET_SHIP_TO_MY_ORDER_REQUEST",
  GET_SHIP_TO_MY_ORDER_SUCCESS: "GET_SHIP_TO_MY_ORDER_SUCCESS",
  GET_SHIP_TO_MY_ORDER_FAILURE: "GET_SHIP_TO_MY_ORDER_FAILURE",

  GET_SEARCHBY_VALUE_MY_ORDER_REQUEST: "GET_SEARCHBY_VALUE_MY_ORDER_REQUEST",
  GET_SEARCHBY_VALUE_MY_ORDER_SUCCESS: "GET_SEARCHBY_VALUE_MY_ORDER_SUCCESS",
  GET_SEARCHBY_VALUE_MY_ORDER_FAILURE: "GET_SEARCHBY_VALUE_MY_ORDER_FAILURE",

  GET_SEARCH_BY_ORDER_NO_REQUEST: " GET_SEARCH_BY_ORDER_NO_REQUEST",
  GET_SEARCH_BY_ORDER_NO_SUCCESS: " GET_SEARCH_BY_ORDER_NO_SUCCESS",
  GET_SEARCH_BY_ORDER_NO_FAILURE: " GET_SEARCH_BY_ORDER_NO_FAILURE",

  SHIPPING_CONDITION_VALUE_REQUEST: "  SHIPPING_CONDITION_VALUE_REQUEST",
  SHIPPING_CONDITION_VALUE_SUCCESS: "  SHIPPING_CONDITION_VALUE_SUCCESS",
  SHIPPING_CONDITION_VALUE_FAILURE: "  SHIPPING_CONDITION_VALUE_FAILURE",

  SHIPPING_TYPE_VALUE_REQUEST: "  SHIPPING_TYPE_VALUE_REQUEST",
  SHIPPING_TYPE_VALUE_SUCCESS: "  SHIPPING_TYPE_VALUE_SUCCESS",
  SHIPPING_TYPE_VALUE_FAILURE: "  SHIPPING_TYPE_VALUE_FAILURE",

  PAGINATION_VALUE_REQUEST: "  PAGINATION_VALUE_REQUEST",
  PAGINATION_VALUE_SUCCESS: "  PAGINATION_VALUE_SUCCESS",
  PAGINATION_VALUE_FAILURE: "  PAGINATION_VALUE_FAILURE",

  GET_PRODUCT_CAT_VALUE_REQUEST: "  GET_PRODUCT_CAT_VALUE_REQUEST",
  GET_PRODUCT_CAT_VALUE_SUCCESS: "  GET_PRODUCT_CAT_VALUE_SUCCESS",
  GET_PRODUCT_CAT_VALUE_FAILURE: "  GET_PRODUCT_CAT_VALUE_FAILURE",

  GET_PRODUCT_CATEGORY_LAVEL_VALUE_REQUEST:
    "   GET_PRODUCT_CATEGORY_LAVEL_VALUE_REQUEST",
  GET_PRODUCT_CATEGORY_LAVEL_VALUE_SUCCESS:
    "   GET_PRODUCT_CATEGORY_LAVEL_VALUE_SUCCESS",
  GET_PRODUCT_CATEGORY_LAVEL_VALUE_FAILURE:
    "   GET_PRODUCT_CATEGORY_LAVEL_VALUE_FAILURE",

  GET_PRODUCT_SUB_CAT_VALUE_REQUEST: "  GET_PRODUCT_SUB_CAT_VALUE_REQUEST",
  GET_PRODUCT_SUB_CAT_VALUE_SUCCESS: "  GET_PRODUCT_SUB_CAT_VALUE_SUCCESS",
  GET_PRODUCT_SUB_CAT_VALUE_FAILURE: "  GET_PRODUCT_SUB_CAT_VALUE_FAILURE",

  GET_PRODUCT_SUB_CATEGORY_LAVEL_VALUE_REQUEST:
    "  GET_PRODUCT_SUB_CATEGORY_LAVEL_VALUE_REQUEST",
  GET_PRODUCT_SUB_CATEGORY_LAVEL_VALUE_SUCCESS:
    "  GET_PRODUCT_SUB_CATEGORY_LAVEL_VALUE_SUCCESS",
  GET_PRODUCT_SUB_CATEGORY_LAVEL_VALUE_FAILURE:
    "  GET_PRODUCT_SUB_CATEGORY_LAVEL_VALUE_FAILURE",

  GET_SPECIAL_SHIP_COND_BY_CAT_REQUEST:
    "  GET_SPECIAL_SHIP_COND_BY_CAT_REQUEST",
  GET_SPECIAL_SHIP_COND_BY_CAT_SUCCESS:
    "  GET_SPECIAL_SHIP_COND_BY_CAT_SUCCESS",
  GET_SPECIAL_SHIP_COND_BY_CAT_FAILURE:
    "  GET_SPECIAL_SHIP_COND_BY_CAT_FAILURE",

  GET_REAMRKS_NOTE_REQUEST: "GET_REAMRKS_NOTE_REQUEST",
  GET_REAMRKS_NOTE_SUCCESS: "GET_REAMRKS_NOTE_SUCCESS",
  GET_REAMRKS_NOTE_FAILURE: "GET_REAMRKS_NOTE_FAILURE",

  UPLOAD_FILE_REQUEST: "UPLOADFILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOADFILE_SUCCESS",
  UPLOAD_FILE_FAILURE: "UPLOADFILE_FAILURE",
  UPLOAD_FILE_RESET: "UPLOADFILE_RESET",

  RETAILER_BY_DISTRICT_REQUEST: "GET_RETAILER_BY_DISTRICT_REQUEST",
  RETAILER_BY_DISTRICT_SUCCESS: "GET_RETAILER_BY_DISTRICT_SUCCESS",
  RETAILER_BY_DISTRICT_FAILURE: "GET_RETAILER_BY_DISTRICT_FAILURE",

  REGION_BY_COUNTRY_CODE_REQUEST: "GET_REGION_BY_COUNTRY_CODE_REQUEST",
  REGION_BY_COUNTRY_CODE_SUCCESS: "GET_REGION_BY_COUNTRY_CODE_SUCCESS",
  REGION_BY_COUNTRY_CODE_FAILURE: "GET_REGION_BY_COUNTRY_CODE_FAILURE",

  PROVINCE_BY_REGION_REQUEST: "GET_PROVINCE_BY_REGION_REQUEST",
  PROVINCE_BY_REGION_SUCCESS: "GET_PROVINCE_BY_REGION_SUCCESS",
  PROVINCE_BY_REGION_FAILURE: "GET_PROVINCE_BY_REGION_FAILURE",

  DISTRICT_BY_PROVINCE_REQUEST: "GET_DISTRICT_BY_PROVINCE_REQUEST",
  DISTRICT_BY_PROVINCE_SUCCESS: "GET_DISTRICT_BY_PROVINCE_SUCCESS",
  DISTRICT_BY_PROVINCE_FAILURE: "GET_DISTRICT_BY_PROVINCE_FAILURE",

  GET_SHIPMENT_CAT_REQUEST: "GET_SHIPMENT_CAT_REQUEST",
  GET_SHIPMENT_CAT_SUCCESS: "GET_SHIPMENT_CAT_SUCCESS",
  GET_SHIPMENT_CAT_FAILURE: "GET_SHIPMENT_CAT_FAILURE",

  SHIP_TO_CUSTOMER_BY_DISTRICT_REQUEST:
    "GET_SHIP_TO_CUSTOMER_BY_DISTRICT_REQUEST",
  SHIP_TO_CUSTOMER_BY_DISTRICT_SUCCESS:
    "GET_SHIP_TO_CUSTOMER_BY_DISTRICT_SUCCESS",
  SHIP_TO_CUSTOMER_BY_DISTRICT_FAILURE:
    "GET_SHIP_TO_CUSTOMER_BY_DISTRICT_FAILURE",
};
