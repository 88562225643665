import React from "react";
import "react-tabs/style/react-tabs.css";
import InProgress from "../../assets/img/In-Progress.png";
import { withTranslation, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Progress = (props) => {
  const { t } = useTranslation();
  // const lancode = localStorage.getItem("lancode");

  return (
    <div className="row ipad_css">
      <div className="mainScroll">
        <div className="col-12">
          <div className="payment-history card1">
            <div
              className="boxSuccess"
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="imgSuccess">
                <img
                  src={InProgress}
                  alt="In-progress"
                  style={{ width: "80px" }}
                />
              </div>
              <div className="boxText" style={{ marginTop: "20px" }}>
                <h2>{props.message? "Your Transaction is In-Progress." : "Your Transaction is In-Progress, we're getting status."}</h2>
              </div>
            </div>
          </div>
          {
            props.ShowButton && (
              <div className="btnPayment">
                <Link className="create_btn mb-3" to="/PaymentHistory">
                  {t("payment.pay_history_btn")}
                </Link>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Progress);
