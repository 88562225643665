import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import Header from "../../../../components/Header/Header";
import Loading from '../../../../components/Loader/Loading'
import 'moment-timezone';
import ContactReportFilters from "./ContactReportFilters";
import ContactReportDataGrid from "./ContactReportDataGrid";
import {
    contractReportList
} from "../../../../_services";
import Axios from "axios";


const INITIAL_FILTER_STATE = {
    "company": "1000",
    "productType": "",
    "contractNo": "",
    "contractName": ""
}

const ContactReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [filterState, setFilterState] = useState(INITIAL_FILTER_STATE);
    const MyNewClass = useSelector((state) => state.addclasswithstyle.addclasswithstyle);
    const [isLoading, setIsLoading] = useState(false);
    const [clearDensity, setClearDensity] = useState("")
    const [isData, setIsData] = useState(false)

    const contractReports = useSelector((state) => state.contractReports)

    const filterReports = () => {
        setIsData(true)
        dispatch(contractReportList(filterState))
    }

    const clearAll = () => {
        setFilterState(INITIAL_FILTER_STATE);
        dispatch({ type: "CONTRACT_LIST", payload: [] })
        setClearDensity("standard")
    }

    useEffect(() => {
        dispatch({ type: "CONTRACT_LIST", payload: [] })
        return () => {
            setIsData(false)
            dispatch({ type: "CONTRACT_LIST", payload: [] })
            dispatch({ type: "IS_CONTRACT_LOADING", payload: false })
        }
    }, [])

    useEffect(() => {
        const url = `/audit-event/create`;
        let details = {
            eventType: "Report_Visit",
            source: "Contract Report",
            userId: (localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')).userId) ?
                JSON.parse(localStorage.getItem('userData')).userId : '',
            customer_number: localStorage.getItem("CustomerNumber"),
        };

        Axios({
            method: "POST",
            url: process.env.REACT_APP_MASTER_API_URL + url,
            data: details,
            headers: {
                "Content-Type": "application/json",
                "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
                "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
            },
        })
            .then(async (response) => {
            })
            .catch((err) => {
            });
    }, [])

    return (
        <div className="content-wrapper">
            {
                isLoading &&
                <div className="firstLoading">
                    <div className="progressLoding">
                        <Loading />
                    </div>
                </div>
            }
            <Header title={t("ContractReport")} />
            <div className={"row ipad_css " + MyNewClass}>
                <div className="mainScroll">
                    <div className="reports-landing-container">
                        <ContactReportFilters
                            setFilterState={setFilterState}
                            filterState={filterState}
                            filterReports={filterReports}
                            clearAll={clearAll}
                            setIsLoading={setIsLoading}
                            INITIAL_FILTER_STATE={INITIAL_FILTER_STATE}
                        />
                        <ContactReportDataGrid
                            rows={Array.isArray(contractReports.contractList) && isData ? contractReports.contractList : []}
                            filterState={filterState}
                            clearDensity={clearDensity}
                            setClearDensity={setClearDensity}
                            loading={contractReports.isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactReport