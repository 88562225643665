import React, { useState, useEffect, useRef } from "react";
import csgImage from "../../assets/img/INSEE-Plus-esg-Web.gif";
import "./esg.scss";
import mobileImg from "../../assets/img/ESG_Mobile.gif";

const ESGCampaign = (props, classes) => {
  const [totalTreesPlanted, setTotalTreesPlanted] = useState("");
  const [treesPlanted, setTreesPlanted] = useState("");
  const img1 = useRef(null);
  const img2 = useRef(null);
  const cN = localStorage.getItem('CustomerNumber');

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'X-AUTH-TOKEN': localStorage.getItem('x-auth-token'),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber")
      },
    };

    // fetch(process.env.REACT_APP_MASTER_API_URL + `/esg`, requestOptions)
    fetch(process.env.REACT_APP_MASTER_API_URL + `/esg/userTreesPlanted?accountNumber=${cN}`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.log("result : ", result)
        // if (result && result.length > 0) {
          // const cN = localStorage.getItem('CustomerNumber');
          // const customerNumber = Number(cN).toString();
          // const findUserData = result.find((item) => item.accountNumber === customerNumber);
          setTreesPlanted(result?.treesPlanted ? result.treesPlanted : "-");
          setTotalTreesPlanted(result?.totalTreesPlanted ? result.totalTreesPlanted : "-");
        // }
      })
      .catch((err) => {
        console.log("55555555555555555555 : ", err);
      })
  }, [cN])

  const countString = (lnth) => {
    return "0000000000".slice(0, lnth);
  }

  return (
    <>
      <div className="image-container web-container">
        <img src={csgImage} alt="Norway" ref={img1} />
        {console.log("treesPlanted : ", treesPlanted === "-")}
        {img1.current?.height > 100 && <>

          <h2 className={`total_planted ${treesPlanted === "-" && "text-center"}`} >{treesPlanted}</h2>
          <h2 className={`customer_planted ${totalTreesPlanted === "-" && "text-center"}`}>{totalTreesPlanted}</h2>

          {/* <h2 className="total_planted" >{treesPlanted !== "-" && <span style={{ visibility: "hidden" }}>{countString(11 - (treesPlanted?.length))}</span>}{treesPlanted}</h2>
          <h2 className="customer_planted">{totalTreesPlanted !== "-" && <span style={{ visibility: "hidden" }}>{countString(11 - (totalTreesPlanted?.length))}</span>}{totalTreesPlanted}</h2> */}
        </>
        }
      </div>
      <div className="image-container mobile-container">
        <img src={mobileImg} alt="Norway" ref={img2} className="esg img-fluid  w-100" />
        {img2.current?.height > 100 && <>
          <h2 className={`total_planted_mb ${treesPlanted === "-" && "text-center"}`}>{treesPlanted}</h2>
          <h2 className={`customer_planted_mb ${totalTreesPlanted === "-" && "text-center"}`}>{totalTreesPlanted}</h2>
        </>}
      </div>
    </>
  );
};

export default ESGCampaign;