import { da } from "date-fns/locale";
import { toast } from "react-toastify";

export const commaFormatter = (num, formatDigit) => {
  const number = num ? num : 0;
  let formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: formatDigit,
  });

  return formatter.format(parseFloat(number).toFixed(formatDigit));
};

//  export const commaFormatter = (num, formatDigit) => {
//   const number = num ? num : 0
//    let formatter = new Intl.NumberFormat("en-US", {
//      minimumFractionDigits: formatDigit,
//    });
//    return formatter.format(Number(number).toFixed(formatDigit));
//  };

export const sortingDateFormatter = (date) => {
  const formatDate = date && date?.split("-").reverse().join("-");
  // console.log('hello', date, {formatDate}, new Date(formatDate));
  return date && new Date(formatDate);
};



export const getAxiosCall = async (endpoint) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
      "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
    }
  };

    const response = await fetch(endpoint,requestOptions);
    const jsonData = await response.json()
    // console.log('hello', jsonData);
    return jsonData;
    // if (jsonData.status === 200) {
    //   console.log(jsonData);
    // }
}

export const postAxiosCall = async (endpoint, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
      "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(endpoint,requestOptions);
    const jsonData = await response.json()
    // console.log('hello', jsonData);
    const responseData = await jsonData.status === 200 && jsonData;
    return responseData;
  } catch (error) {
    console.log(error);
  }
}

export const putAxiosCall = async (endpoint, data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
      "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
    },
    body: JSON.stringify(data),
  };

  console.log('hello', data);
  try {
    const response = await fetch(endpoint,requestOptions);
    const jsonData = await response.json()
    // console.log('hello', jsonData);
    // const responseData = await jsonData.status === 200 && jsonData;
    // return responseData;
    return jsonData;
  } catch (error) {
    console.log(error);
  }
}