import React, { useEffect, useState } from "react";
import logoImage from "./INSEE-life-Logo.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getMyPointsAction } from "../../../_actions/loyaltyPoints.action";
import { useTranslation } from "react-i18next";
import { convertToCurrencyFormat, DataFormat } from "../../../_helpers";
import Axios from "axios"
import "./HeaderText.scss"

export default function HeaderText(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lancode = localStorage.getItem("lancode");
  const getMyPoints = useSelector((state) => state.getMyPoints);
  const [accName,setAccName] = useState();

  useEffect(() => {
    dispatch(getMyPointsAction(localStorage.getItem("CustomerNumber")));
    getCustomerName();
  }, []);

  const getCustomerName = () => {
    Axios.get(
      process.env.REACT_APP_MASTER_API_URL +
        `/customer/getAccountDetails?AccountNumber=` +
        localStorage.getItem("CustomerNumber"),
      {
        headers: {
          "Content-Type": "application/json",
          "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
          "X-SOLD-TO-ID":localStorage.getItem("CustomerNumber")
        },
      }
    )
      .then((response) => {
        if (response.data.status == 200) {
          setAccName(response.data.data);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="loyaltyPageHeader">
      <div className="ImageContainer">
        <img src={logoImage} alt="" />
      </div>
      <div className="textContainer textContainerNotFold">
        <div className="headerText">
          <span>{t("label.account_number")}</span>{" "}
          <span>{DataFormat(localStorage.getItem("CustomerNumber"))}</span>
        </div>
        <div className="headerText">
          <span>{t("label.account_name")}</span>{" "}
          <span>
            {accName && accName ? accName && (lancode === "en" ? accName.accountNameEn : accName.accountNameTh ):""}
          </span>
        </div>
        <div className="headerText">
          <span>{t("label.total_points")}</span>{" "}
          <span>
            {convertToCurrencyFormat(
              getMyPoints?.getMyPointsList?.data?.totalAvailablePoints?.key
            )}
          </span>
        </div>
      </div>

      <div className="textContainer textContainerFold">
        {/* <div className="headerText"> */}
          <span></span>{" "}
          <span><b>{t("label.account_number")} : {" "}</b><br/> {DataFormat(localStorage.getItem("CustomerNumber"))}</span>
        {/* </div> */}
        {/* <div className="headerText"> */}
        <br/>
          <span></span>{" "}
          <span>
            <b className="text-nowrap">{t("label.account_name")} : {" "}</b><br/>
            {accName && accName ? accName && (lancode === "en" ? accName.accountNameEn : accName.accountNameTh ):""}
          </span>
          <br/>
        {/* </div> */}
        {/* <div className="headerText"> */}
          <span></span>{" "}
          <span>
            <b>{t("label.total_points")} : {" "}</b>
            {convertToCurrencyFormat(
              getMyPoints?.getMyPointsList?.data?.totalAvailablePoints?.key
            )}
          </span>
        {/* </div> */}
      </div>

    </div>
  );
}
