import React, { useEffect } from 'react'
import { eventActions } from '../../_actions'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation, useTranslation } from 'react-i18next'
import '../ShiptoRetailersPage/ShiptoRetailersPage.scss'

function DistrictDetail(props) {
  const event = useSelector((state) => state)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  function handleChange(event, name) {
    //setNewvalue(event, name)
    console.log(event, 'event target', name)
  }

  return (
    <>
      <div className='district_page district_page_web'>
        <div class='container'>
          <div className='row'>
            <div className='col-4  district_tile'>
              <p className='dist_head_tit'>{t('Name')}</p>
              <p className='dist_head_val text-break mr-2'>{props.name}</p>
            </div>
            <div className='col-2 district_tile' style={{width:"100px"}}>
              <p className='dist_head_tit'>{t('Code')}</p>
              <p className='dist_head_val text-wrap'>{props.code}</p>
            </div>
            <div className='col-3 district_tile'>
              <p className='dist_head_tit text-nowrap'>{t('District')}</p>
              <p className='dist_head_val text-wrap'>{props.district}</p>
            </div>
            <div className='col-3 district_tile'>
              <p className='dist_head_tit'>{t('Province')}</p>
              <p className='dist_head_val text-wrap'>{props.province}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="district_page district_page_mobile">
        <div class='container'>
        <table class="table table-borderless table-sm">
                    <tbody>
                        <tr>
                            <th scope="row" style={{width:"83px"}}><p className="bgNoText" style={{ fontSize: `${14}px`, color: " #929292", fontSize: "12px", textTransform: "uppercase"}}>{t('Name')}</p></th>
                            {<td><p className="textDark bgNoText textWidth" style={{ fontSize: `${14}px` }}>{props.name}</p></td>}
                        </tr>
                        <tr>
                            <th scope="row" style={{width:"83px"}}><p className="bgNoText" style={{ fontSize: `${14}px`, color: " #929292", fontSize: "12px", textTransform: "uppercase"}}>{t('Code')}</p></th>
                            {<td><p className="textDark bgNoText textWidth" style={{ fontSize: `${14}px` }}>{props.code}</p></td>}
                        </tr>
                        <tr>
                            <th scope="row" style={{width:"83px"}}><p className="bgNoText" style={{ fontSize: `${14}px`, color: " #929292", fontSize: "12px", textTransform: "uppercase" }}>{t('District')}</p></th>
                            {<td><p className="textDark bgNoText textWidth" style={{ fontSize: `${14}px` }}>{props.district}</p></td>}
                        </tr>
                        <tr>
                            <th scope="row" style={{width:"83px"}}><p className="bgNoText" style={{ fontSize: `${14}px`, color: " #929292", fontSize: "12px", textTransform: "uppercase"}}>{t('Province')}</p></th>
                            {<td><p className="textDark bgNoText textWidth" style={{ fontSize: `${14}px` }}>{props.province}</p></td>}
                        </tr>
                    </tbody>
        </table>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(DistrictDetail)
