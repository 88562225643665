import React, { useEffect, useState, useRef } from "react";
import { useLocation, Redirect } from "react-router-dom";
import i18n from "i18next";
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/img/insee-login.png";
import ShowPassword from "../../assets/img/showPassword.png";
import HidePassword from "../../assets/img/hidePass.png";
import { useTranslation, withTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import "./Login.scss";
import { masterActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
// import Loading from "../../components/Loader/Loading";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DbdLogo from "../../components/Footer/Footer";
import Reaptcha from "reaptcha";
import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'
import bgImage from "../../assets/img/INSEE-Plus-bg-on-web-reduce-size.jpg";
import bgMobileImage from "../../assets/img/INSEE-Plus-bg-on-mobile.jpg";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: "25px",
    textAlign: "center",
  },
}))(MuiDialogContent);

const CAPTCHA_SITE_KEY = "6Lebcz0pAAAAADGtIegDt6WnQ4RNjIaU18wRVsrp";

const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const loggedIn = useSelector((state) => state.authentication);
  const forgotPassword = useSelector((state) => state.forgotPassword);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const langCode = localStorage.getItem("lancode");
  const [captchaState, setCaptchaState] = useState(null);
  const [checkErrorMsg, setCheckErrorMsg] = useState(null);
  const is575False = window.matchMedia('(min-width:575px)')
  const [isWeb, setIsWeb] = useState(is575False.matches);

  useEffect(() => {
    localStorage.setItem("lancode", "th");
    i18n.changeLanguage(localStorage.getItem("lancode"));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (forgotPassword && forgotPassword.forgotPassword !== undefined) {
        if (
          forgotPassword.forgotPassword.data ===
          "Password reset link has been sent to registered email!!"
        ) {
          window.location.reload();
        }
      }
    }, 3000);
  });

  function fetchError(err) {
    captchaState.reset();
    setCaptchaError(false);
    setCaptchaToken(null);
    setCheckErrorMsg(err);
    console.log("errerrerrerr : ", err);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const { from } = location.state || { from: { pathname: "/Dashboard" } };
    setCaptchaError(false);
    setCheckErrorMsg(null);
    if (!captchaToken) {
      setCaptchaError(true);
      return;
    }

    dispatch(masterActions.login(username, password, from, fetchError));
    dispatch(masterActions.isLoggedIn(true));
  }

  const forgotpassword = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: "POST_FORGOTPASSWORD_FAILURE",
      payload: "",
    });
    setUsername("");
    // window.location.reload();
  };
  const getUserName = (e) => {
    setUsername(e.target.value);
    dispatch({
      type: "POST_FORGOTPASSWORD_FAILURE",
      payload: "",
    });
  };
  const forgotSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(masterActions.forgotPassword(username));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const onChangePassword = (e) => {
    e.target.setCustomValidity("");
    setPassword(e.target.value);
    setCaptchaError(false);
    setCheckErrorMsg(null);
  };

  const onChangeUsername = (e) => {
    e.target.setCustomValidity("");
    setUsername(e.target.value);
    setCaptchaError(false);
    setCheckErrorMsg(null);
  };

  function onCaptchaChange(recaptchaResponse) {
    console.log("Captcha value:", recaptchaResponse);
    setCaptchaToken(recaptchaResponse);
    setCaptchaError(false);
    setCheckErrorMsg(null);
  }

  function onCaptchaError(e) {
    setCaptchaToken(null);
  }

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  // const grecaptchaObject = window.grecaptcha;
  // // console.log("grecaptchaObject ", grecaptchaObject.reset())
  // useEffect(() => {
  //   console.log("reset Language :", captchaState);
  //   if (captchaState) {
  //     // captchaState.props.grecaptcha.reset();
  //   }
  //   // const grecaptchaObject = window.grecaptcha;
  //   // grecaptchaObject?.render("captcha-id")
  //   // console.log("grecaptchaObject ", grecaptchaObject.reset())
  // }, [langCode]);
  function handleVerify(e) {
    console.log("handleVerify : ", e);
  }

  // const backgrounStryle = {
  //   color: "white",
  //   backgroundColor: "DodgerBlue",
  //   padding: "10px",
  //   fontFamily: "Arial"
  // };

  const backgrounStryle = {
    backgroundImage: `url(${bgImage})`,
    // Adding media query..
    '@media (max-width: 575px)': {
      backgroundImage: `url(${bgMobileImage})`
    },
  };

  window.onresize = function () {
    setIsWeb(is575False.matches)
  };

  return (
    <>
      <Grid className="LoginContainer" style={isWeb ?  {backgroundImage: `url(${bgImage})`} : {backgroundImage: `url(${bgMobileImage})`}}>
        {localStorage.getItem("userData") ? <Redirect to="/Dashboard" /> : null}
        <div className="container login_sec">
          <div className="row mobile_row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div className="login_left_sec">
                <img alt="logo" src={logo} />
                <div className="left_txt">
                  <h4 className="head_txt">
                    {t("welcome.label")} <br />
                    {t("INSEE PLUS")}
                  </h4>
                  <div className="left_add">
                    <div className="address_dtl">
                      <span>
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Thailand
                      </span>
                      <br />
                      <span>
                        <i className="fa fa-phone" aria-hidden="true"></i> 1732
                      </span>
                      <br />
                      <span>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        wecare@siamcitycement.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
              <div className="login_img">
                <div className="login_right_sec mb-3">
                  <div className="title-heading">
                    {/* {captchaError ? <span>{t("captcha.message")}</span> :
                      <>
                        {loggedIn && loggedIn.loggedInFail && loggedIn.message && (
                          <span>
                            {langCode === "en"
                              ? loggedIn?.message.split("-")[0]
                              : loggedIn?.message.split("-")[1]}
                          </span>
                        )}
                      </>
                    } */}

                    {captchaError ? (
                      <span style={{ fontSize: "12px" }}>
                        {t("captcha.message")}
                      </span>
                    ) : (
                      <>
                        {checkErrorMsg && (
                          <span style={{ fontSize: "12px" }}>
                            {langCode === "en"
                              ? checkErrorMsg.split("-")[0]
                              : checkErrorMsg.split("-")[1]}
                          </span>
                        )}
                      </>
                    )}
                    <h4 className="head_txt">{t("login.label")}</h4>
                  </div>
                  <div className="Login_Section form_section form_login_sec">
                    {/* <GoogleReCaptchaProvider reCaptchaKey="6Lebcz0pAAAAADGtIegDt6WnQ4RNjIaU18wRVsrp"> */}
                    <form onSubmit={handleSubmit} method="POST">
                      <div className="inputBox">
                        <input
                          type={"text"}
                          name={"username"}
                          onChange={onChangeUsername}
                          onInvalid={(e) => {
                            e.target.setCustomValidity(t("required.message"));
                          }}
                          placeholder={t("username.label")}
                          className="input"
                          required
                        />
                      </div>
                      <div className="inputBox mb-1 loginPassword">
                        <input
                          type={isRevealPwd ? "text" : "password"}
                          name={"password"}
                          onChange={onChangePassword}
                          onInvalid={(e) => {
                            e.target.setCustomValidity(t("required.message"));
                          }}
                          placeholder={t("password.label")}
                          className="input"
                          required
                          autoComplete="off"
                        />
                        <img
                          title={
                            isRevealPwd ? "Hide password" : "Show password"
                          }
                          src={isRevealPwd ? HidePassword : ShowPassword}
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      </div>
                      <div className="inputBox text-right mb-4 forgot-pswrd">
                        <a onClick={forgotpassword} href="javascript:;">
                          {t("forgotpassword.label")}
                        </a>
                      </div>
                      <div className="mb-3 captcha-id" id="recaptcha1">
                        {/* <Reaptcha
                          // id="captcha-id"
                          ref={(e) => setCaptchaState(e)}
                          sitekey={CAPTCHA_SITE_KEY}
                          onVerify={onCaptchaChange}
                          hl={langCode}
                          onExpire={onCaptchaError}
                        /> */}
                        <ReCAPTCHA
                          key={langCode}
                          ref={(e) => setCaptchaState(e)}
                          sitekey={CAPTCHA_SITE_KEY}
                          onChange={onCaptchaChange}
                          hl={langCode}
                          // grecaptcha={grecaptchaObject}
                          onExpired={onCaptchaError}
                        />

                        {/* <GoogleReCaptcha
     onVerify={handleVerify}
     useRecaptchaNet={true}
     /> */}

                        {console.log("langCode ", langCode)}
                      </div>
                      <Button type={"submit"} label={t("login.button")} />
                    </form>
                    {/* </GoogleReCaptchaProvider> */}
                  </div>
                </div>
              </div>
              <div className="dbdLoginpage">
                <DbdLogo />
              </div>
            </div>
          </div>
        </div>
      </Grid>
      {/* Sold To Selection modal start */}
      <div className="dialog-boxes">
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("forgotpassword.label")}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={forgotSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  id="UserName"
                  value={username}
                  onChange={getUserName}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(t("required.message"))
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                  placeholder={t("username.label")}
                />
                {forgotPassword.forgotPassword && (
                  <span style={{ color: "green" }}>
                    {langCode === "en"
                      ? forgotPassword.forgotPassword.data.split("-")[0]
                      : forgotPassword.forgotPassword.data.split("-")[1]}
                  </span>
                )}
                {forgotPassword.error && (
                  <span style={{ color: "green" }}>
                    {langCode === "en"
                      ? forgotPassword.error.split("-")[0]
                      : forgotPassword.error.split("-")[1]}
                  </span>
                )}
              </div>
              <button
                style={{
                  background: "#ff0000",
                  color: "#fff",
                  border: "1px solid #fff",
                }}
                type="submit"
                className="btn btn-primary"
                disabled={loading || forgotPassword.forgotPassword}
              >
                {t("submit.button")}
              </button>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default withTranslation()(Login);
