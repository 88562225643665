import React, { useEffect, useState } from 'react'
import { orderActions } from '../../../../_actions'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation, useTranslation } from 'react-i18next'
import './MyOrders.scss'
import Header from '../../../../components/Header/Header'
import { useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import "../MyOrders/PrintView.scss";
import Loading from '../../../../components/Loader/Loading'
import ProductImg from '../../../../assets/img/inseeLogo.png'
import { useLocation } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

})

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  containerTable: {
    //   maxHeight: 1000,
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  back_button: {
    backgroundColor: '#000 !important',
    color: '#fff !important',
    marginRight: '8px !important',
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(() => ({
  root: {
    padding: '25px',
    textAlign: 'center',
    // width: '417px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    // margin: 0,
    padding: theme.spacing(1),
    textAlign: 'center',
    marginTop: '20px',
    display: 'block',
  },
}))(MuiDialogActions)

function OrderDetails() {
  const location = useLocation();
  const raiseReleaseStatus = useSelector((state) => state)
  const shipToDetails = useSelector((state) => state.getShipToDetails)
  const classes = useStyles();
  const orderDetails = useSelector(state => state.orderdetail.orderdetail);
  const [open, setOpen] = useState(false)
  const [openCancelSOOne, setOpenCancelSOOne] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()
  let userName = localStorage.getItem('userData')
  userName = JSON.parse(userName)
  const countryCode = userName ? userName.countryCode : ''
  const selectedLangCode = localStorage.getItem('lancode');

  const SmallFontChanger = useSelector((state) => state.smallfontchanger.smallfontchanger);
  const FontChange = useSelector((state) => state.fontsizechanger.fontsizechanger);
  const HeadingFontChange = useSelector((state) => state.headerfontchanger.headerfontchanger);
  const MyNewClass = useSelector((state) => state.addclasswithstyle.addclasswithstyle);
  const cancelSOState = useSelector((state) => state.cancelSO);
  const [iscancellErroropen, setIsCancelledErrorOpen] = useState(false)
  const [cancelConfirm, setCancelConfirm] = useState(false)
  const [cancelMessage, setCancelMessage] = useState("")
    const shipToCodeNo = new URLSearchParams(location.search).get('shipToNumber');
    const orderID = new URLSearchParams(location.search).get('orderID');

  useEffect(() => {
    dispatch(orderActions.getShipToDetails(shipToCodeNo))
    dispatch(orderActions.getOrderDetail(orderID, userName.soldTo[0]));
  }, [shipToCodeNo, orderID])

  const isBorderCustomer = orderDetails && orderDetails.orderListObject !== undefined ?
    orderDetails.orderListObject !== {} && orderDetails.orderListObject &&
      orderDetails.orderListObject.Doc_Currency === null ? 'THB'
      : orderDetails && orderDetails.orderListObject.Doc_Currency : ''

  const navigateToRaiseReleaseRequest = () => {
    dispatch(
      orderActions.raiseReleaseRequest(
        userName.soldTo[0],
        orderDetails &&
        orderDetails.header1.ponumber,
        orderDetails &&
        orderDetails.header1.salesOrderNumber
      )
    )
    console.log(raiseReleaseStatus && raiseReleaseStatus)
    setOpen(true)
  }

  const navigateToCancelSO = () => {
    setOpenCancelSOOne(true)
  }

  const handleClose = () => {
    toast.dark(t('Raise Release Request Success'), {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    setOpen(false)
    dispatch({ type: "CANCEL_SO_FAILURE", error: "" })
  }

  const handleSubmitCancelSO = () => {
    dispatch(
      orderActions.cancelSO(
        userName.soldTo[0],
        orderDetails &&
        orderDetails.orderListObject.ccrz__OrderId__c
      )
    )
    orderActions.cancelSalseOrder(
      userName.soldTo[0],
      orderDetails &&
      orderDetails.orderListObject.ccrz__OrderId__c
    )
      .then(res => {
        if (res.status === 200) {
          setCancelConfirm(true)
        } else {
          setIsCancelledErrorOpen(true)
        }
      })
    setOpenCancelSOOne(false)
    dispatch({ type: "CANCEL_SO_FAILURE", error: "" })
  }

  const handleCloseCancelSOOne = () => {
    setOpenCancelSOOne(false)
  }

  const handleImg = (e) => {
    e.target.src = ProductImg;
  }

  const decimalwithcoma = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }

  const releaseRaiseRequest = () => {
    history.push({
      pathname: '/RaiseReleaseRequest',
      state: { orderdetails: orderDetails },
    })
  }

  const changeSoRequest = () => {
    history.push({
      pathname: '/ChangeSO',
      state: { orderdetails: orderDetails },
    })

  }
  const productPonumber = orderDetails && orderDetails.header1
    ? orderDetails.header1.ponumber
    : ''

  const productSoNumber = orderDetails?.orderListObject
    ? orderDetails.orderListObject
      .ccrz__OrderId__c
    : ''

  const changePaymentMethod = () => {
    dispatch(orderActions.changePaymentMethod(productPonumber, productSoNumber, shipToCodeNo))
  }

  const closeOnCancelled = () => {
    setIsCancelledErrorOpen(false)
    dispatch({ type: "CANCEL_SO_FAILURE", error: "" })
  }

  const cancelConfirmRedirect = () => {
    setCancelConfirm(false)
    history.push('/MyOrder')
  }

  return (
    <>
      <div className='content-wrapper'>
        <Header title="My Order Details" />

        <div className={"row ipad_css " + MyNewClass}>
          <div className='mainScroll'>
            <div className='myorders-container col-12 mt-2 mb-3'>

              <div className='card mt-0'>
                <div className='row place-order-step-box'>
                  <div className='col-12'>
                    <p className='myorder-heading' style={{ fontSize: `${HeadingFontChange}px` }}>{t('Order Details')}</p>
                  </div>
                  {orderDetails ?
                    <>
                      <div className='col-12' style={{ marginTop: '15px' }}>
                        <div className='row'>
                          <div className='col-12 orderDetContainer'>
                            <div className='Rectangle-2231 row mr-0 ml-0'>
                              {
                                countryCode && countryCode === 'VN' ? '' :
                                  <span className='col pl-0 pr-0' style={{ fontSize: `${SmallFontChanger}px` }}>
                                    {t('pono.label')}{' '}
                                    {orderDetails && orderDetails.header1
                                      ? orderDetails.header1.ponumber
                                      : ''}
                                  </span>
                              }

                              <span className='col complit pl-0 pr-0' style={{ fontSize: `${SmallFontChanger}px` }}>
                                {t('sostatus.status')}{': '}
                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Open" ? "พร้อมทำชิปเม้นท์ - Open" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Open" ? "Mở - Open" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Open" ? "พร้อมทำชิปเม้นท์ - Open" : "" : ''
                                }
                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Processing" ? "อยู่ระหว่างดำเนินการ - Processing" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Processing" ? "Đang xử lý - Processing" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Processing" ? "อยู่ระหว่างดำเนินการ - Processing" : "" : ''
                                }

                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Cancel" ? "ยกเลิก - Cancel" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Cancel" ? "Hủy - Cancel" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Cancel" ? "ยกเลิก - Cancel" : "" : ''
                                }

                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Dispatched" ? "รับสินค้าเรียบร้อย - Dispatched" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Dispatched" ? "รับสินค้าเรียบร้อย - Dispatched" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Dispatched" ? "รับสินค้าเรียบร้อย - Dispatched" : "" : ''
                                }

                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Partial" ? "จัดส่งแล้วบางส่วน - Partial" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Partial" ? "จัดส่งแล้วบางส่วน - Partial" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Partial" ? "จัดส่งแล้วบางส่วน - Partial" : "" : ''
                                }

                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Blocked" ? "ถูกบล๊อก - Blocked" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Blocked" ? "Khóa - Blocked" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Blocked" ? "ถูกบล๊อก - Blocked" : "" : ''
                                }

                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Cancelling_Progress" ? "ยกเลิกความคืบหน้า - Cancelling Progress" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Cancelling_Progress" ? "ยกเลิกความคืบหน้า - Cancelling Progress" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Cancelling_Progress" ? "ยกเลิกความคืบหน้า - Cancelling Progress" : "" : ''
                                }

                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Completed" ? "ดำเนินการเรียบร้อย - Completed" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Completed" ? "Đã hoàn tất - Completed" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Completed" ? "ดำเนินการเรียบร้อย - Completed" : "" : ''
                                }

                                {
                                  selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                    orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Failed" ? "ล้มเหลว- Failed" : "" : '' :
                                    selectedLangCode && selectedLangCode === 'vt' ?
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Failed" ? "ล้มเหลว- Failed" : "" : '' :
                                      orderDetails && orderDetails.header1 ? orderDetails.header1.salesOrderStatus === "Failed" ? "ล้มเหลว- Failed" : "" : ''
                                }

                              </span>
                              <span className='col pl-0 pr-0' style={{ fontSize: `${SmallFontChanger}px` }}>
                                {t('SO No.')}:{' '}
                                {orderDetails.orderListObject
                                  ? orderDetails.orderListObject
                                    .ccrz__OrderId__c
                                  : ''}
                              </span>
                              <span className='col pl-0 pr-0' style={{ fontSize: `${SmallFontChanger}px` }}>
                                {t('SO Date')}:{' '}
                                {orderDetails && orderDetails.header1
                                  ? orderDetails.header1.requestDeliveryDate
                                  : ''}
                              </span>
                              <span className='col pl-0 pr-0' style={{ fontSize: `${SmallFontChanger}px` }}>
                                {t('Plant')}:{' '}
                                {orderDetails.items.map((item, i) => {
                                  if (i < 1) {
                                    return <span key={i} style={{ fontSize: `${SmallFontChanger}px` }}>{item.Plant}</span>
                                  }
                                })}
                              </span>
                              <span
                                className='col pl-0 pr-0'
                                style={{ textAlign: 'right', fontSize: `${SmallFontChanger}px` }}
                              >
                                {t('ordertype.label')}:{' '}
                                {
                                  orderDetails && orderDetails.header1 ?
                                    selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                      orderDetails.header1.salesOrderType === "CREDIT" ? 'Credit - เครดิต' : '' :
                                      selectedLangCode && selectedLangCode === 'vt' ?
                                        orderDetails.header1.salesOrderType === "CREDIT" ? 'Credit - Tín dụng' : '' :
                                        orderDetails.header1.salesOrderType === "CREDIT" ? 'Credit - เครดิต' : ''
                                    : ''
                                }

                                {
                                  orderDetails && orderDetails.header1 ?
                                    selectedLangCode && selectedLangCode === 'th' || selectedLangCode && selectedLangCode === null ?
                                      orderDetails.header1.salesOrderType === "CASH" ? 'Cash - เงินสด' : '' :
                                      selectedLangCode && selectedLangCode === 'vt' ?
                                        orderDetails.header1.salesOrderType === "CASH" ? 'Cash - Tiền mặt' : '' :
                                        orderDetails.header1.salesOrderType === "CASH" ? 'Cash - เงินสด' : ''
                                    : ''
                                }
                              </span>
                            </div>

                            {orderDetails != [] && orderDetails !== undefined && orderDetails !== null
                              ?
                              <Paper className={classes.root}>
                                <TableContainer className={classes.containerTable}>
                                  <Table className="tableHaed" stickyHeader aria-label="sticky table">
                                    <TableHead>
                                      <TableRow>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody className='notForPrintout'>
                                      {orderDetails && orderDetails.items ? orderDetails && orderDetails.items.map((row, index) => (
                                        <TableRow key={row.name}>
                                          <TableCell align="center" className="col-xl-1 col-lg-3 col-md-2 col-sm-3 col-xs-4"><img
                                            onError={handleImg}
                                            style={{ height: 50, width: 50, maxHeight: 50, maxWidth: 50, resizeMode: 'contain', fontSize: `${SmallFontChanger}px` }}
                                            src={`${row.MaterialImage}?${new Date().getTime()}`}
                                            alt=''
                                          /></TableCell>
                                          <TableCell className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}> <b><span className="red-text" >{orderDetails && orderDetails.orderItemListObject ?
                                            orderDetails.orderItemListObject[index].Item_Category__c.includes('ZFG') ? "Free" : '' : ''}</span></b> {' '}
                                            {
                                              selectedLangCode === 'en' || selectedLangCode === null ?
                                                row.MaterialName ? row.MaterialName.split(':')[1] : '' : row.MaterialName ? row.MaterialName.split(':')[0] : ''
                                            }
                                          </TableCell>
                                          <TableCell className="col-xl-1 col-lg-3 col-md-2 col-sm-3 col-xs-12 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}>{row.MaterialNumber.replace(/^0+/, '')}</TableCell>
                                          {orderDetails.header1.division && orderDetails.header1.division === 'CW' ?
                                            <TableCell className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-12 pr-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}><b>{row.UnitOfMeasure}</b> {t('UnitOfMeasure')}</TableCell> : ''}
                                          <TableCell className="col-xl-2 col-lg-3 col-md-2 col-sm-3 col-xs-12 pr-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}><b>{orderDetails.orderItemListObject
                                            ? decimalwithcoma(parseFloat(orderDetails.orderItemListObject[index].ccrz__Price__c).toFixed(2)) : '0'} </b>
                                            {t('Price/Unit')}{' '}
                                            {selectedLangCode === 'en' || selectedLangCode === null ?
                                              `(${isBorderCustomer})`
                                              : isBorderCustomer === 'THB' ? '(บาท)' :
                                                isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                            }
                                          </TableCell>
                                          <TableCell className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-12 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}><b>{orderDetails.orderItemListObject && orderDetails.header1.division ===
                                            'CW'
                                            ?
                                            decimalwithcoma(parseFloat(orderDetails
                                              .orderItemListObject[index].Discount_Amount__c).toFixed(2)) + `%  ${t('Discount/Unit')} (%)`
                                            :
                                            countryCode && countryCode === 'VN' ? '' :
                                              decimalwithcoma(parseFloat(orderDetails
                                                .orderItemListObject[index].Discount_Amount__c).toFixed(2)) + ` ${t('Discount/Unit')}
                                              ${selectedLangCode === 'en' || selectedLangCode === null ?
                                                `(${isBorderCustomer})`
                                                : isBorderCustomer === 'THB' ? '(บาท)' :
                                                  isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                              }
                                              `
                                          }
                                            {' '}</b>
                                          </TableCell>
                                          <TableCell className="col-xl-2 col-lg-3 col-md-2 col-sm-3 col-xs-12 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}>
                                            {
                                              countryCode && countryCode === 'VN' ? '' :
                                                <>
                                                  <b>{orderDetails.orderItemListObject
                                                    ? orderDetails.orderItemListObject[index].Freight_Charge_Amount__c.toFixed(2) : '0'}</b>
                                                  {t('Freight/Unit')}  {selectedLangCode === 'en' || selectedLangCode === null ?
                                                    `(${isBorderCustomer})`
                                                    : isBorderCustomer === 'THB' ? '(บาท)' :
                                                      isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`

                                                  }{' '}  </>}
                                          </TableCell>
                                          <TableCell className="col-xl-1 col-lg-3 col-md-2 col-sm-3 col-xs-12 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}>
                                            {orderDetails.header1.division &&
                                              orderDetails.header1.division ===
                                              'CW' ?
                                              <span>
                                                <b>
                                                  {orderDetails.orderItemListObject
                                                    ? parseFloat(orderDetails
                                                      .orderItemListObject[index]
                                                      .Cash_Discount_Amount__c).toFixed(2) + '%'
                                                    : 'NA'}{' '}
                                                </b>
                                                {t('CashDiscount')}(%)
                                              </span>
                                              : null
                                            }
                                          </TableCell>
                                          <TableCell className="col-xl-2 col-lg-3 col-md-2 col-sm-3 col-xs-12 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}>
                                            {orderDetails.header1.division &&
                                              orderDetails.header1.division ===
                                              'CW' ?
                                              <b>
                                                {
                                                  row.UnitOfMeasure === "Each" || "ชิ้น" ? decimalwithcoma(parseFloat(row.OrderQuantity).toFixed(0)) : decimalwithcoma(parseFloat(row.OrderQuantity).toFixed(3))
                                                }
                                                {' '}
                                                {selectedLangCode === 'en' || selectedLangCode === null ?
                                                  row.UnitOfMeasure :
                                                  row.UnitOfMeasure === "TON" ? "ตัน" :
                                                    row.UnitOfMeasure === "Each" ? "ชิ้น" :
                                                      row.UnitOfMeasure}
                                              </b>
                                              :
                                              <b>
                                                {decimalwithcoma(parseFloat(row.OrderQuantity).toFixed(3))}{' '}
                                                {selectedLangCode === 'en' || selectedLangCode === null ?
                                                  row.UnitOfMeasure :
                                                  selectedLangCode === 'vt' ?
                                                    row.UnitOfMeasure === "TON" ? "tấn" :
                                                      row.UnitOfMeasure === "Each" ? "ชิ้น" :
                                                        row.UnitOfMeasure :
                                                    row.UnitOfMeasure === "TON" ? "ตัน" :
                                                      row.UnitOfMeasure === "Each" ? "ชิ้น" :
                                                        row.UnitOfMeasure
                                                }
                                              </b>
                                            }
                                          </TableCell>
                                          <TableCell style={{ fontSize: `${SmallFontChanger}px`, }}>
                                            {orderDetails.header1.division &&
                                              orderDetails.header1.division ===
                                              'CW' ?
                                              <b className='text-nowrap'>
                                                {orderDetails.orderItemListObject
                                                  ? orderDetails.orderItemListObject[index].QuantityInKg : '0'}{' '}
                                                {' '}
                                                {t('KG')}
                                              </b>

                                              : ''
                                            }
                                          </TableCell>
                                        </TableRow>
                                      ))
                                        : null
                                      }
                                    </TableBody>
                                    {/* ############################# This code only for print out purpose #############################333 */}
                                    <TableBody className='printOutOnly'>
                                      {orderDetails && orderDetails.items ? orderDetails && orderDetails.items.map((row, index) => (
                                        <div className='border' key={row.name}>
                                          <TableRow >
                                            <TableCell align="center" className="col-xl-1 col-lg-3 col-md-2 col-sm-3 col-xs-4 pr-0 border-0"><img
                                              onError={handleImg}
                                              style={{ height: 50, width: 50, maxHeight: 50, maxWidth: 50, resizeMode: 'contain', fontSize: `${SmallFontChanger}px` }}
                                              src={`${row.MaterialImage}?${new Date().getTime()}`}
                                              alt=''
                                            /></TableCell>
                                            <TableCell className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 pr-0 border-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px`, width: 100 }}> <b>
                                              <span className="red-text" >{orderDetails && orderDetails.orderItemListObject ?
                                                orderDetails.orderItemListObject[index].Item_Category__c.includes('ZFG') ? "Free" : '' : ''}</span></b> {' '}
                                              {
                                                selectedLangCode === 'en' || selectedLangCode === null ?
                                                  row.MaterialName ? row.MaterialName.split(':')[1] : '' : row.MaterialName ? row.MaterialName.split(':')[0] : ''
                                              }
                                            </TableCell>
                                            <TableCell className="col-xl-1 col-lg-3 col-md-2 col-sm-3 col-xs-12 pr-0 border-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}>{row.MaterialNumber.replace(/^0+/, '')}</TableCell>
                                            {/* {orderDetails.header1.division && orderDetails.header1.division ==='CW' ? 
                                            <TableCell className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-12 pr-0 text-nowrap" style={{fontSize: `${SmallFontChanger}px`}}><b>{row.UnitOfMeasure}</b> {t('UnitOfMeasure')}</TableCell> :'' } */}
                                            <TableCell className="col-xl-2 col-lg-3 col-md-2 col-sm-3 col-xs-12 pr-0 border-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}><b>{orderDetails.orderItemListObject
                                              ? decimalwithcoma(parseFloat(orderDetails.orderItemListObject[index].ccrz__Price__c).toFixed(2)) : '0'} </b>
                                              {t('Price/Unit')}{' '}
                                              {selectedLangCode === 'en' || selectedLangCode === null ?
                                                `(${isBorderCustomer})`
                                                : isBorderCustomer === 'THB' ? '(บาท)' :
                                                  isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                              }
                                            </TableCell>
                                            <TableCell className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-12 pr-0 border-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}><b>{orderDetails.orderItemListObject && orderDetails.header1.division ===
                                              'CW'
                                              ?
                                              decimalwithcoma(parseFloat(orderDetails
                                                .orderItemListObject[index].Discount_Amount__c).toFixed(2)) + `%  ${t('Discount/Unit')} (%)`
                                              :
                                              countryCode && countryCode === 'VN' ? '' :
                                                decimalwithcoma(parseFloat(orderDetails
                                                  .orderItemListObject[index].Discount_Amount__c).toFixed(2)) + ` ${t('Discount/Unit')}
                                              ${selectedLangCode === 'en' || selectedLangCode === null ?
                                                  `(${isBorderCustomer})`
                                                  : isBorderCustomer === 'THB' ? '(บาท)' :
                                                    isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                                }
                                              `
                                            }
                                            </b>
                                            </TableCell>
                                            <TableCell className="col-xl-2 col-lg-3 col-md-2 col-sm-3 col-xs-12 pr-0 border-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}>
                                              {
                                                countryCode && countryCode === 'VN' ? '' :
                                                  <>
                                                    <b>{orderDetails.orderItemListObject
                                                      ? orderDetails.orderItemListObject[index].Freight_Charge_Amount__c.toFixed(2) : '0'}</b>
                                                    {t('Freight/Unit')}  {selectedLangCode === 'en' || selectedLangCode === null ?
                                                      `(${isBorderCustomer})`
                                                      : isBorderCustomer === 'THB' ? '(บาท)' :
                                                        isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                                    }{' '}  </>}
                                            </TableCell>
                                            <TableCell className="col-xl-2 col-lg-3 col-md-2 col-sm-3 col-xs-12 border-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}>
                                              {orderDetails.header1.division &&
                                                orderDetails.header1.division ===
                                                'CW' ?
                                                <b>
                                                  {
                                                    row.UnitOfMeasure === "Each" || "ชิ้น" ? decimalwithcoma(parseFloat(row.OrderQuantity).toFixed(0)) : decimalwithcoma(parseFloat(row.OrderQuantity).toFixed(3))
                                                  }
                                                  {' '}
                                                  {selectedLangCode === 'en' || selectedLangCode === null ?
                                                    row.UnitOfMeasure :
                                                    row.UnitOfMeasure === "TON" ? "ตัน" :
                                                      row.UnitOfMeasure === "Each" ? "ชิ้น" :
                                                        row.UnitOfMeasure}
                                                </b>
                                                :
                                                <b>
                                                  {decimalwithcoma(parseFloat(row.OrderQuantity).toFixed(3))}{' '}
                                                  {selectedLangCode === 'en' || selectedLangCode === null ?
                                                    row.UnitOfMeasure :
                                                    selectedLangCode === 'vt' ?
                                                      row.UnitOfMeasure === "TON" ? "tấn" :
                                                        row.UnitOfMeasure === "Each" ? "ชิ้น" :
                                                          row.UnitOfMeasure :
                                                      row.UnitOfMeasure === "TON" ? "ตัน" :
                                                        row.UnitOfMeasure === "Each" ? "ชิ้น" :
                                                          row.UnitOfMeasure
                                                  }
                                                </b>
                                              }
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell className='border-0'></TableCell>
                                            <TableCell className='border-0'></TableCell>
                                            <TableCell className='border-0'></TableCell>
                                            <TableCell className='border-0'></TableCell>
                                            {orderDetails.header1.division && orderDetails.header1.division === 'CW' ?
                                              <TableCell className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-12 border-0 pr-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}><b>{row.UnitOfMeasure}</b> {t('UnitOfMeasure')}</TableCell> : ''}
                                            <TableCell className="col-xl-1 col-lg-3 col-md-2 col-sm-3 col-xs-12 border-0 pr-0 text-nowrap" style={{ fontSize: `${SmallFontChanger}px` }}>
                                              {orderDetails.header1.division &&
                                                orderDetails.header1.division ===
                                                'CW' ?
                                                <span>
                                                  <b>
                                                    {orderDetails.orderItemListObject
                                                      ? parseFloat(orderDetails
                                                        .orderItemListObject[index]
                                                        .Cash_Discount_Amount__c).toFixed(2) + '%'
                                                      : 'NA'}{' '}
                                                  </b>
                                                  {t('CashDiscount')}(%)
                                                </span>
                                                : null
                                              }
                                            </TableCell>
                                            <TableCell style={{ fontSize: `${SmallFontChanger}px`, whiteSpace: "nowrap" }} className='border-0'>
                                              {orderDetails.header1.division &&
                                                orderDetails.header1.division ===
                                                'CW' ?
                                                <b>
                                                  {orderDetails.orderItemListObject
                                                    ? orderDetails.orderItemListObject[index].QuantityInKg : '0'}{' '}
                                                  {' '}
                                                  {t('KG')}
                                                </b>
                                                : ''
                                              }
                                            </TableCell>
                                          </TableRow>
                                        </div>
                                      ))
                                        : null
                                      }
                                    </TableBody>
                                    {/* ################################################################################################3333 */}
                                  </Table>
                                </TableContainer>
                              </Paper>
                              :
                              null
                            }

                            <div className='Rectangle-108 row mr-0 ml-0'>
                              {
                                countryCode && countryCode === 'VN' ? '' :
                                  <>
                                    <span className='col-6 lighttext' style={{ fontSize: `${SmallFontChanger}px` }}>
                                      {t('Total Price')}
                                      {selectedLangCode === 'en' || selectedLangCode === null ?
                                        `(${isBorderCustomer})`
                                        : isBorderCustomer === 'THB' ? '(บาท)' :
                                          isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                      }
                                    </span>
                                    <span className='col-6 darktext' style={{ fontSize: `${SmallFontChanger}px` }}>
                                      {orderDetails.orderListObject
                                        ? decimalwithcoma(parseFloat(
                                          orderDetails.orderListObject
                                            .totalPriceWithoutTax
                                        )
                                          .toFixed(2))
                                        : 'NA'}
                                    </span>
                                  </>
                              }
                              {
                                countryCode && countryCode === 'VN' ? '' :
                                  <>
                                    <span className='col-6 lighttext' style={{ fontSize: `${FontChange}px` }}>
                                      {t('Total Discount')}
                                      {selectedLangCode === 'en' || selectedLangCode === null ?
                                        `(${isBorderCustomer})`
                                        : isBorderCustomer === 'THB' ? '(บาท)' :
                                          isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                      }
                                    </span>
                                    <span className='col-6 darktext' style={{ fontSize: `${FontChange}px` }}>
                                      {orderDetails.orderListObject
                                        ? decimalwithcoma(parseFloat(
                                          orderDetails.orderListObject.totalDiscount
                                        )
                                          .toFixed(2))
                                        : 'NA'}
                                    </span>
                                  </>
                              }
                              {orderDetails.header1.division &&
                                orderDetails.header1.division ===
                                'CW' ? (
                                <>
                                  <span className='col-6 lighttext' style={{ fontSize: `${FontChange}px` }}>
                                    {t('TotalCashDiscount')}
                                    {selectedLangCode === 'en' || selectedLangCode === null ?
                                      `(${isBorderCustomer})`
                                      : isBorderCustomer === 'THB' ? '(บาท)' :
                                        isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                    }
                                  </span>
                                  <span className='col-6 darktext' style={{ fontSize: `${FontChange}px` }}>
                                    {orderDetails.orderListObject
                                      ? orderDetails &&
                                      decimalwithcoma(parseFloat(orderDetails.orderListObject
                                        .totalCashDiscountAmount).toFixed(2))
                                      : '0'}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}

                              {orderDetails.header1.division &&
                                orderDetails.header1.division ===
                                'CW' ? (
                                <>
                                  <span className='col-6 lighttext' style={{ fontSize: `${FontChange}px` }}>

                                    {t('TotalWeight')}{orderDetails.header1.division &&
                                      orderDetails.header1.division ===
                                      'CW' ? t('KG') : ''}
                                  </span>
                                  <span className='col-6 darktext' style={{ fontSize: `${FontChange}px` }}>
                                    {orderDetails.orderListObject
                                      ? (parseFloat(orderDetails.orderListObject.totalQuantityInKg).toFixed(2))
                                      : '0'}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                              {
                                countryCode && countryCode === 'VN' ? '' :
                                  <>
                                    <span className='col-6 lighttext' style={{ fontSize: `${FontChange}px` }}>
                                      {t('Total Freight')}
                                      {selectedLangCode === 'en' || selectedLangCode === null ?
                                        `(${isBorderCustomer})`
                                        : isBorderCustomer === 'THB' ? '(บาท)' :
                                          isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                      }
                                    </span>
                                    <span className='col-6 darktext' style={{ fontSize: `${FontChange}px` }}>
                                      {orderDetails.orderListObject
                                        ? decimalwithcoma(parseFloat(orderDetails.orderListObject.totalFreight).toFixed(2))
                                        : 'NA'}
                                    </span>
                                  </>
                              }
                              {
                                userName.countryCode === "VN" ? '' :
                                  <>
                                    <span className='col-6 lighttext' style={{ fontSize: `${FontChange}px` }}>
                                      {t('Total Tax')}
                                      {selectedLangCode === 'en' || selectedLangCode === null ?
                                        `(${isBorderCustomer})`
                                        : isBorderCustomer === 'THB' ? '(บาท)' :
                                          isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                      }
                                    </span>
                                    <span className='col-6 darktext' style={{ fontSize: `${FontChange}px` }}>
                                      {orderDetails.orderListObject
                                        ? decimalwithcoma(parseFloat(orderDetails.orderListObject.Tax__c).toFixed(2))
                                        : 'NA'}
                                    </span>
                                  </>
                              }
                            </div>
                            <div className='Rectangle-10887 row mr-0 ml-0'>
                              <span className='col-6 lighttext' style={{ fontSize: `${HeadingFontChange}px` }}>
                                {t('Total')}
                                {selectedLangCode === 'en' || selectedLangCode === null ?
                                  `(${isBorderCustomer})`
                                  : isBorderCustomer === 'THB' ? '(บาท)' :
                                    isBorderCustomer === 'USD' ? '(ดอลล่าร์)' : `(${isBorderCustomer})`
                                }
                              </span>
                              <span className='col-6 darktext' style={{ fontSize: `${HeadingFontChange}px` }}>
                                {orderDetails.orderListObject
                                  ? decimalwithcoma(parseFloat(orderDetails.orderListObject.Total_After_Tax__c).toFixed(2))
                                  : 'NA'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12 col-md-12 col-lg-12 mt-3 shiping-myorder-details'>
                        <p className='mb-1' style={{ fontSize: `${FontChange}px` }}>{t('Ship to')}</p>
                        <p className='shipto-detail' style={{ fontSize: `${FontChange}px` }}>
                          {shipToDetails && shipToDetails.getShipToDetails
                            ? shipToDetails.getShipToDetails.shipToId != "null" ? shipToDetails.getShipToDetails.shipToId.replace(/^0+/, '') : ' '
                            : ' '}{' '}
                          ,
                          {shipToDetails && shipToDetails.getShipToDetails
                            ? shipToDetails.getShipToDetails.shipToName != "null" ?
                              selectedLangCode === 'en' || selectedLangCode === null ?
                                shipToDetails.getShipToDetails.shipToName :
                                selectedLangCode === 'vt' ? shipToDetails.getShipToDetails.shipToName :
                                  shipToDetails.getShipToDetails.shipToNameInLocal
                              : shipToDetails.getShipToDetails.shipToName : ''

                          }{' '}
                          {
                            userName.countryCode === 'VN' ? '' :
                              <>
                                {shipToDetails && shipToDetails.getShipToDetails
                                  ? shipToDetails.getShipToDetails.address.address != "null" ? shipToDetails.getShipToDetails.address.address : ' '
                                  : ' '}
                                {' '}
                                {shipToDetails && shipToDetails.getShipToDetails
                                  ? shipToDetails.getShipToDetails.address.regionId != "null" ? shipToDetails.getShipToDetails.address.regionId : ' '
                                  : ' '}
                                {' '}
                                {shipToDetails && shipToDetails.getShipToDetails
                                  ? shipToDetails.getShipToDetails.street != "null" ? shipToDetails.getShipToDetails.address.street : ' '
                                  : ' '}{' '}
                                {' '}
                                {shipToDetails && shipToDetails.getShipToDetails
                                  ? shipToDetails.getShipToDetails.address.subDistrictId != "null" ? shipToDetails.getShipToDetails.address.subDistrictId : ' '
                                  : ' '}
                                {' '}
                                {shipToDetails && shipToDetails.getShipToDetails
                                  ? shipToDetails.getShipToDetails.address.districtId != "null" ? shipToDetails.getShipToDetails.address.districtId : ' '
                                  : ' '}
                                {' '}

                                {shipToDetails && shipToDetails.getShipToDetails
                                  ? shipToDetails.getShipToDetails.address.provinceId != "null" ?
                                    selectedLangCode === 'en' || selectedLangCode === null ? shipToDetails.getShipToDetails.address.provinceId : shipToDetails.getShipToDetails.address.provinceInLocalLanguage
                                    : ' '
                                  : ' '}{' '}

                                {shipToDetails && shipToDetails.getShipToDetails
                                  ? shipToDetails.getShipToDetails.address.postalCode != "null" ? shipToDetails.getShipToDetails.address.postalCode : ' '
                                  : ' '}{' '}

                                {shipToDetails && shipToDetails.getShipToDetails
                                  ?
                                  shipToDetails.getShipToDetails.address.countryId != "null" ?
                                    selectedLangCode === 'en' || selectedLangCode === null ?
                                      shipToDetails.getShipToDetails.address.countryId === "TH" ? "Thailand"
                                        : shipToDetails.getShipToDetails.address.countryId === "VN" ? 'Vietnam'
                                          : shipToDetails.getShipToDetails.address.countryId === "SL" ? 'Sri lanka'
                                            : shipToDetails.getShipToDetails.address.countryId === "KH" ? 'Comodiya'
                                              : ''
                                      :
                                      shipToDetails.getShipToDetails.address.countryId === "TH" ? "ประเทศไทย"
                                        : shipToDetails.getShipToDetails.address.countryId === "VN" ? 'เวียดนาม'
                                          : shipToDetails.getShipToDetails.address.countryId === "SL" ? 'ศรีลังกา'
                                            : shipToDetails.getShipToDetails.address.countryId === "KH" ? 'Comodiya'
                                              : '' : '' : ''

                                }
                              </>
                          }
                        </p>
                      </div>
                      <div
                        className='col-12'
                        style={{ marginBottom: '10px', marginTop: '-10px' }}
                      >
                        <Divider />
                      </div>
                      <div className='col-sm-3 col-md-3 col-lg-3 shiping-myorder-details'>
                        <p className='mb-1' style={{ fontSize: `${FontChange}px` }}>{t('Shipping Conditions')}</p>
                        <p style={{ fontSize: `${FontChange}px` }}>
                          {
                            orderDetails && orderDetails.header1 && orderDetails.header1
                              ? orderDetails.header1.shippingCondition
                              : 'NA'}
                        </p>
                      </div>
                      {
                        userName.countryCode === "VN" ? '' :
                          <div className='col-sm-3 col-md-3 col-lg-3 shiping-myorder-details'>
                            <p className='mb-1' style={{ fontSize: `${FontChange}px` }}>{t('Special Shipping Conditions')}</p>
                            <p style={{ fontSize: `${FontChange}px` }}>
                              {orderDetails.header1 && orderDetails.header1
                                ?
                                orderDetails.header1.division &&
                                  orderDetails.header1.division ===
                                  'CW' ?
                                  orderDetails.header1.specialProcessingID === "No MHE" ? "-" : '-'
                                  :
                                  selectedLangCode === 'en' || selectedLangCode === null ? orderDetails.header1.specialProcessingID === "No MHE" ? "No MHE" :
                                    orderDetails.header1.specialProcessingID : orderDetails.header1.specialProcessingID === "No MHE" ? "ไม่มีอุปกรณ์ขนถ่าย" : orderDetails.header1.specialProcessingID
                                : 'NA'}
                            </p>
                          </div>
                      }
                      <div className='col-sm-3 col-md-3 col-lg-3 shiping-myorder-details'>
                        <p className='mb-1' style={{ fontSize: `${FontChange}px` }}>{t('Shipping Type')}</p>
                        <p style={{ fontSize: `${FontChange}px` }}>
                          {orderDetails.header1 && orderDetails.header1
                            ? orderDetails.header1.shippingType
                            : 'NA'}
                        </p>
                      </div>
                      {
                        userName.countryCode === "VN" ?
                          orderDetails && orderDetails.header1.division && orderDetails.header1.rebateSalesOrder === true ?
                            <div className='col-sm-3 col-md-3 col-lg-3 shiping-myorder-details'>
                              <p style={{ fontSize: `${FontChange}px` }} className='mb-1'>{t('internalnote.label')}</p>
                              <p style={{ fontSize: `${FontChange}px` }}>
                                {orderDetails && orderDetails.header1 && orderDetails.header1
                                  ? orderDetails.header1.internalNote
                                  : 'NA'}
                              </p>
                            </div> :
                            <div className='col-sm-3 col-md-3 col-lg-3 shiping-myorder-details'>
                              <p style={{ fontSize: `${FontChange}px` }} className='mb-1'>{t('Remarks')}</p>
                              <p style={{ fontSize: `${FontChange}px` }}>
                                {orderDetails && orderDetails.header1 && orderDetails.header1
                                  ? orderDetails.header1.customerNote
                                  : 'NA'}
                              </p>
                            </div>
                          :
                          <div className='col-sm-3 col-md-3 col-lg-3 shiping-myorder-details'>
                            <p style={{ fontSize: `${FontChange}px` }} className='mb-1'>{t('Contract Number')}</p>
                            <p style={{ fontSize: `${FontChange}px` }}>
                              {orderDetails.partnerFunction &&
                                orderDetails.partnerFunction
                                ? orderDetails.partnerFunction.contractNumber + (orderDetails.partnerFunction.contractName ? '-' + orderDetails.partnerFunction.contractName : '')
                                : 'NA'}
                            </p>
                          </div>
                      }
                      <div className='col-sm-12 col-md-12 col-lg-12 mt-3 pl-0'>
                        {(orderDetails &&
                          orderDetails.header1
                            .salesOrderStatus === 'Cancel') ||
                          (orderDetails &&
                            orderDetails.header1
                              .salesOrderStatus === 'Cancelling_Progress') ? null : (
                          <div>
                            {
                              userName.countryCode === "VN" ?
                                <>
                                  {
                                    orderDetails && orderDetails.header1.rebateSalesOrder === true ?
                                      <button onClick={changePaymentMethod} style={{ fontSize: `${FontChange}px` }} className='create_btn'>
                                        {' '}
                                        {t('changepaymentmethod.button')}
                                      </button>
                                      : ''
                                  }
                                  {
                                    orderDetails &&
                                      orderDetails.header1
                                        .salesOrderStatus === 'Blocked' ?
                                      <button onClick={releaseRaiseRequest} style={{ fontSize: `${FontChange}px` }} className='create_btn'>
                                        {' '}
                                        {t('RAISE RELEASE REQUEST')}
                                      </button> :
                                      ''
                                  }
                                </>
                                :
                                orderDetails &&
                                  orderDetails.header1
                                    .salesOrderStatus === 'Blocked' ?
                                  <button
                                    disabled={orderDetails &&
                                      orderDetails.header1.raiseRequestRequestRaised}
                                    className='create_btn'
                                    type='button'
                                    onClick={navigateToRaiseReleaseRequest}
                                    style={{ fontSize: `${FontChange}px` }}
                                  >
                                    {t('Release Raise Request')}{' '}
                                  </button> :
                                  <button
                                    disabled
                                    className='create_btn'
                                    type='button'
                                    onClick={navigateToRaiseReleaseRequest}
                                    style={{ fontSize: `${FontChange}px` }}
                                  >
                                    {t('Release Raise Request')}{' '}
                                  </button>
                            }
                            {
                              userName.countryCode === "VN" ?
                                orderDetails && orderDetails.header1.rebateSalesOrder === true ?
                                  <button onClick={changeSoRequest} className='create_btn' style={{ fontSize: `${FontChange}px` }}>
                                    {' '}
                                    {t('changeso.button')}
                                  </button> : ''
                                :
                                ''
                            }
                            {
                              orderDetails && orderDetails.header1 ? orderDetails && orderDetails.header1.division && orderDetails.header1.division === 'CW' || orderDetails && orderDetails.header1.division && orderDetails.header1.rebateSalesOrder === true ?
                                '' : <button
                                  style={{ textTransform: "inherit" }}
                                  className='create_btn'
                                  type='button'
                                  onClick={navigateToCancelSO}
                                >
                                  {t('Cancel Order')}
                                </button> : null
                            }
                          </div>
                        )}
                      </div>
                    </>
                    : <div className='loading'><Loading /></div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* raise release request start */}
        <div className='dialog-boxes'>
          <Dialog
            onClose={handleClose}
            aria-labelledby='customized-dialog-title'
            open={open}
          >
            <DialogTitle
              id='customized-dialog-title'
              onClose={handleClose}
            ></DialogTitle>
            <DialogContent>
              <Typography>
                {t('Release Raise Email')}{' '}
                {orderDetails?.orderListObject
                  ? orderDetails.orderListObject.ccrz__OrderId__c
                  : 'NA'}
                .
              </Typography>
              <DialogActions>
                <div className='create_link d-flex'>
                  <button className='create pl-5 pr-5' onClick={handleClose}>
                    {t('ok')}
                  </button>
                </div>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>
        {/* raise release request end */}

        {/* cancel so first modal start */}
        <div className='dialog-boxes'>
          <Dialog
            onClose={handleCloseCancelSOOne}
            aria-labelledby='customized-dialog-title'
            open={openCancelSOOne}
          >
            <DialogTitle
              id='customized-dialog-title'
              onClose={handleCloseCancelSOOne}
            ></DialogTitle>
            <DialogContent>
              <Typography>
                {t('Areyousure')}{' '}
                {orderDetails && orderDetails.header1
                  ? orderDetails.header1.ponumber
                  : 'NA'}
                , SO{' '}
                {orderDetails?.orderListObject
                  ? orderDetails.orderListObject.ccrz__OrderId__c
                  : 'NA'}
                .
              </Typography>
              <DialogActions>
                <div className='create_link d-flex'>
                  <button
                    className='create pl-5 pr-5'
                    onClick={handleSubmitCancelSO}
                  >
                    {t('pickupform.yes')}
                  </button>
                  <button
                    className='create pl-5 pr-5'
                    onClick={handleCloseCancelSOOne}
                  >
                    {t('pickupform.no')}
                  </button>
                </div>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>

        {/* popup open after cancel confirm */}

        <div className='dialog-boxes'>
          <Dialog
            onClose={() => setCancelConfirm(false)}
            aria-labelledby='customized-dialog-title'
            open={cancelConfirm}
          >
            <DialogContent style={{ padding: 15 }}>
              <Typography>
                {t('orderCancelSucess')}
              </Typography>
              <DialogActions style={{ marginTop: 0 }}>
                <div className='create_link d-flex'>
                  <button
                    className='create pl-5 pr-5'
                    onClick={cancelConfirmRedirect}
                  >
                    {t('ok')}
                  </button>
                </div>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </div>

        {/* end of cancel confirm popup */}

        {/* raise release request start */}
        <div className='dialog-boxes'>
          <Dialog
            onClose={closeOnCancelled}
            aria-labelledby='customized-dialog-title'
            open={iscancellErroropen}
          >
            <DialogTitle
              id='customized-dialog-title'
              onClose={closeOnCancelled}
            >
            </DialogTitle>
            <DialogContent>
              {cancelMessage.includes("Given order id already CANCELLED") ?
                <Typography>
                  <p>{cancelMessage}</p>
                </Typography>
                :
                <Typography>
                  <b>{t("contCreateOrder")} {orderDetails && orderDetails.header1
                    ? orderDetails.header1.ponumber
                    : ''}</b>
                  <p>{t("beforeCancel")}</p>
                </Typography>
              }
            </DialogContent>
          </Dialog>
        </div>
        {/* cancel so first modal end */}
        {/* setCancelConfirm */}
      </div>
    </>
  )
}

export default withTranslation()(OrderDetails)
