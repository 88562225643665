  
  export const performanceTarget = [{
    country: '2018-2019',
    gold: 36,
    silver: 38,
    bronze: 36,
    color:"gray"
  }, {
   
  }, {
   
  }, {
    country: '2019-2020',
    gold: 19,
    silver: 13,
    bronze: 15,
  }, {
    
  }, {
    
  },

  {
  },

  {
    country: '2020-2021',
    gold: 16,
    silver: 10,
    bronze: 15,
  }

];
  
 
  