import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from "../../../components/Header/Header";
import "./Payment.scss"
// import paymentHistroryTable from "../../../components/Table/paymentHistroryTable";
import PaymentRefTable from "../../../components/Table/PaymentRefTable";
import { paymentofflineActions } from "../../../_actions/paymentoffline.action";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@material-ui/core";
import ExportJsonDataToExcel from "../../../../src/components/exportPopup/ExportJsonDataToExcel"
import Axios from "axios";
import ExportExcelPopup from "../../../components/exportPopup/ExportExcelPopup";

function PaymentRefrence(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const productId = props.location && props.location.state ? props.location.state.id : null
    const paymentHistoryData = useSelector((state) => state.paymentHistoryDetail.paymentHistoryDetail);
    const MyNewClass = useSelector((state) => state.addclasswithstyle.addclasswithstyle);
     
    const formatDataExecl = paymentHistoryData?.inoicesData.map(item => {
      return {
          ...item,
          amountDocCurrency: parseFloat(item.amountDocCurrency)
      }
    })

    useEffect (() => {
        dispatch(paymentofflineActions.paymentHistoryDetail(productId));
    },[0])

    
  const [fileUrl, setFileUrl] = useState("");
  const [exportFiles, setExportFiles] = useState([]);
  const [open, setOpen] = useState(false);

  function exportExcelFile(file_url) {
    setExportFiles([]);
    Axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_URL_CONFIRMPAYMENT +
        `/onlinePayment/bucket?folderName=${file_url}`,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        const newRes = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        setExportFiles(newRes);
      })
      .catch((err) => {
        // setExportState({ btnName: "EXPORT" });
      });
  }

  const lancode = localStorage.getItem('lancode');

  function exportApiCAll() {
    const postObj = {
      id: productId,
      countryCode: lancode,
      excel: "true",
    };

    const url = `/onlinePayment/export/historyDetails`;

    Axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL_CONFIRMPAYMENT + url,
      data: postObj,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        setOpen(true);
        setFileUrl(response.data.data);
        exportExcelFile(response.data.data);
      })
      .catch((err) => {});
  }
    
    return (
      <>
        <div className="content-wrapper">
          <Header
            title={`${t("label.payment_ref_no")} ${
              paymentHistoryData && paymentHistoryData.paymentRefNo
                ? paymentHistoryData.paymentRefNo
                : ""
            }`}
          />
          <div className={"row ipad_css " + MyNewClass}>
            <div className="mainScroll">
              <div className=" row">
              <div className="col-12">
                <div>
                  <ExportExcelPopup
                    title={"Customer"}
                    fileName={"customer"}
                    setOpen={setOpen}
                    open={open}
                    exportExcelFile={exportExcelFile}
                    fileUrl={fileUrl}
                    exportFiles={exportFiles}
                  />
                  {/* <ExportJsonDataToExcel 
                    fileName="Payment-Ref"
                    columnName={column}
                    tableData={formatDataExecl}
                    classes="text-primary float-right mr-2 mt-3"
                    textSize="12px"
                    iconSize="14px"
                  /> */}
                  <Button
                    className="text-primary float-right mr-2 mt-3"
                    style={{ fontSize: "12px" }}   
                    startIcon={<DownloadIcon style={{ fontSize: "14px" }} />}
                    onClick={exportApiCAll}
                  >
                    {t("payment_export")}
                  </Button>
                </div>
                <div className="payment-history card mt-2">
                  <PaymentRefTable paymentHistoryData={paymentHistoryData} />
                </div>
              </div>
                <div className="mt-4 col-12 text-center">
                  <Button
                    className="text-center text-primary "
                    variant="outlined"
                    color="primary"
                    onClick={() => window.history.back()}
                  >
                    {t("Back")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default withTranslation()(PaymentRefrence);
