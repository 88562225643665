import React, { useEffect, useState } from 'react';
import './notification.scss';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { useTranslation } from "react-i18next";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';


const HeaderNotification = () => {
    let history = useHistory();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const lancode = localStorage.getItem("lancode");
    const [options, setOptions] = useState([])
    const open = Boolean(anchorEl);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0)
    const orderID = new URLSearchParams(location.search).get('orderID');
    const countNotify = localStorage.getItem("countNotify");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function changeNotificationStatus(id) {
        var userName = localStorage.getItem('userData')
        userName = JSON.parse(userName)
        Axios({
            method: "PUT", url: process.env.REACT_APP_API_URL_ORDER + `/order/readMarkNotification?notificationId=${id}&userName=${userName.userId}`,
            headers: {
                "Content-Type": "application/json",
                'X-AUTH-TOKEN': localStorage.getItem('x-auth-token'),
                'X-SOLD-TO-ID': localStorage.getItem('CustomerNumber')
            }
        })
            .then((response) => {
                console.log("Notification : ggggg", response.data);
                setOptions(response.data)
                const count = response.data && response.data.length > 0 ? response.data.filter((item) => item.notificationUnread === true) : []
                setNotificationCount(count.length)
                localStorage.setItem("countNotify", count.length);
            })
            .catch((err) => {
                // console.log("Notification : ggggg", err);
            })
    }

    const handleNotificationview = (shipToNumber, orderID, id, notificationUnread) => {
        // console.log("Notification : ggggg", notificationUnread);
        // if (notificationUnread) {
        changeNotificationStatus(id)
        // }

        history.push({
            pathname: '/MyOrderDetails',
            search: `?shipToNumber=${shipToNumber}&orderID=${orderID}`,
        })
    }

    const MINUTE_MS = 20000;

    useEffect(() => {
        function fetchNotification() {
            setIsLoading(true)
            setTimeout(() => {
                Axios({
                    method: "GET", url: process.env.REACT_APP_API_URL_ORDER + '/order/customerNotifications',
                    headers: {
                        "Content-Type": "application/json",
                        'X-AUTH-TOKEN': localStorage.getItem('x-auth-token'),
                        'X-SOLD-TO-ID': localStorage.getItem('CustomerNumber')
                    }
                })
                    .then((response) => {

                        setOptions(response.data.data)
                        const count = response.data?.data.filter((item) => item.notificationUnread === true)
                        setNotificationCount(count.length)
                        localStorage.setItem("countNotify", count.length);
                        setIsLoading(false)

                    })
                    .catch((err) => {
                        setIsLoading(false)
                    })
            }, 3000)
        }

        const interval = setInterval(() => {
            fetchNotification()
        }, MINUTE_MS);

        fetchNotification()
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [localStorage.getItem('CustomerNumber')])


    function countunReadNotification() {
        const crn = options.filter((item) => item.notificationUnread === true)
        return crn.length
    }

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className='shadow-none px-0 mx-0' style={{ backgroundColor: "transparent" }}>
                    <div className='notification'>
                        <i className='fa fa-bell' aria-hidden='true'></i>
                        <span>
                            {/* {countunReadNotification()} */}
                            {/* {notificationCount} */}
                            {countNotify}

                        </span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className='customDropdownStyle'>

                    {options.length > 0 ? options.map((option, index) => {
                        let shipToNumber = option.shipToNumber
                        let orderID = option.salesOrderNumber
                        return (
                            <Dropdown.Item key={index} style={{ width: "98%", margin: 5, padding: 2 }}>
                                <div onClick={() => handleNotificationview(shipToNumber, orderID, option.id, option.notificationUnread)} className={`card notificationContent my-1 py-1 ${option.notificationUnread ? "rowBGColor" : ""}`}>
                                    {lancode === "en" ?
                                        <>
                                            <p className='text-nowrap'>PO Number: <b>{option.ponumber}</b></p>
                                            <p>SO Number: <b>{option.salesOrderNumber} </b><br />was released</p>
                                        </>
                                        :
                                        <>
                                            <p>เลขที่ใบสั่งซื้อ: <b>{option.ponumber}</b></p>
                                            <p>เลขที่ใบรับคำสั่งซื้อ: <b>{option.salesOrderNumber} </b><br />ได้รับการปลดบล็อกเรียบร้อยแล้ว</p>
                                        </>
                                    }
                                </div>
                            </Dropdown.Item>
                        )
                    }) :
                        <Dropdown.Item>
                            <div className={`card notificationContent my-0 py-1 text-center`}>
                                {!isLoading ?
                                    <p>{t("NoNotification")}</p>
                                    :
                                    <Box sx={{ width: '100%', color: 'error.main', bgcolor: 'error.main' }}>
                                        <LinearProgress sx={{ color: 'error.main', bgcolor: 'error.main' }} />
                                    </Box>
                                }
                            </div>
                        </Dropdown.Item>
                    }

                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default HeaderNotification