export const paymentofflineConstants = {
    

    GET_AVAILABLITY_REQUEST: 'AVAILABLITY_REQUEST',
    GET_AVAILABLITY_SUCCESS: 'AVAILABLITY_SUCCESS',
    GET_AVAILABLITY_FAILURE: 'AVAILABLITY_FAILURE',

    GET_SAPAGING_REQUEST: 'SAPAGING_REQUEST',
    GET_SAPAGING_SUCCESS: 'SAPAGING_SUCCESS',
    GET_SAPAGING_FAILURE: 'SAPAGING_FAILURE',


    GET_PAYMENT_MODE_REQUEST: 'PAYMENT_MODE_REQUEST',
    GET_PAYMENT_MODE_SUCCESS: 'PAYMENT_MODE_SUCCESS',
    GET_PAYMENT_MODE_FAILURE: 'PAYMENT_MODE_FAILURE',

    CHECKED_DATA_STATUS_REQUEST: 'CHECKED_DATA_STATUS_REQUEST',
    CHECKED_DATA_STATUS_SUCCESS: 'CHECKED_DATA_STATUS_SUCCESS',
    CHECKED_DATA_STATUS_FAILURE: 'CHECKED_DATA_STATUS_FAILURE',

    GET_CHECKED_DATA_STATUS_REQUEST: 'GET_CHECKED_DATA_STATUS_REQUEST',
    GET_CHECKED_DATA_STATUS_SUCCESS: 'GET_CHECKED_DATA_STATUS_SUCCESS',
    GET_CHECKED_DATA_STATUS_FAILURE: 'GET_CHECKED_DATA_STATUS_FAILURE',

    GET_BAY_BANK_STATUS_REQUEST: 'GET_BAY_BANK_STATUS_REQUEST',
    GET_BAY_BANK_STATUS_SUCCESS: 'GET_BAY_BANK_STATUS_SUCCESS',
    GET_BAY_BANK_STATUS_FAILURE: 'GET_BAY_BANK_STATUS_FAILURE',

    GET_LOAD_CACHE_REQUEST: 'GET_LOAD_CACHE_REQUEST',
    GET_LOAD_CACHE_SUCCESS: 'GET_LOAD_CACHE_SUCCESS',
    GET_LOAD_CACHE_FAILURE: 'GET_LOAD_CACHE_FAILURE',

    
    
    
    GET_PAYMENT_CHART_FILTER_REQUEST: 'GET_PAYMENT_CHART_FILTER_REQUEST',
    GET_PAYMENT_CHART_FILTER_SUCCESS: 'GET_PAYMENT_CHART_FILTER_SUCCESS',
    GET_PAYMENT_CHART_FILTER_FAILURE: 'GET_PAYMENT_CHART_FILTER_FAILURE',

    GET_PAYMENT_TYPE_REQUEST: 'PAYMENT_TYPE_REQUEST',
    GET_PAYMENT_TYPE_SUCCESS: 'PAYMENT_TYPE_SUCCESS',
    GET_PAYMENT_TYPE_FAILURE: 'PAYMENT_TYPE_FAILURE',

    GET_PAYMENT_BANK_REQUEST: 'PAYMENT_BANK_REQUEST',
    GET_PAYMENT_BANK_SUCCESS: 'PAYMENT_BANK_SUCCESS',
    GET_PAYMENT_BANK_FAILURE: 'PAYMENT_BANK_FAILURE',

    GET_PAYMENT_PENDING_REQUEST: 'PAYMENT_PENDING_REQUEST',
    GET_PAYMENT_PENDING_SUCCESS: 'PAYMENT_PENDING_SUCCESS',
    GET_PAYMENT_PENDING_FAILURE: 'PAYMENT_PENDING_FAILURE',

    GET_PAYMENT_PENDING_STATUS_REQUEST: 'GET_PAYMENT_PENDING_STATUS_REQUEST',
    GET_PAYMENT_PENDING_STATUS_SUCCESS: 'GET_PAYMENT_PENDING_STATUS_SUCCESS',
    GET_PAYMENT_PENDING_STATUS_FAILURE: 'GET_PAYMENT_PENDING_STATUS_FAILURE',



    
     PAYMENT_STATUS_REQUEST: ' PAYMENT_STATUS_REQUEST',
     PAYMENT_STATUS_SUCCESS: ' PAYMENT_STATUS_SUCCESS',
     PAYMENT_STATUS_FAILURE: ' PAYMENT_STATUS_FAILURE',

     MAKE_PAYMENT_STATUS_REQUEST: ' MAKE_PAYMENT_STATUS_REQUEST',
     MAKE_PAYMENT_STATUS_SUCCESS: ' MAKE_PAYMENT_STATUS_SUCCESS',
     MAKE_PAYMENT_STATUS_FAILURE: ' MAKE_PAYMENT_STATUS_FAILURE',

     TOTAL_PAYMENT_STATUS_REQUEST: ' TOTAL_PAYMENT_STATUS_REQUEST',
     TOTAL_PAYMENT_STATUS_SUCCESS: ' TOTAL_PAYMENT_STATUS_SUCCESS',
     TOTAL_PAYMENT_STATUS_FAILURE: ' TOTAL_PAYMENT_STATUS_FAILURE',

     

    GET_PAYMENT_SETTLED_REQUEST: 'GET_PAYMENT_SETTLED_REQUEST',
    GET_PAYMENT_SETTLED_SUCCESS: 'GET_PAYMENT_SETTLED_SUCCESS',
    GET_PAYMENT_SETTLED_FAILURE: 'GET_PAYMENT_SETTLED_FAILURE',

    GET_PAYMENT_STATUS_REQUEST: 'PAYMENT_STATUS_REQUEST',
    GET_PAYMENT_STATUS_SUCCESS: 'PAYMENT_STATUS_SUCCESS',
    GET_PAYMENT_STATUS_FAILURE: 'PAYMENT_STATUS_FAILURE',

    PAYMENT_SUMMARY_UPDATE_REQUEST: 'GET_PAYMENT_SUMMARY_UPDATE_REQUEST',
    PAYMENT_SUMMARY_UPDATE_SUCCESS: 'GET_PAYMENT_SUMMARY_UPDATE_SUCCESS',
    PAYMENT_SUMMARY_UPDATE_FAILURE: 'GET_PAYMENT_SUMMARY_UPDATE_FAILURE',

    PAYMENT_CAT_REQUEST : 'PAYMENT_CAT_REQUEST',
    PAYMENT_CAT_SUCCESS : 'PAYMENT_CAT_SUCCESS',
    PAYMENT_CAT_FAILURE : 'PAYMENT_CAT_FAILURE',

    OFFLINE_PAYMENT_STATUS_REQUEST : 'OFFLINE_PAYMENT_STATUS_REQUEST',
    OFFLINE_PAYMENT_STATUS_SUCCESS : 'OFFLINE_PAYMENT_STATUS_SUCCESS',
    OFFLINE_PAYMENT_STATUS_FAILURE : 'OFFLINE_PAYMENT_STATUS_FAILURE',

    PAYMENT_MODE_STATUS_REQUEST : 'PAYMENT_MODE_STATUS_REQUEST',
    PAYMENT_MODE_STATUS_SUCCESS : 'PAYMENT_MODE_STATUS_SUCCESS',
    PAYMENT_MODE_STATUS_FAILURE : 'PAYMENT_MODE_STATUS_FAILURE',

    PAYMENT_TYPE_STATUS_REQUEST : 'PAYMENT_TYPE_STATUS_REQUEST',
    PAYMENT_TYPE_STATUS_SUCCESS : 'PAYMENT_TYPE_STATUS_SUCCESS',
    PAYMENT_TYPE_STATUS_FAILURE : 'PAYMENT_TYPE_STATUS_FAILURE',

    PAYMENT_BANK_STATUS_REQUEST : 'PAYMENT_BANK_STATUS_REQUEST',
    PAYMENT_BANK_STATUS_SUCCESS : 'PAYMENT_BANK_STATUS_SUCCESS',
    PAYMENT_BANK_STATUS_FAILURE : 'PAYMENT_BANK_STATUS_FAILURE',
    

    PAYMENT_DATE_STATUS_REQUEST : 'PAYMENT_DATE_STATUS_REQUEST',
    PAYMENT_DATE_STATUS_SUCCESS : 'PAYMENT_DATE_STATUS_SUCCESS',
    PAYMENT_DATE_STATUS_FAILURE : 'PAYMENT_DATE_STATUS_FAILURE',

    ON_SEARCH_VALUE_REQUEST : 'ON_SEARCH_VALUE_REQUEST',
    ON_SEARCH_VALUE_SUCCESS : 'ON_SEARCH_VALUE_SUCCESS',
    ON_SEARCH_VALUE_FAILURE : 'ON_SEARCH_VALUE_FAILURE',
    
    
    CONFIRM_PAYMENT_STATUS_REQUEST : 'CONFIRM_PAYMENT_STATUS_REQUEST',
    CONFIRM_PAYMENT_STATUS_SUCCESS : 'CONFIRM_PAYMENT_STATUS_SUCCESS',
    CONFIRM_PAYMENT_STATUS_FAILURE : 'CONFIRM_PAYMENT_STATUS_FAILURE',

    CONFIRM_PAYMENT_ONLINE_STATUS_REQUEST : 'CONFIRM_PAYMENT_ONLINE_STATUS_REQUEST',
    CONFIRM_PAYMENT_ONLINE_STATUS_SUCCESS : 'CONFIRM_PAYMENT_ONLINE_STATUS_SUCCESS',
    CONFIRM_PAYMENT_ONLINE_STATUS_FAILURE : 'CONFIRM_PAYMENT_ONLINE_STATUS_FAILURE',




    

    PAYMENT_HISTORY_STATUS_REQUEST : 'PAYMENT_HISTORY_STATUS_REQUEST',
    PAYMENT_HISTORY_STATUS_SUCCESS : 'PAYMENT_HISTORY_STATUS_SUCCESS',
    PAYMENT_HISTORY_STATUS_FAILURE : 'PAYMENT_HISTORY_STATUS_FAILURE',

    PAYMENT_HISTORY_DOWNLOAD_STATUS_REQUEST : 'PAYMENT_HISTORY_DOWNLOAD_STATUS_REQUEST',
    PAYMENT_HISTORY_DOWNLOAD_STATUS_SUCCESS : 'PAYMENT_HISTORY_DOWNLOAD_STATUS_SUCCESS',
    PAYMENT_HISTORY_DOWNLOAD_STATUS_FAILURE : 'PAYMENT_HISTORY_DOWNLOAD_STATUS_FAILURE',

    PAYMENT_HISTORY_DETAILS_STATUS_REQUEST : 'PAYMENT_HISTORY_DETAILS_STATUS_REQUEST',
    PAYMENT_HISTORY_DETAILS_STATUS_SUCCESS : 'PAYMENT_HISTORY_DETAILS_STATUS_SUCCESS',
    PAYMENT_HISTORY_DETAILS_STATUS_FAILURE : 'PAYMENT_HISTORY_DETAILS_STATUS_FAILURE',

   PAYMENT_HISTORY_DELETE_STATUS_REQUEST : 'PAYMENT_HISTORY_DELETE_STATUS_REQUEST',
   PAYMENT_HISTORY_DELETE_STATUS_SUCCESS : 'PAYMENT_HISTORY_DELETE_STATUS_SUCCESS',
   PAYMENT_HISTORY_DELETE_STATUS_FAILURE : 'PAYMENT_HISTORY_DELETE_STATUS_FAILURE',

    
    

    
};
