import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { withTranslation, useTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useDispatch, useSelector } from "react-redux";
import { masterActions } from "../../../_actions";
import { onExportExcel } from "../../../_helpers/exportExcel";
import Header from "../../../components/Header/Header";
import "./VehicleManagement.scss";
import EditVehicle from "./EditVehicle";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    borderBottom: "1px solid rgba(196, 196, 196, 0.5)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  back_button: {
    backgroundColor: "#000 !important",
    color: "#fff !important",
    marginRight: "8px !important",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: "25px",
    textAlign: "center",
    // width: '417px',
  },
}))(MuiDialogContent);

const EditDialogContent = withStyles(() => ({
  root: {
    padding: "25px",
    textAlign: "center",
    width: "100%",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    // margin: 0,
    padding: theme.spacing(1),
    textAlign: "center",
    marginTop: "20px",
    display: "block",
  },
}))(MuiDialogActions);

function VehicleManagement() {
  let userName = localStorage.getItem("userData");
  userName = JSON.parse(userName);
  const countryCode = userName ? userName.countryCode : "TH";
  const inputRefs = useRef([]);
  const [createVehicle, setCreateVehicle] = useState({
    capacity: "",
    countryCode: userName.countryCode,
    expireDate: "2999-12-31",
    licenseNumber: "",
    notes: "",
    ownership: "",
    retailerName: "",
    soldToNumber: localStorage.getItem("CustomerNumber"),
    vehicleId: "",
    vehicleStatus: "",
    vehicleType: "",
  });
  const [erropen, setErropen] = useState(false);
  const [editVehicles, setEditVehicles] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [errorLicenseNo, setErrorLicenseNo] = useState("");
  const [editinput, setEditTrue] = useState(false);
  const updateVehicle = useSelector((state) => state.updateVehicle);
  const getOwnership = useSelector((state) => state.getOwnership);
  const getVehicletypes = useSelector((state) => state.getVehicletypes);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)
  const deleteVehiclesByVehicleId = useSelector(
    (state) => state.deleteVehiclesByVehicleId
  );
  const getVehiclesDetailsById = useSelector(
    (state) => state.getVehiclesDetailsById
  );
  const createVehicleApi = useSelector((state) => state.createVehicle);
  const vehicleData = useSelector((state) => state.getVehicles.getVehicles);
  const langCode = localStorage.getItem('lancode')
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(masterActions.getOwnership());
    dispatch(
      masterActions.getVehicles(
        localStorage.getItem("CustomerNumber"),
        "TRUCK",
        "All"
      )
    );
    dispatch(masterActions.vehicletypes(countryCode));
  }, []);

  const handleChange = (e) => {
    setErrorLicenseNo("");
    var regex = /^[0-9A-Z-]\S*$/;
    var regexCaps = /^[A-Za-z-]\S*$/;
    setEditTrue(true);
    setIsSaveDisabled(false)
    if (countryCode && countryCode === "VN") {
      if (e.target.name === "licenseNumber") {
        setCreateVehicle({
          ...createVehicle,
          [e.target.name]: "",
        });
        if (
          createVehicle.vehicleType === "TRUCK" ||
          createVehicle.vehicleType === "TRAILER"
        ) {
          if (regex.test(e.target.value)) {
            var licenseNumber = e.target.value.toUpperCase();
            setErrorLicenseNo("");
            setCreateVehicle({
              ...createVehicle,
              [e.target.name]: licenseNumber,
            });
          } else {
            setErrorLicenseNo(
              "License No. should be start with number no space"
            );
          }
        } else {
          if (createVehicle.vehicleType === "BARGE") {
            if (regexCaps.test(e.target.value)) {
              var licenseNumberB = e.target.value.toUpperCase();
              setErrorLicenseNo("");
              setCreateVehicle({
                ...createVehicle,
                [e.target.name]: licenseNumberB,
              });
            } else {
              setErrorLicenseNo("License No. should be uppercase letters");
            }
          }
        }
      } else {
        setCreateVehicle({
          ...createVehicle,
          [e.target.name]:
            e.target.name == "capacity"
              ? e.target.value.replace(/[^0-9\.]/g, "")
              : e.target.value,
        });
      }
    } else {
      setCreateVehicle({
        ...createVehicle,
        [e.target.name]:
          e.target.name == "capacity"
            ? e.target.value.replace(/[^0-9\.]/g, "")
            : e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaveDisabled(true)
    dispatch(masterActions.createVehicle(createVehicle));
  };

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const editVehiclesFun = async (vehicleId) => {
    setIsSaveDisabled(false)
    setVehicleId(vehicleId);
    setEditVehicles(true);
    setErropen(true);
    dispatch(masterActions.getVehiclesDetailsById(vehicleId));
  };
  const deleteVehicles = (vehicleId) => {
    setEditVehicles(false);
    setVehicleId(vehicleId);
    setErropen(true);
  };
  const handleClose = () => {
    setErropen(false);
  };
  const confirmRemove = () => {
    setErropen(false);
    dispatch(masterActions.deleteVehiclesByVehicleId(vehicleId));
  };

  useEffect(() => {
    if (
      createVehicleApi.createVehicle !== undefined &&
      createVehicleApi.createVehicle.status == 200
    ) {
      dispatch(
        masterActions.getVehicles(
          localStorage.getItem("CustomerNumber"),
          "TRUCK",
          "All"
        )
      );

      document.getElementById("deleteMessage").innerHTML =
        '<div class="alert alert-success"><button type="button" style="opacity: 1;" class="close" data-dismiss="alert" aria-label="Close"><span style="color: #fff" aria-hidden="true">&times;</span></button><strong>Success!</strong> Vehicle add successfully.</div>';
    } else {


      if (createVehicleApi && createVehicleApi.createVehicle !== undefined) {
        setErrorLicenseNo(createVehicleApi.createVehicle?.message)
      } else {
        setErrorLicenseNo("")
      }

      // document.getElementById("lincenceErr").innerHTML =
      //   "" + createVehicleApi && createVehicleApi.createVehicle !== undefined
      //     ? createVehicleApi.createVehicle.message
      //     : "" + "";
    }
  }, [createVehicleApi.createVehicle]);

  useEffect(() => {
    if (deleteVehiclesByVehicleId.deleteVehiclesById) {
      dispatch(
        masterActions.getVehicles(
          localStorage.getItem("CustomerNumber"),
          "TRUCK",
          "All"
        )
      );
      document.getElementById("deleteMessage").innerHTML =
        '<div class="alert alert-success"><button type="button" style="opacity: 1;" class="close" data-dismiss="alert" aria-label="Close"><span style="color: #fff" aria-hidden="true">&times;</span></button><strong>Success!</strong> Vehicle delete successful.</div>';
    }
  }, [deleteVehiclesByVehicleId.deleteVehiclesById]);
  //handleUpdate
  const handleUpdate = (e) => {
    e.preventDefault();
    setIsSaveDisabled(true)
    const curentValue = inputRefs.current;
    const updateVehicleData = {};

    for (let i = 0; i < curentValue.length; i++) {
      if (curentValue[i] != null) {
        updateVehicleData[curentValue[i].name] = curentValue[i].value;
        updateVehicleData["soldToNumber"] =
          localStorage.getItem("CustomerNumber");
      }
    }

    console.log("updateVehicleData : ", updateVehicleData)
    dispatch(masterActions.updateVehicle(updateVehicleData, vehicleId));
  };
  useEffect(() => {
    if (
      updateVehicle.updateVehicle !== undefined &&
      updateVehicle.updateVehicle.status == 200
    ) {
      dispatch(
        masterActions.getVehicles(
          localStorage.getItem("CustomerNumber"),
          "TRUCK",
          "All"
        )
      );
      document.getElementById("deleteMessage").innerHTML =
        '<div class="alert alert-success"><strong>Success!</strong> Vehicle updated successfully.</div>';
    } else {
      document.getElementById("lincenceErr").innerHTML =
        "" + updateVehicle && updateVehicle.updateVehicle !== undefined
          ? updateVehicle.updateVehicle.message
          : "" + "";
    }
    setErropen(false);
  }, [updateVehicle.updateVehicle]);
  const FontChange = useSelector(
    (state) => state.fontsizechanger.fontsizechanger
  );
  const HeadingFontChange = useSelector(
    (state) => state.headerfontchanger.headerfontchanger
  );
  const MyNewClass = useSelector(
    (state) => state.addclasswithstyle.addclasswithstyle
  );

  const exportTableData = (data) => {
    data = data.map((item) => {
      return ({
        ...item,
        documentDate: item.documentDate ? new Date(item.documentDate) : "",
        expireDate: item.expireDate ? new Date(item.expireDate) : "",
      })
    })

    const columns = [
      {
        label: t("Registered License No."),
        value: "licenseNumber",
      },
      {
        label: t("Vehicle Capacity"),
        value: "capacity",
      },
      {
        label: t("TYPE"),
        value: "vehicleType",
      },
      {
        label: t("Ownership"),
        value: "ownership",
      },
      {
        label: t("Retailer Name"),
        value: "retailerName",
      },
      {
        label: t("Start Date"),
        value: "documentDate",
        format: "dd-mm-yyyy"
      },
      {
        label: t("Expire Date"),
        value: "expireDate",
        format: "dd-mm-yyyy"
      },
      {
        label: t("Note"),
        value: "notes",
      },
    ];

    console.log("datadatadata : ", data)
    onExportExcel(
      columns,
      data,
      `Vehicle_Management_Details_${moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      )}`,
      `Vehicle_Management_Details`
    );
  };
  const [filteredData, setFilteredData] = useState(vehicleData);

  const filterVehicleData = (_, selectedElement) => {
    if (selectedElement) {
      setSearchTerm(selectedElement.licenseNumber);
      const filteredElements = vehicleData.filter(
        (item) =>
          selectedElement &&
          item.licenseNumber?.includes(selectedElement.licenseNumber)
      );
      setFilteredData(filteredElements);
    } else setSearchTerm("");
  };

  const renderTableRow = (data) => {
    return (
      <tr key={data.vehicleId}>
        <td style={{ fontSize: `${FontChange}px` }}>{data.licenseNumber}</td>
        <td style={{ fontSize: `${FontChange}px` }}>{data.capacity}</td>
        <td style={{ fontSize: `${FontChange}px` }}>{data.vehicleType}</td>
        <td style={{ fontSize: `${FontChange}px` }}>{data.ownership}</td>
        <td style={{ fontSize: `${FontChange}px` }}>{data.retailerName}</td>
        <td style={{ fontSize: `${FontChange}px` }}>
          {moment(data.documentDate).format("DD-MM-YYYY")}
        </td>
        <td style={{ fontSize: `${FontChange}px` }}>
          {moment(data.expireDate).format("DD-MM-YYYY")}
        </td>
        <td style={{ fontSize: `${FontChange}px` }}>{data.notes}</td>
        <td className="action">
          <i
            onClick={() => {
              editVehiclesFun(data.vehicleId);
            }}
            className="fa fa-pencil"
            style={{ fontSize: `${FontChange}px` }}
            aria-hidden="true"
          ></i>
          {/* {data && !data.isBlocked &&
            <i
              onClick={() => {
                deleteVehicles(data.vehicleId);
              }}
              className="fa fa-trash"
              style={{ fontSize: `${FontChange}px` }}
              aria-hidden="true"
            ></i>
          } */}

        </td>
      </tr>
    );
  };


  function handleKeyDown(e) {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  function handeErrorMessage(msg){
    let newMsg = msg.split("|");
    newMsg = langCode === "th" ? newMsg[1] : newMsg[0];
    return newMsg
  }
  
  return (
    <>
      <div className="content-wrapper vehicle_management_section">
        <Header title={t("Vehicle Management")} />
        <div className={"row ipad_css " + MyNewClass}>
          <div style={{ width: "100%" }} id="deleteMessage"></div>
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <div className="mainScroll">
              <div className="customer_profile px-4">
                <div className="main-heading pl-0">
                  <h5 style={{ fontSize: `${HeadingFontChange}px` }}>
                    {t("VehicleManagement.heading")}
                  </h5>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                      <div className="inputBox ">
                        <label style={{ fontSize: `${FontChange}px` }}>
                          {t("Vehicle Type")}
                        </label>
                        <select
                          required
                          className="input"
                          name="vehicleType"
                          onChange={handleChange}
                          style={{ fontSize: `${FontChange}px` }}
                        >
                          <option
                            value=""
                            style={{ fontSize: `${FontChange}px` }}
                          >
                            {t("Vehicle Type")}
                          </option>
                          {getVehicletypes &&
                            getVehicletypes.getVehicletypes !== undefined
                            ? getVehicletypes.getVehicletypes.map((data, index) => {
                              return (
                                <option
                                  value={data.key}
                                  style={{ fontSize: `${FontChange}px` }}
                                  key={index}
                                >
                                  {" "}
                                  {capitalize(data.value)}
                                </option>
                              );
                            })
                            : ""}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                      <div className="inputBox ">
                        <label style={{ fontSize: `${FontChange}px` }}>
                          {t("Registered License No.")}
                        </label>
                        <input
                          required
                          type="text"
                          name="licenseNumber"
                          placeholder={t("Registered License No.")}
                          onChange={handleChange}
                          className="input"
                          value={createVehicle["licenseNumber"].toUpperCase()}
                          style={{ fontSize: `${FontChange}px` }}
                        />
                        <span
                          id="lincenceErrd"
                          style={{ color: "red", fontSize: `${FontChange}px` }}
                        >
                          {handeErrorMessage(errorLicenseNo)}
                        </span>

                        <span id="lincenceErr" style={{ color: "red" }}></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4  col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                      <div className="inputBox ">
                        <label style={{ fontSize: `${FontChange}px` }}>
                          {t("Vehicle Capacity")}
                        </label>
                        <input
                          required
                          type="text"
                          name="capacity"
                          placeholder={t("Vehicle Capacity")}
                          onChange={handleChange}
                          className="input"
                          value={createVehicle["capacity"]}
                          style={{ fontSize: `${FontChange}px` }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                      <div className="inputBox ">
                        <label style={{ fontSize: `${FontChange}px` }}>
                          {t("Start Date")}
                        </label>
                        <input
                          required
                          type="date"
                          name="documentDate"
                          placeholder={t("Document Date to")}
                          onChange={handleChange}
                          className="input"
                          value={createVehicle["documentDate"]}
                          style={{ fontSize: `${FontChange}px` }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                      <div className="inputBox ">
                        <label style={{ fontSize: `${FontChange}px` }}>
                          {t("Expire Date")}
                        </label>
                        <input
                          required
                          type="date"
                          name="expireDate"
                          placeholder={t("Document Date to")}
                          onChange={handleChange}
                          className="input"
                          value={createVehicle["expireDate"]}
                          style={{ fontSize: `${FontChange}px` }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                      <div className="inputBox ">
                        <label style={{ fontSize: `${FontChange}px` }}>
                          {t("Retailer Name")}
                        </label>
                        <input
                          type="text"
                          name="retailerName"
                          placeholder={t("Retailer Name")}
                          onChange={handleChange}
                          className="input"
                          value={createVehicle["retailerName"]}
                          style={{ fontSize: `${FontChange}px` }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                      <div className="inputBox ">
                        <label style={{ fontSize: `${FontChange}px` }}>
                          {t("Ownership")}
                        </label>
                        <select
                          className="input"
                          name="ownership"
                          onChange={handleChange}
                          style={{ fontSize: `${FontChange}px` }}
                        >
                          <option
                            value=""
                            style={{ fontSize: `${FontChange}px` }}
                          >
                            {t("Vehicle Type")}
                          </option>
                          {getOwnership &&
                            getOwnership.getOwnership !== undefined
                            ? getOwnership.getOwnership.map((data, index) => {
                              return (
                                <option
                                  value={data.key}
                                  style={{ fontSize: `${FontChange}px` }}
                                  key={index}
                                >
                                  {" "}
                                  {capitalize(data.value)}
                                </option>
                              );
                            })
                            : ""}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form_section">
                      <div className="inputBox ">
                        <span style={{ color: "red", float: "right", fontSize: `${FontChange}px` }}>{t("100 Words")}</span>
                        <label style={{ fontSize: `${FontChange}px` }}>
                          {t("Notes")}
                        </label>
                        <textarea
                          style={{
                            overflow: "hidden",
                            fontSize: `${FontChange}px`,
                            resize: "none"
                          }}
                          className="input"
                          name="notes"
                          onChange={handleChange}
                          rows="1"
                          onKeyDown={handleKeyDown}
                          onKeyPress={e => {
                            if (e.key === 'Enter')
                              e.preventDefault()
                          }}
                          maxLength="100"
                        ></textarea>
                        {createVehicle.notes && createVehicle.notes.length > 0 && <span style={{ color: "red" }}>{`${100 - parseInt(createVehicle.notes.length)} ${t("CharacterRemaining")}`}</span>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form_section">
                      <div className="vm_button_section">
                        {/*<button type="button" className="add-more-btn"> {t("Add More")}</button>*/}
                        <button
                          type="submit"
                          className="btn create_btn px-5"
                          disabled={isSaveDisabled}
                        >
                          {t("Save")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Grid item md xs display="flex" justifyContent="flex-end">
                <Autocomplete
                  options={vehicleData}
                  onChange={filterVehicleData}
                  getOptionLabel={(option) => option.licenseNumber}
                  id="search-export-field"
                  sx={{ width: "260px" }}
                  noOptionsText={t("lable.norecordfound")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("label.search_vehicle_data")}
                      variant="standard"
                      sx={{
                        '& .MuiFormLabel-root': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.vehicleId}>
                      {option.licenseNumber}
                    </li>
                  )}
                />
                <Button
                  variant="contained"
                  className="btncolor font-weight-bold"
                  size="small"
                  sx={{ m: 1, fontSize: 12 }}
                  onClick={() =>
                    searchTerm.length < 3
                      ? exportTableData(vehicleData)
                      : exportTableData(filteredData)
                  }
                >
                  {t("Export")}
                </Button>
              </Grid>

              <div className="row" style={{ display: "initial" }}>
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("Registered License No.")}
                          </th>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("Vehicle Capacity")}
                          </th>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("TYPE")}
                          </th>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("Ownership")}
                          </th>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("Retailer Name")}
                          </th>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("Start Date")}
                          </th>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("Expire Date")}
                          </th>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("Note")}
                          </th>
                          <th style={{ fontSize: `${FontChange}px` }}>
                            {t("Actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchTerm.length < 3
                          ? vehicleData?.map((data) => {
                            return renderTableRow(data);
                          })
                          : filteredData?.map((data) => {
                            return renderTableRow(data);
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        style={{ borderRadius: "15px" }}
        aria-labelledby="customized-dialog-title"
        open={erropen}
      >
        {editVehicles ? (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              &nbsp;
            </DialogTitle>
            <EditDialogContent>
              {getVehiclesDetailsById &&
                getVehiclesDetailsById.editVehiclesById !== undefined ? (
                <>
                  <EditVehicle
                    getVehiclesDetailsById={getVehiclesDetailsById}
                    handleUpdate={handleUpdate}
                    inputRefs={inputRefs}
                    handleChange={handleChange}
                    handleClose={handleClose}
                    getOwnership={getOwnership}
                    capitalize={capitalize}
                    getVehicletypes={getVehicletypes}
                    isSaveDisabled={isSaveDisabled}
                    createVehicle={createVehicle}
                    editinput={editinput}
                    DialogActions={DialogActions}
                    vehicleId={vehicleId}
                    setIsSaveDisabled={setIsSaveDisabled}
                    FontChange={FontChange}
                  />
                  {/* <form style={{ width: "100%" }} onSubmit={handleUpdate}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form_section">
                        <div className="inputBox ">
                          <label>{t("Registered License No.")}</label>
                          <input
                            disabled="disabled"
                            ref={(ref) => inputRefs.current.push(ref)}
                            required
                            type="text"
                            name="licenseNumber"
                            placeholder={t("Registered License No.")}
                            onChange={handleChange}
                            className="input"
                            defaultValue={
                              getVehiclesDetailsById.editVehiclesById
                                .licenseNumber
                            }
                          />
                          <span
                            id="lincenceErr"
                            style={{ color: "red" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form_section">
                        <div className="inputBox ">
                          <label>{t("Vehicle Capacity")}</label>
                          <input
                            ref={(ref) => inputRefs.current.push(ref)}
                            required
                            type="text"
                            name="capacity"
                            placeholder={t("Vehicle Capacity")}
                            onChange={handleChange}
                            className="input"
                            value={
                              editinput
                                ? createVehicle["capacity"]
                                : getVehiclesDetailsById.editVehiclesById
                                  .capacity
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form_section">
                        <div className="inputBox ">
                          <label>{t("Start Date")}</label>
                          <input
                            ref={(ref) => inputRefs.current.push(ref)}
                            required
                            type="date"
                            name="documentDate"
                            placeholder={t("Document Date to")}
                            onChange={handleChange}
                            className="input"
                            defaultValue={
                              getVehiclesDetailsById.editVehiclesById
                                .documentDate
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form_section">
                        <div className="inputBox ">
                          <label>{t("Expire Date")}</label>
                          <input
                            ref={(ref) => inputRefs.current.push(ref)}
                            required
                            type="date"
                            name="expireDate"
                            placeholder={t("Document Date to")}
                            onChange={handleChange}
                            className="input"
                            defaultValue={
                              getVehiclesDetailsById.editVehiclesById.expireDate
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form_section">
                        <div className="inputBox ">
                          <label>{t("Retailer Name")}</label>
                          <input
                            ref={(ref) => inputRefs.current.push(ref)}
                            type="text"
                            name="retailerName"
                            placeholder={t("Retailer Name")}
                            onChange={handleChange}
                            className="input"
                            defaultValue={
                              getVehiclesDetailsById.editVehiclesById
                                .retailerName
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form_section">
                        <div className="inputBox ">
                          <label>{t("Vehicle Type")}</label>
                          <select
                            ref={(ref) => inputRefs.current.push(ref)}
                            required
                            className="input"
                            name="vehicleType"
                            onChange={handleChange}
                          >
                            <option value="">Vehicle Type</option>
                            {getVehicletypes &&
                              getVehicletypes.getVehicletypes !== undefined
                              ? getVehicletypes.getVehicletypes.map((data) => {
                                if (
                                  getVehiclesDetailsById.editVehiclesById
                                    .vehicleType == data.key
                                ) {
                                  return (
                                    <option selected value={data.key}>
                                      {" "}
                                      {capitalize(data.value)}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={data.key}>
                                      {" "}
                                      {capitalize(data.value)}
                                    </option>
                                  );
                                }
                              })
                              : ""}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form_section">
                        <div className="inputBox ">
                          <label>{t("Ownership")}</label>
                          <select
                            ref={(ref) => inputRefs.current.push(ref)}
                            className="input"
                            name="ownership"
                            onChange={handleChange}
                          >
                            <option value="">Vehicle Type</option>
                            {getOwnership &&
                              getOwnership.getOwnership !== undefined
                              ? getOwnership.getOwnership.map((data) => {
                                if (
                                  getVehiclesDetailsById.editVehiclesById
                                    .ownership == data.key
                                ) {
                                  return (
                                    <option selected value={data.key}>
                                      {" "}
                                      {capitalize(data.value)}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={data.key}>
                                      {" "}
                                      {capitalize(data.value)}
                                    </option>
                                  );
                                }
                              })
                              : ""}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form_section">
                        <div className="inputBox ">
                          <label>{t("Notes")}</label>
                          <textarea
                            ref={(ref) => inputRefs.current.push(ref)}
                            style={{ overflow: "hidden" }}
                            className="input"
                            name="notes"
                            onChange={handleChange}
                            rows="1"
                          >
                            {getVehiclesDetailsById.editVehiclesById.notes}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DialogActions>
                    <div className="create_link d-flex">
                      <button
                        type="button"
                        className="create pl-5 pr-5"
                        onClick={handleClose}
                      >
                        {t("cancel.button")}
                      </button>
                      <button 
                      type="submit" 
                      className="create pl-5 pr-5"
                      disabled={isSaveDisabled}
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </DialogActions>
                </form> */}



                </>
              ) : (
                ""
              )}
            </EditDialogContent>
          </>
        ) : (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Delete Vechile
            </DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete vechile number
              </Typography>
              <DialogActions>
                <div className="create_link d-flex">
                  <button className="create pl-5 pr-5" onClick={handleClose}>
                    No
                  </button>
                  <button className="create pl-5 pr-5" onClick={confirmRemove}>
                    Yes
                  </button>
                </div>
              </DialogActions>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
}

export default withTranslation()(VehicleManagement);
