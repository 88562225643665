import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { withTranslation, useTranslation } from "react-i18next";
import ExportExcelPopup from "../../../../components/exportPopup/ExportExcelPopup";
import "./RedemptionHistory.scss";
import SearchForm from "./SearchForm";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Axios from "axios";
import DataGridProMUI from "../../../../components/DataGrid/DataGridProMUI";
import {
  convertToCurrencyFormat,
  convertToCurrencyFormatQuantaty,
  millisecondsToStringDate,
  DATE_FORMAT,
  handleZero,
} from "../../../../_helpers";
import { onExportExcel } from "../../../../_helpers/exportExcel";

const SubDealerActivityPoint = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [exportFiles, setExportFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [exportState, setExportState] = useState("Export");
  const lancode = localStorage.getItem("lancode");
  const [searchedRows, setSearchedRows] = useState(null)
  const [filter, setFilter] = useState({
    startDate: new Date().setFullYear(new Date().getFullYear() - 1),
    endDate: new Date(),
  });
  const [pageSize, setPageSize] = useState(25);
  const [state, setState] = React.useState({
    message: "Deleted Successfully!!!!",
    severity: "success",
    open: false,
  });
  
  function CustomToolbar(){
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <div className="ExportAndSearchContainer">
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onExportClick}
            style={{ border: "0px" }}
          >
            {t("Export")}
          </Button>
          <GridToolbarQuickFilter />
        </div>
      </GridToolbarContainer>
    );
  };

  const onExportClick = () => {
    const url = `export/redemption-history`;
    setExportState("Exporting...");
    let filters = {
      accountNumber: localStorage.getItem("CustomerNumber"),
      needHistoryFlag: true,
      startDate: moment(filter.startDate).format("DD-MM-YYYY"),
      endDate: moment(filter.endDate).format("DD-MM-YYYY"),
      search: null,
      languageType: lancode.toUpperCase(),
    };
    Axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL_LMS + url,
      data: filters,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        setExportState("Export");
        setOpen(true);
        setFileUrl(response.data.data);
        exportExcelFile(response.data.data);
      })
      .catch((err) => {
        setExportState("Export");
      });
  };

  function exportExcelFile(file_url) {
    setExportFiles([]);
    Axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_URL_LMS +
        `export/bucket?folderName=${file_url}`,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        const newRes = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        setExportFiles(newRes);
      })
      .catch((err) => {
        // setExportState({ btnName: "EXPORT" });
      });
  }

  const fetchRedeemHistory = function () {
    setShowLoading(true);
    const customerId = localStorage.getItem("CustomerNumber");
    Axios.get(
      // `${process.env.REACT_APP_API_URL_LMS}loyalty/redeem/history?accountNumber=1600002914&isDealer=true`,
      `${process.env.REACT_APP_API_URL_LMS
      }loyalty/redeemPoints/inquirePointBalance?accountNumber=${customerId}&needHistoryFlag=true
      &startDate=${moment(filter.startDate).format(
        "DD-MM-YYYY"
      )}&endDate=${moment(filter.endDate).format("DD-MM-YYYY")}`,

      {
        headers: {
          "Content-Type": "application/json",
          "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
          "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
        },
      }
    )
      .then((response) => {
        setShowLoading(false);
        setRows(
          (response?.data?.data?.redeemedPointsHistory || []).map((item) => ({
            ...item,
            redemptionDate: item.redemptionDate != null ? new Date(item.redemptionDate) : "",
            rd:item.redemptionDate != null ? moment(new Date(item.redemptionDate)).format("DD-MM-yyyy"): "",
            q:item.quantity != null ? convertToCurrencyFormatQuantaty(item.quantity) : "0.000",
            pr:item.redeemedPoints != null ? convertToCurrencyFormat(item.redeemedPoints) : "0.00"
          }))
        );
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    setRows([]);
    fetchRedeemHistory();
  }, [filter.startDate, filter.endDate]);

  const columns = [
    {
      //field: "redeemOrder",
      field: "redemptionNumber",
      headerName: t("label.redemption_order"),
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "redemptionDate",
      headerName: t("label.redemption_date"),
      headerAlign: "center",
      width: 150,
      align: "center",
      type: "date",
      valueFormatter: (row) => {
        return row.value && row.value !== null
          ? moment(new Date(row.value)).format("DD-MM-yyyy")
          : "";
      },
    },
    {
      field: "productName",
      headerName: t("label.product_name"),
      headerAlign: "center",
      align: "left",
      width: 200,
    },
    {
      field: "quantity",
      headerName: t("label.quantity_units"),
      headerAlign: "center",
      align: "right",
      width: 150,
      format: '#,##0.000',
      renderCell: ({ row }) => {
        return <div>{convertToCurrencyFormatQuantaty(row.quantity)}</div>;
      },
    },
    {
      field: "redeemedPoints",
      headerName: t("label.points_redeemed"),
      headerAlign: "center",
      align: "right",
      width: 150,
      format: '#,##0.00',
      renderCell: ({ row }) => {
        return <div>{convertToCurrencyFormat(row.redeemedPoints)}</div>;
      },
    },
    {
      field: "deliveryStatusUrl",
      headerName: t("label.delivery_status"),
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <a
            href={`${process.env.REACT_APP_INSEE_LIFE_AWARD_TRACKING_URL}${row.redemptionNumber
              }/${handleZero(row.accountNumber)}/${row.productCode}
          `}
            target="_blank"
          >
            {t("ClickLink")}
          </a>
        );
      },
    },
    { field: 'rd',headerName: 'rd', hide:true},
    { field: 'q',headerName: 'q', hide:true},
    { field: 'pr',headerName: 'pr', hide:true},
  ];

  const onFilterChange = (filterObj) => {
    setFilter({ ...filterObj });
  };

  function modifyColumns(columns) {
    columns = columns.filter((item)=>!["rd", "q", "pr"].includes(item.field))
    return columns.map((item) => {
      return ({
        label: item.headerName,
        value: item.field,
        ...item
      })
    })
  }

  function modifyRows(rowdata) {
    // let filteredRow = searchedRows.map((item) => item.model);
    let filteredRow = rowdata.map((item) => item.model);
    const data = filteredRow.map((item) => {
      return ({
        ...item,
        deliveryStatusUrl: `${process.env.REACT_APP_INSEE_LIFE_AWARD_TRACKING_URL}${item.redemptionNumber
          }/${handleZero(item.accountNumber)}/${item.productCode}`
      })
    })
    return data
  }

  function callExcelExport(data) {
    const fileName = `Redemption_History_Report_${moment(new Date()).format("DDMMyyyy")}`;
    const sheetName = `Redemption_History_Report`;
    onExportExcel(modifyColumns(columns), modifyRows(data), fileName, sheetName)
  }

  return (
    <>
      <div>
        <SearchForm
          onFilterChange={onFilterChange}
          setOpenPopup={true}
          recordForEdit={null}
        />
      </div>

      <ExportExcelPopup
        title={"Customer"}
        fileName={"customer"}
        setOpen={setOpen}
        open={open}
        exportExcelFile={exportExcelFile}
        fileUrl={fileUrl}
        exportFiles={exportFiles}
      />
      <div className="DataGridContainer">
        <DataGridProMUI
          rows={rows}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
          pageSize={pageSize}
          loading={!(rows || []).length && showLoading}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          onExportClick={onExportClick}
          exportState={exportState}
          setSearchedRows={setSearchedRows}
          callExcelExport={callExcelExport}
          defaultStateChange={false}
        />
      </div>
    </>
  );
};

export default withTranslation()(SubDealerActivityPoint);
