import React, { useEffect } from 'react';
import moment from 'moment';
///import 'moment-timezone';
import { eventActions } from '../../_actions'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation, useTranslation } from 'react-i18next'
import FormSelectbox from '../FormSelectbox/FormSelectbox'
import FormInput from '../FormInput/FormInput'
// import LinearProgress from '@material-ui/core/LinearProgress';
import MultiRangeSlider from '../../components/MultiRangeSlider/MultiRangeSlider'

import './ShipmentCondition.scss'

function ShipmentCondition(props) {
  const event = useSelector((state) => state)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  ////console.log('props.getShipmentStatus',props.getShipmentStatus);
  var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const statusDataCount = {}
  function formatDate(date) {
    var day = date.getDate() + ''
    var month = date.getMonth() + 1 + ''
    var year = date.getFullYear() + ''
    var hour = date.getHours() + ''
    var minutes = date.getMinutes() + ''
    var seconds = date.getSeconds() + ''
    return (
      (day <= 9 ? '0' + day : day) +
      '-' +
      (month <= 9 ? '0' + month : month) +
      '-' +
      year +
      ' ' +
      (hour <= 9 ? '0' + hour : hour) +
      ':' +
      (minutes <= 9 ? '0' + minutes : minutes)
    )
  }
  let indexFind =
    props.getShipmentStatus &&
    props.getShipmentStatus.findIndex(
      (x) => x.shipmentStatus === props.shipStatus
    )
  function convertUTCToTimezone(utcDt, utcDtFormat, timezone) {
    return moment.utc(utcDt, utcDtFormat).tz(timezone).format('DD-MM-YYYY HH:mm:ss');
  }

  console.log('indexFindindexFind', indexFind, '', props.shipStatus);
  const FontChange = useSelector((state) => state.fontsizechanger.fontsizechanger);
  const SmallFontChanger = useSelector((state) => state.smallfontchanger.smallfontchanger);


  function getShipmentStatusList() {
    const pickupStatusList = ["Shipment Created", "Truck Allocated",
      "Check In", "In Plant", "Dispatched", "Delivered"];
    const pickupStatusListReverse = ["Delivered", "Dispatched", "In Plant", "Check In", "Truck Allocated", "Shipment Created"];
    let updatedpickupStatusList = []

    for (let i = 0; i < pickupStatusList.length; i++) {
      let findPickupStatus = props.getShipmentStatus.find((f) => f.shipmentStatus === pickupStatusList[i])
      if (findPickupStatus) {
        updatedpickupStatusList.push(findPickupStatus)
      } else {
        // console.log("findPickupStatus : ",findPickupStatus)
        updatedpickupStatusList.push({
          key: pickupStatusList[i],
          shipmentLastUpdatedTime: "",
          shipmentStatus: pickupStatusList[i],
          value: pickupStatusList[i]
        })
      }
    }

    let reverseStatusList = updatedpickupStatusList.reverse();
    let isTime = true;
    for (let k = 0; k < reverseStatusList.length; k++) {

      if (reverseStatusList[k].shipmentLastUpdatedTime !== "") {
        isTime = false;
        reverseStatusList[k].isDateTime = true;
      }

      if (reverseStatusList[k].shipmentLastUpdatedTime === "") {
        if (isTime) {
          reverseStatusList[k].isDateTime = true;
        } else {
          reverseStatusList[k].isDateTime = false;
        }
      }
    }

    return reverseStatusList.reverse();
  }

  return (
    <>
      <div className='shipment-condition'>
        <div className='row myShipmentStatusContainer'>
          <div className='col-sm-12 col-md-12 col-lg-12 myShipmentStatusBarMobile'>
            <ul class='timeline' id="timelineCom">
              {(props.getShipmentStatus &&
                props.getShipmentStatus !== undefined) ||
                null
                ? getShipmentStatusList().map((data, index) => {
                  console.log("TimeIsvailable : ", data.shipmentLastUpdatedTime)
                  if (props.ShippingConditions == 'pickup') {
                    if (data.key !== 'Delivered') {
                      console.log("cvfgfgfgfgfgfgfgfgfgfgf", index, data.key, data.isDateTime)
                      return (
                        <li
                          //id={`rajeev${index}`}
                          class={
                            data.shipmentLastUpdatedTime ? 'complete' : 'progress-status'
                          }
                        >
                          <div class='timestamp'>
                            <span class='author' style={{ fontSize: `${FontChange}px` }}>{t(data.key)}</span>
                          </div>
                          {/* <div class={'status'}></div> */}
                          {/* <div class={data.shipmentLastUpdatedTime ? 'status' : 'status2'}></div> */}
                          <div class={data.isDateTime ? 'status' : 'status2'}></div>
                          {props.shipStatus != 'Cancel' &&
                            data.shipmentLastUpdatedTime ? (
                            <div class='status-first'>
                              <span style={{ fontSize: `${FontChange}px` }}>
                                {/* {convertUTCToTimezone(data.shipmentLastUpdatedTime, null, 'Asia/Bangkok')} */}
                                {/* {moment(data.shipmentLastUpdatedTime).format('DD-MM-YYYY HH:mm:ss')} */}
                                {data.shipmentLastUpdatedTime ? moment(data.shipmentLastUpdatedTime).format('DD-MM-YYYY HH:mm:ss') : ""}
                                {
                                  /*data.shipmentLastUpdatedTime != ''
                                    ? formatDate(
                                        new Date(data.shipmentLastUpdatedTime)
                                      )
                                    : '' new Date(
                                            data.shipmentLastUpdatedTime
                                          ).toLocaleTimeString(
                                            'en-US',
                                            dateOptions
                                          )*/
                                }
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </li>
                      )
                    }
                  } else {
                    return (
                      <li
                        class={
                          data.shipmentLastUpdatedTime && props.shipStatus != 'Cancel'
                            ? 'complete'
                            : 'progress-status'
                        }
                      >
                        <div class='timestamp'>
                          <span class='author' style={{ fontSize: `${FontChange}px` }}>{t(data.key)}</span>
                        </div>
                        {/* <div class='status'></div> */}
                        <div class={data.isDateTime ? 'status' : 'status2'}></div>
                        {props.shipStatus != 'Cancel' &&
                          data.shipmentLastUpdatedTime ? (
                          <div class='status-first'>
                            <span style={{ fontSize: `${FontChange}px` }}>
                              {moment(data.shipmentLastUpdatedTime).format('DD-MM-YYYY HH:mm:ss')}
                              {/* {
                                  data.shipmentLastUpdatedTime != ''
                                    ? 
                                      convertUTCToTimezone(data.shipmentLastUpdatedTime, null, 'Asia/Bangkok')
                                      moment(data.shipmentLastUpdatedTime).format('DD-MM-YYYY HH:mm:ss')
                                    : ''
                                    //  /*new Date(
                                    //         data.shipmentLastUpdatedTime
                                    //       ).toLocaleTimeString(
                                    //         'en-US',
                                    //         dateOptions
                                    //       )
                                } */}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                      </li>
                    )
                  }
                })
                : ''}
            </ul>
          </div>
        </div>
        <div className='card'>
          <div className='row'>
            <div className='col-12'>
              <h6 style={{ textTransform: 'uppercase', fontSize: `${SmallFontChanger}px` }}>
                {t('shipmanagement.donumber')} :
                {props.doNumber &&
                  props.doNumber.map((data) => {
                    if (data !== '' && data !== null) {
                      return <span className='doNumberDisplay' style={{ fontSize: `${SmallFontChanger}px` }}>{data}</span>
                    }
                  })}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(ShipmentCondition)
