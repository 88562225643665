import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import "react-tabs/style/react-tabs.css";
import Header from "../../../components/Header/Header";
import "./Payment.scss";
import { getAxiosCall } from "../../../_constant";
import Progress from "../../../components/BankRedirectMessage/Progress";
import Success from "../../../components/BankRedirectMessage/Success";
import Failed from "../../../components/BankRedirectMessage/Failed";

function KBankPaymentOnlineSuccessful(props) {
  const { t } = useTranslation();
  const paymentId = localStorage.getItem("payId");
  const [kBankRes, setKBankRes] = useState("Progress");
  const [bgRefNo, setBgRefNo] = useState();
  const [showMessage, setShowMessage] = useState(false);
  

  async function callingBeForUpdateTransection(paymentId) {
    const endpoint = `${process.env.REACT_APP_API_URL_CONFIRMPAYMENT}/payment/kbank/dd/payRef/inquiry?payId=${paymentId}`;

    const apiResponse = await getAxiosCall(endpoint);
    if (apiResponse.status === 200 && apiResponse.message === "Sucess") {
      const resData = apiResponse.data.split("-");

      const paymentRefNo = resData[0];
      const status = resData[1];

      status === "Progress" && setShowMessage(true);
      setKBankRes(status);
      setBgRefNo(paymentRefNo);
    }

    // console.log("hello", apiResponse);
  }

  useEffect(() => {
    paymentId && callingBeForUpdateTransection(paymentId);
  }, []);

  useEffect(() => {
    return () => {
      kBankRes !== "Progress" && localStorage.removeItem("payId");
    };
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <Header title={`${t("label.payment_ref_no")}`} />
        {kBankRes === "Progress" ? (
          <Progress message={showMessage} ShowButton={showMessage} />
        ) : kBankRes === "Receipt" ? (
          <Success bgRefNo={bgRefNo} />
        ) : (
          <Failed bgRefNo={bgRefNo} />
        )}
      </div>
    </>
  );
}

export default withTranslation()(KBankPaymentOnlineSuccessful);
