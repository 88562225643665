export const orderConstants = {
    

    GET_ALL_PRODUCT_REQUEST: 'ALL_PRODUCT_REQUEST',
    GET_ALL_PRODUCT_SUCCESS: 'ALL_PRODUCT_SUCCESS',
    GET_ALL_PRODUCT_FAILURE: 'ALL_PRODUCT_FAILURE',




    GET_PRODUCT_REQUEST: 'PRODUCT_REQUEST',
    GET_PRODUCT_SUCCESS: 'PRODUCT_SUCCESS',
    GET_PRODUCT_FAILURE: 'PRODUCT_FAILURE',

    ADD_TO_CART_REQUEST: 'ADDTOCART_REQUEST',
    ADD_TO_CART_SUCCESS: 'ADDTOCART_SUCCESS',
    ADD_TO_CART_FAILURE: 'ADDTOCART_FAILURE',

    CART_DATA_REQUEST: 'CARTDATA_REQUEST',
    CART_DATA_SUCCESS: 'CARTDATA_SUCCESS',
    CART_DATA_FAILURE: 'CARTDATA_FAILURE',

    GET_SHIPPING_VN_REQUEST: 'GET_SHIPPING_VN_REQUEST',
    GET_SHIPPING_VN_SUCCESS: 'GET_SHIPPING_VN_SUCCESS',
    GET_SHIPPING_VN_FAILURE: 'GET_SHIPPING_VN_FAILURE',

    GET_SHIPPING_TYPE_VN_REQUEST: 'GET_SHIPPING_TYPE_VN_REQUEST',
    GET_SHIPPING_TYPE_VN_SUCCESS: 'GET_SHIPPING_TYPE_VN_SUCCESS',
    GET_SHIPPING_TYPE_VN_FAILURE: 'GET_SHIPPING_TYPE_VN_FAILURE',

    

    ORDER_REQUEST: 'ORDER_REQUEST',
    ORDER_SUCCESS: 'ORDER_SUCCESS',
    ORDER_FAILURE: 'ORDER_FAILURE',

    GET_PDP_INFO_REQUEST: 'GET_PDP_INFO_REQUEST',
    GET_PDP_INFO_SUCCESS: 'GET_PDP_INFO_SUCCESS',
    GET_PDP_INFO_FAILURE: 'GET_PDP_INFO_FAILURE',

    RAISE_RELEASE_VN_REQUEST: 'RAISE_RELEASE_VN_REQUEST',
    RAISE_RELEASE_VN_SUCCESS: 'RAISE_RELEASE_VN_SUCCESS',
    RAISE_RELEASE_VN_FAILURE: 'RAISE_RELEASE_VN_FAILURE',

    ORDER_UPDATE_VN_REQUEST: 'ORDER_UPDATE_VN_REQUEST',
    ORDER_UPDATE_VN_SUCCESS: 'ORDER_UPDATE_VN_SUCCESS',
    ORDER_UPDATE_VN_FAILURE: 'ORDER_UPDATE_VN_FAILURE',

    CHANGE_PAYMENT_METHOD_REQUEST: 'CHANGE_PAYMENT_METHOD_REQUEST',
    CHANGE_PAYMENT_METHOD_SUCCESS: 'CHANGE_PAYMENT_METHOD_SUCCESS',
    CHANGE_PAYMENT_METHOD_FAILURE: 'CHANGE_PAYMENT_METHOD_FAILURE',


    
    
    
    GET_PDP_CONFIRMED_REQUEST: 'GET_PDP_CONFIRMED_REQUEST',
    GET_PDP_CONFIRMED_SUCCESS: 'GET_PDP_CONFIRMED_SUCCESS',
    GET_PDP_CONFIRMED_FAILURE: 'GET_PDP_CONFIRMED_FAILURE',

    

    ORDER_DETAIL_REQUEST: 'ORDER_DETAIL_REQUEST',
    ORDER_DETAIL_SUCCESS: 'ORDER_DETAIL_SUCCESS',
    ORDER_DETAIL_FAILURE: 'ORDER_DETAIL_FAILURE',

    GET_CONWOOD_CATEGORY_REQUEST: 'GET_CONWOOD_CATEGORY_REQUEST',
    GET_CONWOOD_CATEGORY_SUCCESS: 'GET_CONWOOD_CATEGORY_SUCCESS',
    GET_CONWOOD_CATEGORY_FAILURE: 'GET_CONWOOD_CATEGORY_FAILURE',

    GET_SELECTED_ORDER_CHECKOUT_REQUEST: 'GET_SELECTED_ORDER_CHECKOUT_REQUEST',
    GET_SELECTED_ORDER_CHECKOUT_SUCCESS: 'GET_SELECTED_ORDER_CHECKOUT_SUCCESS',
    GET_SELECTED_ORDER_CHECKOUT_FAILURE: 'GET_SELECTED_ORDER_CHECKOUT_FAILURE',

    CHECK_SHIPTO_SELECTED_REQUEST: 'CHECK_SHIPTO_SELECTED_REQUEST',
    CHECK_SHIPTO_SELECTED_SUCCESS: 'CHECK_SHIPTO_SELECTED_SUCCESS',
    CHECK_SHIPTO_SELECTED_FAILURE: 'CHECK_SHIPTO_SELECTED_FAILURE',

    CHECK_PLANT_SELECTED_REQUEST: 'CHECK_PLANT_SELECTED_REQUEST',
    CHECK_PLANT_SELECTED_SUCCESS: 'CHECK_PLANT_SELECTED_SUCCESS',
    CHECK_PLANT_SELECTED_FAILURE: 'CHECK_PLANT_SELECTED_FAILURE',

    CHECK_SHIPPING_COND_SELECTED_REQUEST: 'CHECK_SHIPPING_COND_SELECTED_REQUEST',
    CHECK_SHIPPING_COND_SELECTED_SUCCESS: 'CHECK_SHIPPING_COND_SELECTED_SUCCESS',
    CHECK_SHIPPING_COND_SELECTED_FAILURE: 'CHECK_SHIPPING_COND_SELECTED_FAILURE',

    CHECK_SHIPPING_TYPE_SELECTED_REQUEST: 'CHECK_SHIPPING_TYPE_SELECTED_REQUEST',
    CHECK_SHIPPING_TYPE_SELECTED_SUCCESS: 'CHECK_SHIPPING_TYPE_SELECTED_SUCCESS',
    CHECK_SHIPPING_TYPE_SELECTED_FAILURE: 'CHECK_SHIPPING_TYPE_SELECTED_FAILURE',

    PLACE_ORDER_SEARCH_CLICKED_REQUEST: 'PLACE_ORDER_SEARCH_CLICKED_REQUEST',
    PLACE_ORDER_SEARCH_CLICKED_SUCCESS: 'PLACE_ORDER_SEARCH_CLICKED_SUCCESS',
    PLACE_ORDER_SEARCH_CLICKED_FAILURE: 'PLACE_ORDER_SEARCH_CLICKED_FAILURE',

    GET_ORDER_CREDITINFO_REQUEST: 'GET_ORDER_CREDITINFO_REQUEST',
    GET_ORDER_CREDITINFO_SUCCESS: 'GET_ORDER_CREDITINFO_SUCCESS',
    GET_ORDER_CREDITINFO_FAILURE: 'GET_ORDER_CREDITINFO_FAILURE',

    GET_ALL_ORDER_LIST_REQUEST: 'GET_ALL_ORDER_LIST_REQUEST',
    GET_ALL_ORDER_LIST_SUCCESS: 'GET_ALL_ORDER_LIST_SUCCESS',
    GET_ALL_ORDER_LIST_FAILURE: 'GET_ALL_ORDER_LIST_FAILURE',

    CLEAR_CART_REQUEST: 'CLEAR_CART_REQUEST',
    CLEAR_CART_SUCCESS: 'CLEAR_CART_SUCCESS',
    CLEAR_CART_FAILURE: 'CLEAR_CART_FAILURE',

    PLACE_ORDER_REQUEST: 'PLACE_ORDER_REQUEST',
    PLACE_ORDER_SUCCESS: 'PLACE_ORDER_SUCCESS',
    PLACE_ORDER_FAILURE: 'PLACE_ORDER_FAILURE',

    GET_SHIPTO_DETAILS_REQUEST: 'GET_SHIPTO_DETAILS_REQUEST',
    GET_SHIPTO_DETAILS_SUCCESS: 'GET_SHIPTO_DETAILS_SUCCESS',
    GET_SHIPTO_DETAILS_FAILURE: 'GET_SHIPTO_DETAILS_FAILURE',

    RAISE_RELEASE_REQUEST_REQUEST: 'RAISE_RELEASE_REQUEST_REQUEST',
    RAISE_RELEASE_REQUEST_SUCCESS: 'RAISE_RELEASE_REQUEST_SUCCESS',
    RAISE_RELEASE_REQUEST_FAILURE: 'RAISE_RELEASE_REQUEST_FAILURE',

    CANCEL_SO_REQUEST: 'CANCEL_SO_REQUEST',
    CANCEL_SO_SUCCESS: 'CANCEL_SO_SUCCESS',
    CANCEL_SO_FAILURE: 'CANCEL_SO_FAILURE',

    DELETE_PRODUCT_FROM_CART_REQUEST: 'DELETE_PRODUCT_FROM_CART_REQUEST',
    DELETE_PRODUCT_FROM_CART_SUCCESS: 'DELETE_PRODUCT_FROM_CART_SUCCESS',
    DELETE_PRODUCT_FROM_CART_FAILURE: 'DELETE_PRODUCT_FROM_CART_FAILURE',
    
};
