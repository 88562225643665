  
  export const olimpicMedals = [{
    country: 'Jan',
    gold: 36,
    silver: 38,
    bronze: 36,
    color:"gray"
  }, {
    country: 'Feb',
    gold: 51,
    silver: 21,
    bronze: 28,
  }, {
    country: 'March',
    gold: 23,
    silver: 21,
    bronze: 28,
  }, {
    country: 'April',
    gold: 19,
    silver: 13,
    bronze: 15,
  }, {
    country: 'May',
    gold: 14,
    silver: 15,
    bronze: 17,
  }, {
    country: 'June',
    gold: 16,
    silver: 10,
    bronze: 15,
  }];
  
 
  