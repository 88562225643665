import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Select from "../../../../components/Select/Select";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import ExportPopup from "../../../../components/exportPopup/ExportPopup";
import ExportExcelPopup from "../../../../components/exportPopup/ExportExcelPopup";
import TextField from '@mui/material/TextField';

const ContactReportFilters = ({ setFilterState, filterState, filterReports, clearAll, setDateError, INITIAL_FILTER_STATE, setIsLoading }) => {
    const [openExportPopup, setOpenExportPopup] = useState(null);
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [fileUrl, setFileUrl] = useState("");
    const [exportFiles, setExportFiles] = useState([])
    const [open, setOpen] = useState(false);
    var langCode = localStorage.getItem('lancode')
    const [companies, setCompanies] = useState([])
    const [productstype, setProductsType] = useState([])

    function handleFilterChange(e) {
        // setDateError("")
        setFilterState({ ...filterState, [e.target.name]: e.target.value })
    }

    function getProductType(key) {
        setIsLoading(true)
        Axios({
            method: "GET", url: process.env.REACT_APP_API_URL_RS + `/report/contractReportProductType?salesOrg=${key}`,
            headers: {
                "Content-Type": "application/json",
                'X-AUTH-TOKEN': localStorage.getItem('x-auth-token'),
                'X-SOLD-TO-ID': localStorage.getItem('CustomerNumber')
            }
        })
            .then(async (response) => {
                setProductsType(response.data)
                console.log("Product Type : ", response.data)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                // setExportState({ btnName: "EXPORT" });
            })
    }

    function handleFilterChangeCompany(e) {
        clearAll()
        getProductType(e.target.value)
        // setDateError("")
        setFilterState({ ...INITIAL_FILTER_STATE, [e.target.name]: e.target.value })
        dispatch({ type: "CONTRACT_LIST", payload: [] })
    }

    function exportExcelFile(file_url) {
        setExportFiles([])
        Axios({
            method: "GET", url: process.env.REACT_APP_API_URL_CONFIRMPAYMENT + `/reports/bucket?folderName=${file_url}`,
            headers: {
                "Content-Type": "application/json",
                'X-AUTH-TOKEN': localStorage.getItem('x-auth-token'),
                'X-SOLD-TO-ID': localStorage.getItem('CustomerNumber')
            }
        })
            .then(async (response) => {
                const newRes = Array.isArray(response.data.data) ? response.data.data : []
                setExportFiles(newRes)
            }).catch((err) => {
                // setExportState({ btnName: "EXPORT" });
            })
    }

    function exportApiCAll() {

        const { company, productType, contractNo, contractName } = filterState;

        let language = langCode === "th" ? "TH" : "EN";

        const contractURL = process.env.REACT_APP_API_URL_RS + `/report/export/contractReport?company=${company}&division=${productType}&contractNumber=${contractNo}&contractName=${contractName}&language=${language}`;

        Axios({
            method: "POST",
            url: contractURL,
            // data: postObj,
            headers: {
                "Content-Type": "application/json",
                "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
                "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
            },
        })
            .then(async (response) => {
                setOpen(true);
                console.log("DATADATADATA : ", response.data)
                setFileUrl(response.data.data);
                exportExcelFile(response.data.data);
            })
            .catch((err) => { });
    }

    useEffect(() => {
        Axios({
            method: "GET", url: process.env.REACT_APP_API_URL_RS + `/report/contractReportSalesOrg`,
            headers: {
                "Content-Type": "application/json",
                'X-AUTH-TOKEN': localStorage.getItem('x-auth-token'),
                'X-SOLD-TO-ID': localStorage.getItem('CustomerNumber')
            }
        })
            .then(async (response) => {
                setCompanies(response.data)
                getProductType("1000")
                console.log("Product Type : ", response.data)
            }).catch((err) => {
                // setExportState({ btnName: "EXPORT" });
            })
    }, [])


    return (
        <>
            <Grid container spacing={2} mt={2} columns={11}>

                <ExportPopup
                    title={"Contract Report"}
                    fileName={"contract_report"}
                    openPopup={openExportPopup}
                    setOpenExportPopup={setOpenExportPopup}
                // linkToDownload={exportState.linkToDownload}
                />

                <ExportExcelPopup
                    title={"Customer"}
                    fileName={"customer"}
                    setOpen={setOpen}
                    open={open}
                    exportExcelFile={exportExcelFile}
                    fileUrl={fileUrl}
                    exportFiles={exportFiles}
                />
                <Grid item lg={2} md={5} sm={5} xs={12} className="SelectRightPadding">
                    <Select
                        name="company"
                        label={t("report.company")}
                        value={filterState.company}
                        onChange={handleFilterChangeCompany}
                        options={companies}
                        minWidth="100%"
                        showNoneOption={false}
                        isDefaultOptionVisible={false}
                        optionCode="value"
                        optionLabel="key"
                    />
                </Grid>

                <Grid item lg={3} md={6} sm={6} xs={12} className="SelectRightPadding selctStyle">

                    <Select
                        name="productType"
                        label={t("ProductType")}
                        value={filterState.productType}
                        onChange={handleFilterChange}
                        options={productstype}
                        minWidth="100%"
                        showNoneOption={false}
                        isDefaultOptionVisible={false}
                        optionCode="value"
                        optionLabel={langCode === "en" ? "key" : "keyTH"}
                    />
                </Grid>

                <Grid item md={2} sm={5} xs={12} className="reportInputText SelectRightPadding">

                    <TextField
                        id="standard-basic"
                        label={t("report.contractNo")}
                        variant="standard"
                        style={{ width: "100%" }}
                        name="contractNo"
                        value={filterState.contractNo}
                        onChange={handleFilterChange}
                    />
                </Grid>

                <Grid item md={2} sm={5} xs={12} className="reportInputText SelectRightPadding">

                    <TextField
                        id="standard-basic"
                        label={t("report.contractName")}
                        variant="standard"
                        style={{ width: "100%" }}
                        name="contractName"
                        value={filterState.contractName}
                        onChange={handleFilterChange}
                    />

                </Grid>

                <Grid item md xs display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        className="btncolor"
                        size="small"
                        sx={{ m: 1 }}
                        onClick={filterReports}
                    >
                        {t("Search")}
                    </Button>
                    <Button
                        variant="contained"
                        className="btncolor"
                        size="small"
                        sx={{ m: 1 }}
                        onClick={clearAll}
                    >
                        {t("report.clearAll")}
                    </Button>
                    <Button
                        variant="contained"
                        className="btncolor"
                        size="small"
                        sx={{ m: 1 }}
                        onClick={exportApiCAll}
                    >
                        {t("Export")}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactReportFilters