import React from "react";
import Header from "../../components/Header/Header";
import "react-tabs/style/react-tabs.css";
// import "./Payment.scss";
import UnSuccessImg from "../../assets/img/unsuccess.png";
import { withTranslation, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Failed = ({ bgRefNo }) => {
  const { t } = useTranslation();
  const lancode = localStorage.getItem("lancode");

  return (
    // <div className="content-wrapper">
      <div className="row ipad_css">
        <div className="mainScroll">
          <div className="col-12">
            <div className="payment-history card1">
              <div
                className="boxSuccess"
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  // marginLeft: "1rem",
                }}
              >
                <div className="imgSuccess">
                  <img
                    style={{ width: "85px", height: "85px" }}
                    src={UnSuccessImg}
                    alt="success"
                  />
                </div>

                {lancode === "en" ? (
                  <div className="boxText" style={{ marginTop: "20px" }}>
                    <h2
                      style={{ color: "red" }}
                    >{`Your payment status No. ${bgRefNo} is INCOMPLETED`}</h2>
                  </div>
                ) : (
                  <div className="boxText" style={{ marginTop: "20px" }}>
                    <h2
                      style={{ color: "red" }}
                    >{`รายการชำระเงินตามเลขที่เอกสารอ้างอิง  ${bgRefNo} ไม่เสร็จสมบูรณ์`}</h2>
                  </div>
                )}
              </div>
              <div className="btnPayment">
                <Link className="create_btn mb-3" to="/PaymentHistory">
                  {t("payment.pay_history_btn")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    // </div>
  );
};

export default withTranslation()(Failed);
