import React, { useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import "react-tabs/style/react-tabs.css";
import Header from "../../../components/Header/Header";
import "./Payment.scss";
import { getAxiosCall } from "../../../_constant";
import Success from "../../../components/BankRedirectMessage/Success";
import Failed from "../../../components/BankRedirectMessage/Failed";

function PaymentOnlineSuccessful(props) {
  const { t } = useTranslation();
  // const lancode = localStorage.getItem("lancode");

  const bgURL = window.location.search;
  const dataBgRef = bgURL.split("&");

  const bgRefNoData = dataBgRef ? dataBgRef[0].split("=") : "";
  const bgRefNo = bgRefNoData ? bgRefNoData[1] : "";

  const bgRefStatus = dataBgRef ? dataBgRef[2].split("=") : "";
  const bgStatus = bgRefStatus ? bgRefStatus[1] : "";

  const bgResponse = dataBgRef ? dataBgRef[1].split("=") : "";
  const bgResCode = bgResponse ? bgResponse[1] : "";

  async function callingBeForUpdateTransection(refNo, resCode, resStatus) {
    const endpoint = `${process.env.REACT_APP_API_URL_CONFIRMPAYMENT}/payment/bay/fg?REF1=${refNo}&RESPCODE=${resCode}&STATUS=${resStatus}`;

    const apiResponse = await getAxiosCall(endpoint);
    console.log("hello", apiResponse);
  }

  useEffect(() => {
    callingBeForUpdateTransection(bgRefNo, bgResCode, bgStatus);
  }, [bgRefNo, bgResCode, bgStatus]);

  return (
    <>
      <div className="content-wrapper">
        <Header title={`${t("label.payment_ref_no")}`} />
        {bgStatus === "COMPLETE" ? (
          <Success bgRefNo={bgRefNo} />
        ) : (
          <Failed bgRefNo={bgRefNo} />
        )}
      </div>
    </>
  );
}

export default withTranslation()(PaymentOnlineSuccessful);
