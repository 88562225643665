import React from 'react';
import xlsx from "json-as-xlsx"
import { useTranslation } from 'react-i18next';
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@material-ui/core";

const ExportJsonDataToExcel = ({fileName, columnName, tableData, classes, textSize, iconSize}) => {
  const { t } = useTranslation();
  let data = [
    {
      sheet: 'sheet1',
      columns: columnName,
      content: tableData,
      // columns: [
      //   { label: "Payment Status", value: "paymentstatus" }, // Top level data
      //   { label: "Sold To Number", value: "soldToNumber" }, // Custom format
      //   { label: "Payment Amount", value: "paymentamount" }, // Run functions
      //   { label: "Invoice", value: "more.phone" },
      // ],

    },
  ];

  let settings = {
    fileName: fileName, // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  };

  const onExportClick = () => {
    xlsx(data, settings)
  }
  
  return (
    <Button
      className={classes}
      style={ textSize && { fontSize: textSize }}
      startIcon={<DownloadIcon style={iconSize && {  fontSize: iconSize }} />}
      onClick={onExportClick}
    >
      {t("payment_export")}
    </Button>
  );
}

export default ExportJsonDataToExcel;
