import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
// import Axios, { AxiosResponse } from 'axios';
import RefreshIcon from "../../assets/img/refreshIcon.png";
// import { API_URL_CONFIRMPAYMENT } from '../../constant'
// import { paymentofflineActions, masterActions } from "../../_actions";
import Pagination from "@material-ui/lab/Pagination";
import Loading from "../../components/Loader/Loading";
import moment from "moment";
import "moment-timezone";
import "./table.scss";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Approve = {
  Approve_Modal: "Approve_Modal",
  Decline_Modal: "Decline_Modal",
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: "25px",
    textAlign: "center",
    // width: "517px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    // margin: 0,
    padding: theme.spacing(1),
    textAlign: "center",
    marginTop: "20px",
    display: "block",
  },
}))(MuiDialogActions);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles({
  refreshButton: {
    background: "#ffffff",
    border: "0px",
  },
  loadingOne: {
    textAlign: "center",
    margin: "5px 0",
  },
  table: {
    minWidth: 700,
  },
  textRight: {
    textAlign: "right !important",
  },
  tableBody: {
    "& th": {
      padding: "10px !important",
    },
    "& td": {
      padding: "10px !important",
    },
  },
});

export default function PaymentApprovalTable(props) {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cancleConfirm, setCancleConfirm] = useState(props.isCancelSuccess);
  // const custNo = localStorage.getItem('CustomerNumber');
  const rows = props.paymentHistory && props.paymentHistory;
  let userName = localStorage.getItem("userData");
  const [loading, setSetloading] = React.useState(false);
  let page = props.page;
  const selectedLangCode = localStorage.getItem("lancode");
  userName = JSON.parse(userName);

  const decimalwithcoma = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };

  // const FontChange = useSelector((state) => state.fontsizechanger.fontsizechanger);
  // const HeadingFontChange = useSelector((state) => state.headerfontchanger.headerfontchanger)
  const SmallFontChanger = useSelector(
    (state) => state.smallfontchanger.smallfontchanger
  );

  const handleCancleClose = () => {
    setCancleConfirm(false);
    props.handleClose();
    // window.location.reload();
  };

  const handleApproveCancle = () => {
    // setCancleConfirm(true);
    props.handleReject();
  };

  useEffect(() => {
    setCancleConfirm(props.isCancelSuccess);
  }, [props.isCancelSuccess]);

  // console.log('hello', props);

  const formatPaymentBankName = (bankName) => {
    if (bankName === "SCB") {
      return `${t("bank.scbbank")}`;
    } else if (bankName === "BAY" || bankName === "BayBank") {
      return `${t("bank.baybank")}`;
    } else if (bankName === "KBANK" || bankName === "KBankCF" || bankName === "KBankDD") {
      return `${t("bank.kbank")}`;
    }
  };

  return (
    <div className="table-resp">
      <div className="col-md-12 text-right mb-2">
        <button
          className={classes.refreshButton}
          onClick={props.refresPendingData}
        >
          <img src={RefreshIcon} alt="reload" />
        </button>
      </div>
      {loading ? (
        <div className={classes.loadingOne}>
          {" "}
          <Loading />{" "}
        </div>
      ) : (
        ""
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: `${SmallFontChanger}px` }}>
              <span style={{cursor:'pointer'}} onClick={props.sortPaymentRef}>
                {t("label.payment_ref_no")}
                <i class="fa fa-fw fa-sort"></i>
                </span>
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: `${SmallFontChanger}px` }}
                align="right"
              >
                {t("Document Type")}
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: `${SmallFontChanger}px` }}
                align="center"
              >
                {t("label.bankname")}
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: `${SmallFontChanger}px` }}
                align="center"
              >
                {t("Transaction date")}
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: `${SmallFontChanger}px` }}
                align="center"
                className={classes.textRight}
              >
                {t("label.total_amount")}
                {/* {console.log("rows.results.length : ", rows.results.length)} */}
                {rows.results.length > 0 &&
                  (selectedLangCode === "en" || selectedLangCode === null
                    ? `(${
                        rows && rows.results.length > 0
                          ? rows && rows.results[0].invoiceid[0].docCurrency
                          : ""
                      })`
                    : rows && rows.results.length > 0
                    ? rows && rows.results[0].invoiceid[0].docCurrency === "THB"
                      ? "(บาท)"
                      : ""
                    : rows && rows.results.length > 0
                    ? rows && rows.results[0].invoiceid[0].docCurrency === "USD"
                      ? "(ดอลล่าร์)"
                      : ""
                    : `(${
                        rows && rows.length > 0
                          ? rows && rows[0].invoiceid[0].docCurrency
                          : ""
                      }) `)}
                {rows.results.length === 0 &&
                  (selectedLangCode === "en" || !selectedLangCode
                    ? "(THB)"
                    : "(บาท)")}
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: `${SmallFontChanger}px` }}
                align="center"
              >
                {t("PreparedBy")}
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: `${SmallFontChanger}px` }}
                align="center"
              >
                {t("Status")}
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: `${SmallFontChanger}px` }}
                align="center"
              >
                {t("payment.action")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows && rows.totalCount > 0 ? rows && rows.map((row) => ( */}
            {rows && rows.totalCount > 0 ? (
              rows &&
              rows.results.map((row) => (
                // row.paymentstatus === "In-Progress" && (
                <StyledTableRow key={row.name} className={classes.tableBody}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ fontSize: `${SmallFontChanger}px` }}
                  >
                    <Link
                      to={{
                        pathname: "/PaymentApprovalReference",
                        state: { id: row.id },
                      }}
                    >
                      {row.paymentRefNo}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: `${SmallFontChanger}px` }}
                    align="right"
                  >
                    {/* {row.paymenttype && row.paymenttype === "DirectDebit" ? "Online Direct Debit" : row.paymenttype} */}
                    {row.paymenttype &&
                    (row.paymenttype === "DirectDebit" ||
                      row.paymenttype === "DD")
                      ? "Online Direct Debit"
                      : row.paymenttype === "CF"
                      ? "Customer Financing"
                      : row.paymenttype}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: `${SmallFontChanger}px` }}
                    align="right"
                  >
                    {formatPaymentBankName(row.paymentbank)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: `${SmallFontChanger}px` }}
                    align="right"
                  >
                    {moment(row.transationdate).format("DD-MM-YYYY")}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: `${SmallFontChanger}px` }}
                    align="right"
                    className={classes.textRight}
                  >
                    {decimalwithcoma(row.paymentamount)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: `${SmallFontChanger}px` }}
                    align="right"
                  >
                    {row.createdBy && row.createdBy}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: `${SmallFontChanger}px` }}
                    align="right"
                  >
                    <span className="sattled-color">
                      {row.paymentstatus && row.paymentstatus === "In-Progress"
                        ? "Pending for approval"
                        : row.paymentstatus}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div
                      className="receipt_action"
                      style={{ fontSize: `${SmallFontChanger}px` }}
                    >
                      <Box className="actions">
                        <Box
                          style={{ cursor: "pointer", margin: 5 }}
                          onClick={() => props.handleOpen(row, true)}
                        >
                          <Button
                            size="small"
                            style={{
                              backgroundColor: "#0089ff",
                              color: "white",
                              fontSize: 12,
                            }}
                          >
                            {t("pickupform.yes")}
                          </Button>
                        </Box>

                        <Box style={{ cursor: "pointer" }}>
                          <Button
                            size="small"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              fontSize: 12,
                            }}
                            onClick={() => props.handleOpen(row, false)}
                          >
                            {t("pickupform.no")}
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
                // )
              ))
            ) : (
              <StyledTableRow className={classes.tableBody}>
                <div className="noBankFound">{t("Data not available")}</div>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
        ></DialogTitle>
        <DialogContent>
          <Typography>
            {props.modalType === Approve.Approve_Modal ? (
              <p className="mb-0" style={{ fontSize: "15px" }}>
                {t(`payment.sure_approval`)} {props?.approvalItem?.paymentRefNo}
                {selectedLangCode !== "en" ? ` หรือไม่?` : `?`}
              </p>
            ) : (
              <p className="mb-0">
                {t(`payment.cancel_approval`)}{" "}
                {props?.approvalItem?.paymentRefNo}
                {selectedLangCode !== "en" ? ` หรือไม่?` : `?`}{" "}
              </p>
            )}
            {/* <p className="mb-0 text-secondary">Are you sure</p> */}
          </Typography>
          <DialogActions>
            <div className="create_link d-flex pull-center">
              <Button
                size="small"
                style={{
                  backgroundColor: `${props.isDisabled ? "#0000001f" : "#0089ff"}`,
                  color: `${props.isDisabled ? "#00000042" : "white"}`,
                  fontSize: 12,
                }}
                onClick={() =>
                  props.modalType === Approve.Decline_Modal
                    ? handleApproveCancle()
                    : props.handleApprove()
                }
                disabled={props.isDisabled ? true : false}
              >
                {t("pickupform.yes")}
              </Button>
              <Button
                size="small"
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid black",
                  color: "black",
                  fontSize: 12,
                }}
                onClick={props.handleClose}
              >
                {t("pickupform.no")}
              </Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleCancleClose}
        aria-labelledby="customized-dialog-title"
        open={cancleConfirm}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCancleClose}
        ></DialogTitle>
        <DialogContent>
          <Typography>
            {selectedLangCode === "en" ? (
              <p className="mb-0 text-danger">
                {t(
                  `Payment Ref No. ${props?.approvalItem?.paymentRefNo} has been Cancelled.`
                )}
              </p>
            ) : (
              <p className="mb-0 text-danger">
                {t(
                  `เลขที่เอกสารอ้างอิง ${props?.approvalItem?.paymentRefNo} ได้ถูกยกเลิก`
                )}
              </p>
            )}
            {/* <p className="mb-0 text-secondary">Are you sure</p> */}
          </Typography>
          <DialogActions>
            <div className="create_link d-flex pull-center">
              <button
                className="create p-2 pl-3 pr-3 rounded"
                onClick={handleCancleClose}
              >
                {t("pickupform.close")}
              </button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <div className="col-md-12 text-right mt-4 mb-4">
        {
          <Pagination
            count={Math.ceil(rows && rows.totalCount / 10)}
            page={page}
            onChange={props.handleChangePage}
          />
        }
      </div>
    </div>
  );
}
