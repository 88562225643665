import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import "react-tabs/style/react-tabs.css";
import Header from "../../../components/Header/Header";
import "./Payment.scss";
import { getAxiosCall,  } from "../../../_constant";
import Success from "../../../components/BankRedirectMessage/Success";
import Failed from "../../../components/BankRedirectMessage/Failed";
import Progress from "../../../components/BankRedirectMessage/Progress";

function PaymentOnlineSuccessfulSCB(props) {
  const { t } = useTranslation();
  const bgRefNo = localStorage.getItem("referenceNo");
  const [bgStatus, setBgStatus] = useState("Progress");
  const [showMessage, setShowMessage] = useState(false);

  async function callingBeForUpdateTransection() {
    const endpoint = `${process.env.REACT_APP_API_URL_CONFIRMPAYMENT}/payment/scb/check/inquiry?paymentRefNo=${bgRefNo}`;

    const apiResponse = await getAxiosCall(endpoint);
    // console.log('hello', apiResponse);
    if (apiResponse.status === 200 && apiResponse.message === "Success") {
      const status = apiResponse.data.statusCode === "1000" ? "Success" : "Failed";
      setBgStatus(status);
      console.log('hello', apiResponse.data);
    } else{
      setShowMessage(false);
    }
  }

 

  useEffect(() => {
    callingBeForUpdateTransection();
  }, []);

// useEffect(() => {
  // return () => {
    // localStorage.removeItem("referenceNo");
  // };
// }, []);

  return (
    <>
      <div className="content-wrapper">
        <Header title={`${t("label.payment_ref_no")}`} />
        { bgStatus === "Progress" ? (
          <Progress message={showMessage} ShowButton={showMessage} />
        ) : bgStatus === "Success" ? (
          <Success bgRefNo={bgRefNo} />
        ) : (
          <Failed bgRefNo={bgRefNo} />
        )}
      </div>
    </>
  );
}

export default withTranslation()(PaymentOnlineSuccessfulSCB);
