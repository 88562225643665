import Input from "../../../components/Input/Input";
import Select from "../../../components/Select/Select";
import Checkbox from "../../../components/CheckBox/Checkbox";
import DatePicker from "../../../components/DatePicker/DatePicker";
import CardComponent from "../../../components/Card/Card";

const Controls = {
  Input,
  Select,
  Checkbox,
  DatePicker,
  CardComponent,
};

export default Controls;
