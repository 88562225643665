import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation, useTranslation } from "react-i18next";
import "react-tabs/style/react-tabs.css";
// import { masterActions } from "../../../_actions";
import Header from "../../../components/Header/Header";
import "./Payment.scss";
import PaymentHeader from "../../../components/PaymentHeader/PaymentHeader";
// import { paymentofflineActions } from "../../../_actions/paymentoffline.action";
import Loading from "../../../components/Loader/Loading";
// import moment from "moment";
import "moment-timezone";
import PaymentApprovalTable from "../../../components/Table/PaymentApprovalTable";
import { getAxiosCall, putAxiosCall } from "../../../_constant";
import { debounce } from "lodash";
import { history } from "../../../_helpers";
import { toast } from "react-toastify";

const Approve = {
  Approve_Modal: "Approve_Modal",
  Decline_Modal: "Decline_Modal",
};

function PaymentApproval(props) {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [filterData, setFilterData] = useState([]);
  const [filterCatValue, setFilterCatValue] = useState("");
  const [modalType, setModalType] = useState("");
  let userName = localStorage.getItem("userData");
  const [approvalTableItem, setApprovalTableItem] = React.useState("");
  userName = JSON.parse(userName);
  const paymentHistory = useSelector(
    (state) => state.paymentHistoryData.paymentHistoryData
  );
  let startIndex = paymentHistory && paymentHistory.startIndex;
  let endIndex = paymentHistory && paymentHistory.endIndex;
  const getPaymentCat = localStorage.getItem("paymentCatName");
  let soldToNumber = localStorage.getItem("CustomerNumber");
  const [paymentApprovalData, setPaymentApprovalData] = useState([]);
  const [paymentApprovalRes, setPaymentApprovalRes] = useState([]);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortingOrder, setSortingOrder] = useState(false);
  const selectedLangCode = localStorage.getItem("lancode");
  

  const fromIndex = 0;
  const toIndex = 10;

  const defaultCat =
    getPaymentCat && getPaymentCat ? getPaymentCat && getPaymentCat : "Cement";
  const [open, setOpen] = React.useState(false);

  const toastClasses = {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }
  // let filterDataValue;

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      if (localStorage.getItem("redirectPath")) {
        history.push(localStorage.getItem("redirectPath"));
        localStorage.removeItem("redirectPath");
      }
    });
  }, []);

  const onChangeSearchHistory = debounce(async (value) => {
    setFilterData(value);
  }, 500);

  const sortPaymentRef = () => {
    setSortingOrder(!sortingOrder)
  }
  

  const handleEvent = (event, picker) => {
    console.log(picker.startDate, "dateEvent");
  };

  const handleChangeCat = (event) => {
    console.log(event.target.value);
    var filterCat = event.target.value;
    setFilterCatValue(filterCat);
  };

  const handleChangePage = (event, value) => {
    // await dispatch(masterActions.paginationValue(value));
    if (value === 1) {
      startIndex = 1;
      endIndex = 10;
    } else {
      startIndex = (value - 1) * 10 + 1;
      endIndex = value * 10;
    }
    setPage(value);
    getPaymentApprovalData(startIndex, endIndex);
    console.log("hello", startIndex, endIndex, value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (row, status) => {
    if (status) {
      setModalType(Approve.Approve_Modal);
    } else {
      setModalType(Approve.Decline_Modal);
    }
    setOpen(true);
    setApprovalTableItem(row);
  };

  const modifyThaiTextForWarning = (refNo) => {
    if(selectedLangCode === "en"){
      return `There is an error in some invoice under payment reference number ${refNo}, please contact wecare (1732)`;
    }
    return `มีข้อผิดพลาดในใบแจ้งหนี้บางรายการ ภายใต้หมายเลขอ้างอิงการชำระเงิน ${refNo} โปรดติดต่อ wecare (1732)`
  }

  const bankErrorMessage = () => {
    if(selectedLangCode === "en"){
      return `Bank Services are not available, please try after some time.`;
    }
    return `ธนาคารปิดให้บริการชั่วคราว โปรดลองอีกครั้งในภายหลัง`;
  }

  async function sendPaymentApproval() {
    const { invoiceid, ...rest } = approvalTableItem;
    const newObj = { ...rest, invoiceId: invoiceid };
    const data = {
      ...newObj,
    };
    // console.log("hello", data);
    const endPoint =
      process.env.REACT_APP_API_URL_CONFIRMPAYMENT +
      `/onlinePayment/approvePayment`;
    const apiResponse = await putAxiosCall(endPoint, data);
    if (
      apiResponse && (apiResponse.status === 200 &&
      apiResponse.message === "Sucess" &&
      apiResponse.data !== "WIP")
    ) {

      if(data.paymentbank === "KBANK" && data.paymenttype === "DD"){
        const paymentId = apiResponse.data?.split("?")[1].split("=")[1];
        localStorage.setItem("payId", paymentId);

        window.location.replace(apiResponse.data);
      } else if(data.paymentbank === "SCB"){
        const referenceNo = apiResponse.data?.split("&")[1].split("=")[1];
        localStorage.setItem("referenceNo", referenceNo);
        
        const url = apiResponse.data?.split("&")[0]
        window.location.href =url;
      } else{
        window.location.replace(apiResponse.data);
      }

    } else if(apiResponse.status === 300 && apiResponse.message === "Failed"){
      // toast.error(apiResponse.data, toastClasses);
      toast.error(modifyThaiTextForWarning(apiResponse.data), toastClasses);
      setIsLoading(false);
    } else if(apiResponse.status === 500){
      toast.error(bankErrorMessage(), toastClasses);
      setIsLoading(false);
    } else{
      toast.error(t("Something is Wrong."), toastClasses);
      setIsLoading(false);
    }
  }

  async function handleReject() {
    const data = {
      ...approvalTableItem,
    };
    // console.log("hello", data);
    const endPoint =
      process.env.REACT_APP_API_URL_CONFIRMPAYMENT +
      `/onlinePayment/rejectPayment`;
    const apiResponse = await putAxiosCall(endPoint, data);
    setIsCancelSuccess(apiResponse.status === 200 ? true : false);
    // console.log("hello", apiResponse);
  }

  const handleApprove = () => {
    localStorage.setItem("redirectPath", window.location.pathname);
    sendPaymentApproval();
    setIsLoading(true);
  };

  async function getPaymentApprovalData(startIndex, endIndex) {
    //'/onlinePayment/approval/0110008926?fromIndex=1&toIndex=10&paymentRefNo=17&division=conwood'
    const endPoint =
      process.env.REACT_APP_API_URL_CONFIRMPAYMENT +
      `/onlinePayment/approval/${soldToNumber}?fromIndex=${startIndex}&toIndex=${endIndex}&paymentRefNo=${filterData}&division=${defaultCat}&ascSortRef=${sortingOrder}`;
    const apiResponse = await getAxiosCall(endPoint);
    // console.log('hello', apiResponse.data);
    const data =
      apiResponse.status === 200 && apiResponse?.data?.results
        ? apiResponse.data.results
        : [];
    // setPaymentApprovalData(apiResponse.status === 200 && apiResponse?.data ? apiResponse.data : []);
    setPaymentApprovalRes(apiResponse?.data);
    setPaymentApprovalData(data);
  }

  const refresPendingData = () => {
    setPaymentApprovalRes([]);
    getPaymentApprovalData(fromIndex, toIndex);
  };

  useEffect(() => {
    getPaymentApprovalData(fromIndex, toIndex);
  }, [fromIndex, toIndex, filterData, isCancelSuccess, sortingOrder]);

  const MyNewClass = useSelector(
    (state) => state.addclasswithstyle.addclasswithstyle
  );

  return (
    <>
      <div className="content-wrapper">
        <Header title={t("payment.my_approval")} />

        <div className={"row ipad_css " + MyNewClass}>
          <div className="mainScroll">
            <div className="col-12">
              <div className="payment-history card">
                <PaymentHeader
                  onChangeSearch={onChangeSearchHistory}
                  // fromDateTransDate={fromDateTransDate}
                  // toDateTransDate={toDateTransDate}
                  // pleaseSelectTransDate={pleaseSelectTransDate}
                  handleEvent={handleEvent}
                  handleChangeCat={handleChangeCat}
                  // paymentHisDateChange={handleCallbackTransactionDate}
                  heading={t("payment.my_approval")}
                />
                {paymentApprovalRes && paymentApprovalRes.totalCount >= 0 ? (
                  <PaymentApprovalTable
                    paymentHistory={paymentApprovalRes}
                    open={open}
                    handleApprove={handleApprove}
                    approvalItem={approvalTableItem}
                    handleChangePage={handleChangePage}
                    page={page}
                    refresPendingData={refresPendingData}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    modalType={modalType}
                    handleReject={handleReject}
                    isCancelSuccess={isCancelSuccess}
                    isDisabled={isLoading}
                    sortPaymentRef={sortPaymentRef}
                  />
                ) : (
                  <div className="loading">
                    {" "}
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(PaymentApproval);
