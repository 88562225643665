import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import { Tab, Tabs } from "@material-ui/core";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RedeenHistoryList from "./RedemptionHistory/RedeenHistoryList";
import ActivityPointsList from "./ActivityPoints/ActivityPointsList";
import AllocationHistory from "./AllocationHistory/AllocationHistory";
import MyPoints from "./MyPoints/MyPoints";
import HeaderText from "./HeaderText";
import AllocationVolume from "./AllocationVolume/AllocationVolume";
import { useDispatch, useSelector } from 'react-redux'
import "./HeaderText.scss"

// const LinkTab=(props)=> {
//     return (
//         <Button  target="_blank" href="http://uat.insee.life/insee-loyalty-line/#/" endIcon={<OpenInNewIcon />}>Redeem Points</Button>
//     );
//   }

const LinkTab = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      target="_blank"
      href={process.env.REACT_APP_INSEE_LIFE_REDEEM_POINTS_URL}
      endIcon={<OpenInNewIcon />}
    >
      {t("label.redeem_points")}
    </Button>
  );
};

function LoyaltyPointsNewContainer(props) {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const MyNewClass = useSelector((state) => state.addclasswithstyle.addclasswithstyle);


  return (
    <>
      <div className="content-wrapper">
        <Header title={t("nav.loyaltypoints")} />
        <div className={"row ipad_css " + MyNewClass}>
          <div className="mainScroll pb-5 mr-2">
            <div className="col-12 mt-2">
              <HeaderText />
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={t("label.my_points")} />
                <Tab label={t("label.allocate_volume")} />
                <Tab label={t("label.allocation_history")} />
                <Tab label={t("label.activity_points")} />
                <Tab label={t("label.redemption_history")} />
                <div className="redeemPoints"><LinkTab
                  value={5}
                /></div>
                
              </Tabs>
              {selectedTab === 4 && <RedeenHistoryList />}
              {selectedTab === 3 && <ActivityPointsList />}
              {selectedTab === 2 && <AllocationHistory />}
              {selectedTab === 1 && <AllocationVolume />}

              {selectedTab === 0 && <MyPoints />}

              {/* <SubDealerList /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(LoyaltyPointsNewContainer);
