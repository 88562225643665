import React from 'react';
import "./BotAvatar.scss";

const BotAvatar = () => {
    return (
        <>
        <div className="bot-avatar">S</div>
        </>
    )
}

export default BotAvatar;