import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-tabs/style/react-tabs.css";
import PaymentRadioButton from "../RadioButtonGroup/PaymentRadioButton";
import PaymentTable from "../Table/PaymentTable";
import SattledTable from "../Table/SattledTable";
import "./PaymentChildTabs.scss";
import Loading from "../../components/Loader/Loading";
import RefreshIcon from "../../assets/img/refreshIcon.png";
import { useTranslation } from "react-i18next";
import { paymentofflineActions } from "../../_actions";

const PaymentChildTabs = (props) => {
  let custmerNo = localStorage.getItem("CustomerNumber");
  const dispatch = useDispatch();
  const [isPaymentTableDisplayed, setIsPaymentTableDisplayed] = useState(true);
  // const getpendingpay = useSelector((state) => state.getpendingpay);
  const getSettledPaymentData = useSelector((state) => state.getSettledPayment);
  const getSettledPaymentTable =
    getSettledPaymentData.getSettledPayment &&
    getSettledPaymentData.getSettledPayment;
  const selectedTab = props.tab;
  const [selectItem, setSelectItem] = useState();
  const { t } = useTranslation();
  const selectedLangCode = localStorage.getItem('lancode');
  const pamentTypeCat = useSelector(
    (state) => state.offlinegetPaymentStatus.offlinegetPaymentStatus
  );
  const SmallFontChanger = useSelector((state) => state.smallfontchanger.smallfontchanger);
  const [count, setCount] = useState(0);
  const [amonut, setAmonut] = useState(0);


  const data = props.checkedValue;

  useEffect(() => {
    setCount(data.length)
  }, [props.ischeckedSoiValue, data.length])

  useEffect(() => {
    setAmonut(props.totalPayment)
  }, [props.totalPayment]);

  const [preSelect, setPreSelect] = useState(false);



  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    const selectedItem = [];
    const selectHeaderItem = []
    let totalAmount = 0;
    let selectedData = [];

    if (isChecked) {
      setPreSelect(true);
      // setIsSelectAll(true)
      props.getpendingpaydata.results?.map((item) => {
        if (item.summary.length > 0) {
          selectHeaderItem.push(item.soiNumber)
          item.summary.map((summaryItem) => {
            if (!data?.includes(summaryItem.invoiceDoc)) {
              selectedItem.push(summaryItem.invoiceDoc)
            }

            selectedData.push(summaryItem)
            totalAmount = parseFloat(totalAmount) + parseFloat(summaryItem.amountDocCurrency);
          });
        } else {
          let tempData = [...selectedData, item];
          selectedData = tempData;

          if (!data?.includes(item.invoiceDoc)) {
            selectedItem.push(item.summary.length < 1 && item.invoiceDoc);
          }

          totalAmount = parseFloat(totalAmount) + parseFloat(item.amountDocCurrency)
        }
      });
      const addPreviousData = [...props.checkedValue, ...selectedItem]
      // const addPreviousHeaderData = [...props.ischeckedSoi, ...selectHeaderItem]
      props.setIsChecked(addPreviousData);
      props.setIscheckedSoi(selectHeaderItem);
      setAmonut(totalAmount);
      // calculatedAmount = totalAmount
      // dispatch(
      //   paymentofflineActions.totalMakePaymentData(parseFloat(totalAmount), count )
      // );

    } else {
      props.setIsChecked([]);
      props.setIscheckedSoi([]);
      setPreSelect(false);
      setCount(0);
      setAmonut(0);
    }
    props.setIsCheckedData(selectedData)
  };


  useEffect(() => {
    setPreSelect(false);
    // props.setIsChecked([]);
    props.setIscheckedSoi([]);
    setAmonut(0);
    props.setPaymentId([])
    // props.setIsCheckedData([])
  }, [props.getpendingpaydata]);

  useEffect(() => {
    props.updateAmountAndTodalCount(amonut, count)
    dispatch(
      paymentofflineActions.totalMakePaymentData(parseFloat(amonut), count)
    );
  }, [count]);

  useEffect(() => {
    props.resetIndex()
  }, [getSettledPaymentTable, getSettledPaymentTable]);


  return (
    <div className="row ml-1 mr-1 parent-child-tabs">
      <div className="col-12 p-0">
        <div className="row">
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
            <PaymentRadioButton
              setIsPaymentTableDisplayed={setIsPaymentTableDisplayed}
              selectedTab={selectedTab}
              setSelectItem={setSelectItem}
            />
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2 text-right mt-2">
            <button className="refreshButton" onClick={props.refresPendingData}>
              <img src={RefreshIcon} alt="reload" title="Reload Page." />
            </button>
          </div>

        </div>


        <div className="row">
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
            {
              selectItem && selectItem === "pendingnew" ?
                <div className="statusDef" style={{ fontSize: `${SmallFontChanger}px` }}>
                  {
                    selectedLangCode === 'en' || selectedLangCode === null ? 'List of invoice, purchase order and summary of invoice to be selected for payment.' :
                      'รายการใบแจ้งหนี้, ใบสั่งซื้อ และรายการสรุปใบแจ้งหนี้ ที่ต้องการเลือกเพื่อชำระเงิน'
                  }

                </div>
                : ''
            }
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 pr-5">

            {selectItem === "pendingnew" && (
              <span className="float-right mt-0 mr-4" >
                  {/* <span className="add-payment float-right" > */}
                <input
                  type="checkbox"
                  id="select-all"
                  class="checkbox-custom"
                  checked={preSelect}
                  onChange={(e) => handleSelectAll(e)}
                />
                <label
                  for="select-all"
                  style={{
                    marginLeft: "4.2em",
                    fontWeight: "400",
                    // marginTop: "11px",
                    lineHeight: "1.5",
                    letterSpacing: "0.00938em",
                    fontSize: "13px",
                    whiteSpace: "nowrap"
                  }}
                  className="checkbox-custom-label"
                >
                  {t("payment.select_all")}
                </label>
              </span>
            )}


          </div>
        </div>
        {isPaymentTableDisplayed ? (
          props.getpendingpaydata !== undefined ? (
            <PaymentTable
              handleChangePage={props.handleChangePage}
              page={props.page}
              Pending={props.getpendingpaydata}
              isChecked={props.checkedValue}
              ischeckedSoiValue={props.ischeckedSoiValue}
              handleCheckPayment={props.handleCheck}
              handleCheckAllPayment={props.handleCheckAll}
              sortDocumentDate={props.sortDocumentDate}
              sortDueDate={props.sortDueDate}
              sortNoOfDueDate={props.sortNoOfDueDate}
              startIndex={props.startIndex}
              endIndex={props.endIndex}
            />
          ) : (
            <div className="loading">
              {"Please wait..."}
              <Loading />
            </div>
          )
        ) : getSettledPaymentTable ? (
          <SattledTable
            handleChangePageSattled={props.handleChangePageSattled}
            page={props.page}
            getSettledPaymentTable={getSettledPaymentTable}
            startIndex={props.startIndex}
            endIndex={props.endIndex}
          />
        ) : (
          <div className="loading">
            {" "}
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentChildTabs;
