import React, { useEffect } from "react";
import { eventActions } from "../../_actions";
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from "react-i18next";
import "../AccountInformation/AccountInformation.scss";
import { Link } from "react-router-dom";
function InseePrivilege(props) {
    const selectedLangCode = localStorage.getItem('lancode');
    const { t } = useTranslation();
    const inseePrivilage = props.inseePrivilage;
    const SmallFontChanger = useSelector((state) => state.smallfontchanger.smallfontchanger);
    const FontChange = useSelector((state) => state.fontsizechanger.fontsizechanger);
    const HeadingFontChange = useSelector((state) => state.headerfontchanger.headerfontchanger);
    return (
        <>

            <div className="user_information d-none d-sm-block">
                <div className="head">
                    <h5 style={{ fontSize: `${HeadingFontChange}px` }}>{props.mainHeading}</h5>
                </div>

                <div className="co-12 mt-3">
                    <div className="row account_title">
                        <div className="col-4">
                            <h6 style={{ fontSize: `${SmallFontChanger}px` }}>{t("nextyearstatus.label")} </h6>
                        </div>

                        <div className="col-4">
                            <h6 style={{ fontSize: `${SmallFontChanger}px` }}>{t("inseecoins.label")} </h6>
                        </div>

                        <div className="col-4">
                            <h6 style={{ fontSize: `${SmallFontChanger}px` }}>{t("coinssalesvolperiod.label")}</h6>
                        </div>

                    </div>
                    {inseePrivilage &&
                        <div className="row account_value">
                            <div className="col-4">
                                <h6 style={{ fontSize: `${SmallFontChanger}px` }}>{inseePrivilage.nextYearStatus}</h6>
                            </div>
                            <div className="col-4">
                                <h6 style={{ fontSize: `${SmallFontChanger}px` }}>{

                                    inseePrivilage.customerInseeCoin}</h6>
                            </div>
                            <div className="col-4">
                                <h6 style={{ fontSize: `${SmallFontChanger}px` }}>{
                                    selectedLangCode === 'en' || selectedLangCode === null ?
                                        inseePrivilage.coinSalesVolumePeriod_EN : inseePrivilage.coinSalesVolumePeriod_TH

                                }</h6>
                            </div>
                        </div>
                    }
                </div>


                <div className="co-12 mt-3">
                    <div className="row account_title">
                        <div className="col-12">
                            <h6 style={{ fontSize: `${SmallFontChanger}px` }}>{t("remark.label")}</h6>
                        </div>

                    </div>
                </div>

                {inseePrivilage &&
                    <div className="co-12">
                        <div className="row account_value">
                            <div className="col-12">
                                <h6 style={{ fontSize: `${SmallFontChanger}px` }}>{
                                    selectedLangCode === 'en' || selectedLangCode === null ?
                                        inseePrivilage.soldToRemark_EN
                                        : inseePrivilage.soldToRemark_TH
                                }</h6>
                            </div>

                        </div>
                    </div>
                }

            </div>

            <div className="col d-sm-none px-0">
                <div className="user_information">
                    <div className="head">
                        <h5 style={{ fontSize: `${HeadingFontChange}px` }}>{props.mainHeading}</h5>
                    </div>
                    <table class="table table-borderless table-sm">
                        <tbody>
                            <tr>
                                <th scope="row"><p className="bgNoText" style={{ fontSize: `${SmallFontChanger}px`,color:" #929292",fontSize: "12px",textTransform: "uppercase" }}>{t("nextyearstatus.label")}</p></th>
                                {inseePrivilage && <td><p className="textDark bgNoText textWidth" style={{ fontSize: `${SmallFontChanger}px` }}>{inseePrivilage.nextYearStatus}</p></td>}
                            </tr>
                            <tr>
                                <th scope="row"><p className="bgNoText" style={{ fontSize: `${SmallFontChanger}px`,color:" #929292",fontSize: "12px",textTransform: "uppercase" }}>{t("inseecoins.label")}</p></th>
                                {inseePrivilage && <td><p className="textDark bgNoText textWidth" style={{ fontSize: `${SmallFontChanger}px` }}>{inseePrivilage.customerInseeCoin}</p></td>}
                            </tr>
                            <tr>
                                <th scope="row"><p className="bgNoText" style={{ fontSize: `${SmallFontChanger}px`,color:" #929292",fontSize: "12px",textTransform: "uppercase" }}>{t("coinssalesvolperiod.label")}</p></th>
                                {inseePrivilage && <td><p className="textDark bgNoText textWidth" style={{ fontSize: `${SmallFontChanger}px` }}>{
                                    selectedLangCode === 'en' || selectedLangCode === null ?
                                        inseePrivilage.coinSalesVolumePeriod_EN : inseePrivilage.coinSalesVolumePeriod_TH

                                }</p></td>}
                            </tr>
                            <tr>
                                <th scope="row"><p className="bgNoText" style={{ fontSize: `${SmallFontChanger}px`,color:" #929292",fontSize: "12px",textTransform: "uppercase" }}>{t("remark.label")}</p></th>
                                {inseePrivilage && <td><p className="textDark bgNoText textWidth" style={{ fontSize: `${SmallFontChanger}px` }}>{
                                    selectedLangCode === 'en' || selectedLangCode === null ?
                                        inseePrivilage.soldToRemark_EN
                                        : inseePrivilage.soldToRemark_TH
                                }</p></td>}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default withTranslation()(InseePrivilege);
