import React, { useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  gridVisibleSortedRowIdsSelector,
  gridVisibleSortedRowEntriesSelector,
  useGridApiRef,
  gridPaginatedVisibleSortedGridRowIdsSelector,
} from "@mui/x-data-grid-pro";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import ExportPopup from "../exportPopup/ExportPopup";
import { customStableSort } from '../../_helpers';
import { ExportDataGrid } from "../../_helpers/commonFunctions";
import UploadIcon from "@mui/icons-material/Upload";

const DataGridProMUI = (props) => {
  const [openExportPopup, setOpenExportPopup] = useState(null);
  const [exportState, setExportState] = useState({
    btnName: "EXPORT",
    linkToDownload: null,
  });
  const [sortModel, setSortModel] = React.useState([]);
  const [sortedRow, setSortedRow] = useState([])
  const [flRow, setFLRow] = useState(null)

  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  let searchRow;
  const {defaultStateChange = true} = props;

  useEffect(() => {
    setSortModel([])
    setSortedRow(props.rows)
  }, [props.rows])

  const onExportClick = () => {
    let dataToExport = getExportData();
    ExportDataGrid(
      props.ExpFilecolHeadings,
      props.ExpFilecolKeys,
      dataToExport,
      props.ExportDateFormatIndexes,
      props.ExportFileName,
      props.excelFormating,
      props.excelDataTransformConfig
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector />
        <div className="ExportAndSearchContainer">
          {props.enableUpload && (
            <Button
              variant="outlined"
              startIcon={<UploadIcon />}
              onClick={props.enableUpload}
            >
              {"Upload"}
            </Button>
          )}

          {props.callExcelExport &&
            <Button
              startIcon={<DownloadIcon />}
              // onClick={()=>props.callExcelExport(flRow)}
              onClick={()=>props.callExcelExport(defaultStateChange ? flRow : searchRow)}
            >
              {t("Export")}
            </Button>
          }

          {/* {(props?.shouldShowExportButton ?? true) && (
            <Button
              startIcon={<DownloadIcon />}
              onClick={props.onExportClick}
            >
              {t(props.exportState)}
            </Button>
          )} */}
          {props.enableQuickSearch && <GridToolbarQuickFilter />}
        </div>
      </GridToolbarContainer>
    );
  };

  // returns the data which are displyed on DataGrid
  const getExportData = () => {
    let finalRows = [];
    const visibleRows = gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
    for (let i = 0; i < visibleRows.length; i++) {
      let curRow = props.rows.filter((r) => {
        return r.id == visibleRows[i];
      });
      finalRows.push(curRow[0]);
    }
    // console.log("finalRows", finalRows);
    return finalRows;
  };

  const getDetailPanelHeight = React.useCallback(() => "auto", []);

  // ===============================================
  function customSortModel(model) {
    setSortedRow(customStableSort(props.rows, model))
    setSortModel(model)
  }

  const onstateChange = (state) => {
    const rowEntity = gridVisibleSortedRowEntriesSelector(state);
    if (defaultStateChange) {
      if (props.setSearchedRows) {
          props.setSearchedRows(rowEntity)
        }
          setFLRow(rowEntity)
    } else{
      searchRow = rowEntity;
    }
  }

  return (
    <div>
      <DataGridPro
        style={{ fontSize: "14px", outline: "none !important" }}
        // rows={props.rows}
        rows={Array.isArray(sortedRow) ? sortedRow : []}
        sortingMode="server"
        columns={props.columns}
        components={{ Toolbar: CustomToolbar }}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        labelRowsPerPage={t(props.labelRowsPerPage)}
        getDetailPanelHeight={getDetailPanelHeight}
        pagination={true}
        loading={props.loading}
        onPageSizeChange={props.onPageSizeChange}
        localeText={{
          noRowsLabel: t("label.No_Data"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} ${t("of")} ${count}`,
          },
          toolbarQuickFilterPlaceholder: t("label.Search")
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: t("report.perPage"),
          },
        }}
        // onStateChange={(state) => {
        //   const newRows = gridVisibleSortedRowIdsSelector(state);
        //   //setVisibleRows(newRows);
        // }}
        // onStateChange={(state) => {
        //   const rowEntity = gridVisibleSortedRowEntriesSelector(state)
        //   if (props.setSearchedRows) {
        //     props.setSearchedRows(rowEntity)
        //   }
        //     setFLRow(rowEntity)
        // }}
        onStateChange={onstateChange}
        apiRef={apiRef}
        autoHeight={true}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => customSortModel(newSortModel)}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
             outline: "none !important",
          },
       }}
      />

      <ExportPopup
        title={""}
        openPopup={openExportPopup}
        setOpenExportPopup={setOpenExportPopup}
        linkToDownload={exportState.linkToDownload}
      />
    </div>
  );
};

DataGridProMUI.defaultProps = {
  pageSize: 25,
  rowsPerPageOptions: [25, 50, 75, 100],
  pagination: true,
  labelRowsPerPage: "lable.RowsPerPage",
  enableQuickSearch: true,
  enableMasterDetail: false, //make it true and also pass detailComponent as props that will be displayed on click of '+' button in each row.
  style: { display: "flex", height: "350px", marginTop: "10px" },
  noRowsLabel: "No rows",
  loading: false,
};

export default DataGridProMUI;
