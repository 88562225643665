import React, { useEffect, useState } from "react";
import { eventActions, masterActions, orderActions } from "../../../_actions";
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../../components/Header/Header";
import { withTranslation, useTranslation } from "react-i18next";
import UserProfileCard from "../../../components/UserProfileCard/UserProfileCard";
import AccountInformation from "../../../components/AccountInformation/AccountInformation";
import UserInfo from "../../../components/UserInfo/UserInfo";
import MainLocation from "../../../components/MainLocation/MainLocation";
import AlternateLocation from "../../../components/AlternateLocation/AlternateLocation";
import InseePrivilege from "../../../components/InseePrivilege/InseePrivilege";
import PdpaAgreement from "../../../components/PdpaAgreement/PdpaAgreement";
import { Link } from "react-router-dom";
import Loading from '../../../components/Loader/Loading';
import Location from '../../../components/Location/Location';
import "./CustomerProfile.scss";
import { shiptobycount } from "../../../_reducers/shiptobycount.reducer";
import Axios from 'axios';


function CustomerProfile() {

  ///const getSocialMedia = useSelector(state => state.getSocialMediaUsingGet);
  const [previlage, setPrevilage] = useState(null);
  const [customerTierStatus, setCustomerTierStatus] = useState(null)
  let userName = localStorage.getItem('userData');
  userName = JSON.parse(userName);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shipToDetails = useSelector((state) => state.getShipToDetails);
  useEffect(() => {
    dispatch(masterActions.getCustomerBySoldTo(localStorage.getItem('CustomerNumber')));
    dispatch(orderActions.getShipToDetails(localStorage.getItem('CustomerNumber')));
  }, []);
  const getCustomerBySoldTo = useSelector(state => state.getCustomerBySoldTo);
  const pdpConfirmedCustomer = useSelector(state => state.getCustomerPdpInfo.getCustomerPdpInfo);
  const FontChange = useSelector((state) => state.fontsizechanger.fontsizechanger);
  const HeadingFontChange = useSelector((state) => state.headerfontchanger.headerfontchanger);
  const MyNewClass = useSelector((state) => state.addclasswithstyle.addclasswithstyle);


  useEffect(() => {
    Axios({
      method: "GET", url: process.env.REACT_APP_MASTER_API_URL + `/customer/info/${localStorage.getItem('CustomerNumber')}`,
      headers: {
        "Content-Type": "application/json",
        'X-AUTH-TOKEN': localStorage.getItem('x-auth-token'),
        'X-SOLD-TO-ID': localStorage.getItem('CustomerNumber')
      }
    })
      .then(async (response) => {
        if (response?.data?.data?.inseePrevilage) {
          setPrevilage(response.data.data.inseePrevilage)
        }
        if(response?.data?.data?.customerTierStatusObj){
          setCustomerTierStatus(response.data.data.customerTierStatusObj)
        }
      }).catch((ee) => {

      })
  }, [])

  return (
    <>
      <div className="content-wrapper user_section">
        <Header title={t("viewProfileDetail")} />
        <div className={"row ipad_css " + MyNewClass}>
          <div className="mainScroll">
            {getCustomerBySoldTo && getCustomerBySoldTo.loading ? <span style={{ marginLeft: "30%" }}>
              <div className='loading'>
                <Loading />
              </div>
            </span> : ''}
            {getCustomerBySoldTo && getCustomerBySoldTo.customerDetailById !== undefined ?
              <div className="customer_profile">
                <div className="main-heading">
                  <h5 style={{ fontSize: `${HeadingFontChange}px` }}>{t("userprofile.heading")}</h5>
                </div>
                <UserProfileCard
                  customerInfo={getCustomerBySoldTo.customerDetailById}
                  shipToDetails={shipToDetails}
                  customerTierStatus = {customerTierStatus}
                  />
                <div className="col-12 p-2">
                  <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-2">
                      <AccountInformation cotactList={getCustomerBySoldTo.customerDetailById} />
                      <UserInfo userInfo={userName} />

                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-2">
                      {/* <Location locationInfo={getCustomerBySoldTo.customerDetailById} /> */}
                      {/* {
                        userName && userName.countryCode === 'VN' || userName && userName.countryCode === 'LK' ? 
                        '':
                        <InseePrivilege inseePrivilage={getCustomerBySoldTo.customerDetailById.inseePrivilage} mainHeading={t("inseeprivilage.heading")} />
                      } */}
                      {previlage && <InseePrivilege inseePrivilage={previlage} mainHeading={t("inseeprivilage.heading")} />}
                      <PdpaAgreement pdpConfirmedCustomer={pdpConfirmedCustomer} mainHeading={t("pdpaagreement.heading")} />
                    </div>
                  </div>
                  {/*
                <div className="row">  
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-2">
                      <MainLocation/>
                      <AlternateLocation/>
                  </div>
                </div>
                */}
                </div>

                  <div className="create_link d-flex justify-content-start pb-4 pt-2 ml-0 unhideSamsungFold" >
                    <div className="row">
                      <div className="col-6">
                      <Link to="/ViewRetailers"><button className="viewretailersButton" style={{ padding: '7px 15px', fontSize: `${FontChange}px` }}>{t("viewretailers.button")}</button></Link>
                      </div>
                      <div className="col-6">
                      <Link to="/ViewShipTo"><button className="viewshiptoButton" style={{ fontSize: `${FontChange}px` }}> {t("viewshipto.button")}</button></Link>
                      </div>
                    </div>
                </div>

                <div className="create_link pb-4 pt-2 ml-0 hideSamsungFold text-center" >
                      <Link to="/ViewRetailers"><button className="viewretailersButton" style={{ padding: '7px 15px', fontSize: `${FontChange}px` }}>{t("viewretailers.button")}</button></Link>
                      <Link to="/ViewShipTo"><button className="viewshiptoButton mt-1" style={{ fontSize: `${FontChange}px` }}> {t("viewshipto.button")}</button></Link>
            
                </div>
              </div> : ''
            }
          </div>

        </div>
      </div>
    </>
  );
}

export default withTranslation()(CustomerProfile);