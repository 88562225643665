import React, { useState, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import SearchForm from "./SearchForm";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import Axios from "axios";
import { convertToCurrencyFormatQuantaty } from "../../../../_helpers";
import DataGridProMUI from "../../../../components/DataGrid/DataGridProMUI";
import ExportExcelPopup from "../../../../components/exportPopup/ExportExcelPopup";
import moment from "moment";
import { onExportExcel } from "../../../../_helpers/exportExcel";

const AllocationHistory = () => {
  const [row, setRow] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [showLoading, setShowLoading] = useState(false);
  const lancode = localStorage.getItem("lancode");
  const [fileUrl, setFileUrl] = useState("");
  const [exportFiles, setExportFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [exportState, setExportState] = useState("Export");
  const [searchedRows, setSearchedRows] = useState(null)
  const [filter, setFilter] = useState({
    company: "",
    billingMonth: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    ),
    billingYear: new Date(),
    subDealerNumber: "",
  });
  const { t } = useTranslation();

  const onExportClick = () => {
    const url = `export/allocated-history`;
    setExportState("Exporting...");
    let filters = {
      startMonthYear: filter.billingMonth
        ? moment(filter.billingMonth).format("MM-YYYY")
        : null,
      endMonthYear: filter.billingYear
        ? moment(filter.billingYear).format("MM-YYYY")
        : null,
      company: filter.company,
      subDealer: filter.subDealerNumber,
      customerId: localStorage.getItem("CustomerNumber"),
      billingMonths: null,
      billingYear: null,
      search: null,
      languageType: lancode.toUpperCase(),
    };
    Axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL_LMS + url,
      data: filters,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        setExportState("Export");
        setOpen(true);
        setFileUrl(response.data.data);
        exportExcelFile(response.data.data);
      })
      .catch((err) => {
        setExportState("Export");
      });
  };

  function exportExcelFile(file_url) {
    setExportFiles([]);
    Axios({
      method: "GET",
      url:
        process.env.REACT_APP_API_URL_LMS +
        `export/bucket?folderName=${file_url}`,
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
        "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
      },
    })
      .then(async (response) => {
        const newRes = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        setExportFiles(newRes);
      })
      .catch((err) => {
        // setExportState({ btnName: "EXPORT" });
      });
  }

  const fetchAllocationHistory = function () {
    let customerId = localStorage.getItem("CustomerNumber");
    setShowLoading(true);
    Axios.get(
      `${
        process.env.REACT_APP_API_URL_LMS
      }loyalty/allocate-volume/history?customerId=${customerId}&startMonthYear=${
        filter.billingMonth
          ? moment(filter.billingMonth).format("MM-YYYY")
          : null
      }&endMonthYear=${
        filter.billingYear ? moment(filter.billingYear).format("MM-YYYY") : null
      }&company=${filter.company}&subDealer=${filter.subDealerNumber}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-AUTH-TOKEN": localStorage.getItem("x-auth-token"),
          "X-SOLD-TO-ID": localStorage.getItem("CustomerNumber"),
        },
      }
    )
      .then((response) => {
        setShowLoading(false);
        setRow(
          (response?.data?.data || []).map((item, index) => ({
            ...item,
            no: index + 1,
            subDealerId: item.subDealerId ? +item.subDealerId : "",
            a:convertToCurrencyFormatQuantaty(item.sumAllocated),
            bm:item.billingMonth ? moment(item.billingMonth).format("MM") : "",
            by:item.billingYear ? moment(item.billingYear).format("YYYY") : ""
          }))
        );
      })
      .catch((error) => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    setRow([]);
    fetchAllocationHistory();
  }, [
    filter.billingMonth,
    filter.billingYear,
    filter.company,
    filter.subDealerNumber,
  ]);

  const columns = [
    {
      field: "no",
      headerName: t("no"),
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "company",
      headerName: t("company"),
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: "billingMonth",
      headerName: t("billingMonth"),
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <div>
            {row.billingMonth ? moment(row.billingMonth).format("MM") : ""}
          </div>
        );
      },
    },
    {
      field: "billingYear",
      headerName: t("billingYear"),
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <div>
            {row.billingYear ? moment(row.billingYear).format("YYYY") : ""}
          </div>
        );
      },
    },
    {
      field: "subDealerId",
      headerName: t("subDealerNumber"),
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: `${lancode === "en" ? "subDealerName" : "subDealerNameTH"}`,
      headerName: t("subDealerName"),
      headerAlign: "center",
      align: "left",
      width: 180,
    },
    {
      field: "sumAllocated",
      headerName: t("allocated"),
      align: "right",
      headerAlign: "center",
      width: 150,
      format: '#,##0.000',
      renderCell: ({ row }) => {
        return (
          <div>{convertToCurrencyFormatQuantaty(row.sumAllocated)}</div>
        );
      },
    },
    { field: 'a',headerName: 'sumAllocated', hide:true},
    { field: 'bm',headerName: 'billingMonth', hide:true},
    { field: 'by',headerName: 'billingYear', hide:true},
  ];
  const filteredQqtyRow = row.filter((row) => row.sumAllocated > 0);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <div className="ExportAndSearchContainer">
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onExportClick}
            style={{ border: "0px" }}
          >
            {t("Export")}
          </Button>
          <GridToolbarQuickFilter />
        </div>
      </GridToolbarContainer>
    );
  };

  const onFilterChange = (filterObj) => {
    setFilter({ ...filterObj });
  };


  function modifyColumns(columns) {
    columns = columns.filter((item)=>!["a", "bm", "by"].includes(item.field))
    return columns.map((item) => {
      return ({
        label: item.headerName,
        value: item.field,
        ...item
      })
    })
  }
  
  function modifyRows(rowdata) {
    // let filteredRow = searchedRows.map((item) => item.model);
    let filteredRow = rowdata.map((item) => item.model);
    const data = filteredRow.map((item) => {
      return ({
        ...item
      })
    })
    return data
  }
  
  function callExcelExport(data){
    const fileName = `Allocation_History_Report_${moment(new Date()).format("DDMMyyyy")}`;
    const sheetName = `Allocation_History_Report`
    onExportExcel(modifyColumns(columns), modifyRows(data), fileName, sheetName)
  }

  return (
    <>
      <div>
        <SearchForm
          onFilterChange={onFilterChange}
          setOpenPopup={true}
          recordForEdit={null}
        />

        <ExportExcelPopup
          title={"Customer"}
          fileName={"customer"}
          setOpen={setOpen}
          open={open}
          exportExcelFile={exportExcelFile}
          fileUrl={fileUrl}
          exportFiles={exportFiles}
        />
      </div>
      <div style={{ marginTop: "13px" }}>
        <DataGridProMUI
          rows={row}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
          pageSize={pageSize}
          loading={!(row || []).length && showLoading}
          onExportClick={onExportClick}
          exportState={exportState}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          setSearchedRows={setSearchedRows}
          callExcelExport={callExcelExport}
          defaultStateChange={false}
        />
      </div>
      <div
        id="footer"
        style={{
          backgroundColor: "red",
          color: "white",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h6>
          {t("Total")}{" "}
          {convertToCurrencyFormatQuantaty(
            filteredQqtyRow.reduce(
              (acc, item) => Number(acc) + Number(item?.sumAllocated || 0),
              0
            )
          )}
        </h6>
      </div>
    </>
  );
};

export default withTranslation()(AllocationHistory);
