import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
// import { useDispatch } from "react-redux";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(() => ({
    root: {
      padding: "25px",
      textAlign: "center",
      // width: "517px",
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      // margin: 0,
      padding: theme.spacing(1),
      textAlign: "center",
      marginTop: "20px",
      display: "block",
    },
  }))(MuiDialogActions);



const PaymentReferencePopup = (props) => {
    let history = useHistory();
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    // const [open, setOpen] = React.useState(false);
    // const [loading, setSetloading] = React.useState(false);

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // let userName = localStorage.getItem("userData");
    // userName = JSON.parse(userName);
    // const soldToNo = userName.soldTo[0];
    // const transationdateFormet = moment(props.paymentDate).format("DD-MM-YYYY");

    const closePopup = () => {
        // dispatch(paymentofflineActions.loadcacheData(soldToNo));
        history.push({
          pathname: "/PaymentHistory",
        });
      };


  return (
    <div>
      <Dialog aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title"></DialogTitle>
        <DialogContent>
          <Typography>
            <p className="mb-0">
              {t("Payment Ref No")} {props.paymentrefno && props.paymentrefno}
            </p>
            <div className="row mt-3">
              <div className="col-12">
                <Box  className='text-success'
                >
                  {t("label.approval_success")}
                </Box>
              </div>
            </div>
          </Typography>
          <DialogActions>
            <div className="create_link d-flex pull-center">
              <button
                className="create p-2 pl-4 pr-4 rounded"
                onClick={() => closePopup()}
              >
                {t("close.button")}
              </button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PaymentReferencePopup;